#canvas,#root {
  height: 100%
}

#canvas {
  display: block;
  width: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  -moz-transform: translate(-50%,-50%);
  -o-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%)
}

.ant-image-preview,.ant-modal {
  pointer-events: none
}

.ant-image-preview.zoom-appear,.ant-image-preview.zoom-enter,.ant-modal.zoom-appear,.ant-modal.zoom-enter {
  transform: none;
  opacity: 0;
  animation-duration: .3s;
  user-select: none
}

.ant-image-preview-mask,.ant-modal-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0,0,0,.45);
  filter: alpha(opacity=50)
}

.ant-image-preview-mask-hidden,.ant-modal-mask-hidden {
  display: none
}

.ant-image-preview-wrap,.ant-modal-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch
}

.ant-legacy-form {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0,0,0,.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum"
}

.ant-legacy-form legend {
  display: block;
  width: 100%;
  margin-bottom: 20px;
  padding: 0;
  color: rgba(0,0,0,.45);
  font-size: 16px;
  line-height: inherit;
  border: 0;
  border-bottom: 1px solid #d9d9d9
}

.ant-legacy-form label {
  font-size: 14px
}

.ant-legacy-form input[type=search] {
  box-sizing: border-box
}

.ant-legacy-form input[type=checkbox],.ant-legacy-form input[type=radio] {
  line-height: normal
}

.ant-legacy-form input[type=file] {
  display: block
}

.ant-legacy-form input[type=range] {
  display: block;
  width: 100%
}

.ant-legacy-form select[multiple],.ant-legacy-form select[size] {
  height: auto
}

.ant-legacy-form input[type=checkbox]:focus,.ant-legacy-form input[type=file]:focus,.ant-legacy-form input[type=radio]:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px
}

.ant-legacy-form output {
  display: block;
  padding-top: 15px;
  color: rgba(0,0,0,.85);
  font-size: 14px;
  line-height: 1.5715
}

.ant-legacy-form-item-required:before {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun,sans-serif;
  line-height: 1;
  content: "*"
}

.ant-legacy-form-hide-required-mark .ant-legacy-form-item-required:before {
  display: none
}

.ant-legacy-form-item-label>label {
  color: rgba(0,0,0,.85)
}

.ant-legacy-form-item-label>label:after {
  content: ":";
  position: relative;
  top: -.5px;
  margin: 0 8px 0 2px
}

.ant-legacy-form-item-label>label.ant-legacy-form-item-no-colon:after {
  content: " "
}

.ant-legacy-form-item {
  box-sizing: border-box;
  padding: 0;
  color: rgba(0,0,0,.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  margin: 0 0 24px;
  vertical-align: top;
  width: 100%;
}
.ant-form-item .ant-form-item-row {
  width: 100%;
}

.ant-form-item .ant-form-item-row .ant-input {
  border-radius: 0;
}

.ant-form-item .ant-form-item-row .ant-input-affix-wrapper {
  border-radius: 0;
  padding: 0 !important;
}

.ant-form-item .ant-form-item-row .ant-input-affix-wrapper .ant-input-suffix {
  display: none;
}

.ant-form-item .ant-form-item-row .ant-input-affix-wrapper .ant-input-lg {
  padding: 6.5px 11px !important;
}

.ant-legacy-form-item label {
  position: relative
}

.ant-legacy-form-item label>.anticon {
  font-size: 14px;
  vertical-align: top
}

.ant-legacy-form-item-control {
  position: relative;
  line-height: 40px
}

.ant-legacy-form-item-control:before {
  display: table;
  content: ""
}

.ant-legacy-form-item-control:after {
  display: table;
  clear: both;
  content: ""
}

.ant-legacy-form-item-children {
  position: relative
}

.ant-legacy-form-item-with-help {
  margin-bottom: 4px
}

.ant-legacy-form-item-label {
  display: inline-block;
  overflow: hidden;
  line-height: 39.9999px;
  white-space: nowrap;
  text-align: right;
  vertical-align: middle;
  flex-grow: 0
}

.ant-legacy-form-item-label-left {
  text-align: left
}

.ant-legacy-form-item-control-wrapper {
  flex: 1 1 0
}

.ant-legacy-form-item .ant-switch {
  margin: 2px 0 4px
}

.ant-legacy-form-explain,.ant-legacy-form-extra {
  clear: both;
  min-height: 23px;
  margin-top: -2px;
  color: rgba(0,0,0,.45);
  font-size: 14px;
  line-height: 1.5715;
  transition: color .3s cubic-bezier(.215,.61,.355,1)
}

.ant-legacy-form-explain {
  margin-bottom: -1px
}

.ant-legacy-form-extra {
  padding-top: 4px
}

.ant-legacy-form-text {
  display: inline-block;
  padding-right: 8px
}

.ant-legacy-form-split {
  display: block;
  text-align: center
}

form .has-feedback .ant-input {
  padding-right: 30px
}

form .has-feedback .ant-input-affix-wrapper .ant-input-suffix {
  padding-right: 18px
}

form .has-feedback .ant-input-affix-wrapper .ant-input {
  padding-right: 49px
}

form .has-feedback .ant-input-affix-wrapper.ant-input-affix-wrapper-input-with-clear-btn .ant-input {
  padding-right: 68px
}

form .has-feedback :not(.ant-input-group-addon)>.ant-select .ant-select-arrow,form .has-feedback :not(.ant-input-group-addon)>.ant-select .ant-select-selection__clear,form .has-feedback>.ant-select .ant-select-arrow,form .has-feedback>.ant-select .ant-select-selection__clear {
  right: 28px
}

form .has-feedback :not(.ant-input-group-addon)>.ant-select .ant-select-selection-selected-value,form .has-feedback>.ant-select .ant-select-selection-selected-value {
  padding-right: 42px
}

form .has-feedback .ant-cascader-picker-arrow {
  margin-right: 17px
}

form .has-feedback .ant-calendar-picker-clear,form .has-feedback .ant-calendar-picker-icon,form .has-feedback .ant-cascader-picker-clear,form .has-feedback .ant-input-search:not(.ant-input-search-enter-button) .ant-input-suffix,form .has-feedback .ant-time-picker-clear,form .has-feedback .ant-time-picker-icon {
  right: 28px
}

form .has-feedback .ant-picker .ant-picker-suffix {
  padding-right: 17px
}

form .ant-mentions,form textarea.ant-input {
  height: auto;
  margin-bottom: 4px
}

form .ant-upload {
  background: transparent
}

form input[type=checkbox],form input[type=radio] {
  width: 14px;
  height: 14px
}

form .ant-checkbox-inline,form .ant-radio-inline {
  display: inline-block;
  margin-left: 8px;
  font-weight: 400;
  vertical-align: middle;
  cursor: pointer
}

form .ant-checkbox-inline:first-child,form .ant-radio-inline:first-child {
  margin-left: 0
}

form .ant-checkbox-vertical,form .ant-radio-vertical {
  display: block
}

form .ant-checkbox-vertical+.ant-checkbox-vertical,form .ant-radio-vertical+.ant-radio-vertical {
  margin-left: 0
}

form .ant-input-number+.ant-legacy-form-text {
  margin-left: 8px
}

form .ant-input-number-handler-wrap {
  z-index: 2
}

form .ant-cascader-picker,form .ant-select {
  width: 100%
}

form .ant-input-group .ant-cascader-picker,form .ant-input-group .ant-select {
  width: auto
}

form .ant-input-group-wrapper,form :not(.ant-input-group-wrapper)>.ant-input-group {
  position: relative;
  top: -1px;
  display: inline-block;
  vertical-align: middle
}

.ant-col-24.ant-legacy-form-item-label,.ant-col-xl-24.ant-legacy-form-item-label,.ant-legacy-form-vertical .ant-legacy-form-item-label {
  display: block;
  margin: 0;
  padding: 0 0 8px;
  line-height: 1.5715;
  white-space: normal;
  text-align: left;
  flex-basis: 100%
}

.ant-col-24.ant-legacy-form-item-label label:after,.ant-col-xl-24.ant-legacy-form-item-label label:after,.ant-legacy-form-vertical .ant-legacy-form-item-label label:after {
  display: none
}

.ant-legacy-form-vertical .ant-legacy-form-item {
  padding-bottom: 8px
}

.ant-legacy-form-vertical .ant-legacy-form-item-control {
  line-height: 1.5715
}

.ant-legacy-form-vertical .ant-legacy-form-explain {
  margin-top: 2px;
  margin-bottom: -5px
}

.ant-legacy-form-vertical .ant-legacy-form-extra {
  margin-top: 2px;
  margin-bottom: -4px
}

@media (max-width: 575px) {
  .ant-legacy-form-item-control-wrapper,.ant-legacy-form-item-label {
      display:block;
      width: 100%
  }

  .ant-legacy-form-item-label {
      display: block;
      margin: 0;
      padding: 0 0 8px;
      line-height: 1.5715;
      white-space: normal;
      text-align: left;
      flex-basis: 100%
  }

  .ant-legacy-form-item-label label:after {
      display: none
  }

  .ant-legacy-form-item-control-wrapper {
      flex-basis: 100%
  }

  .ant-col-xs-24.ant-legacy-form-item-label {
      display: block;
      margin: 0;
      padding: 0 0 8px;
      line-height: 1.5715;
      white-space: normal;
      text-align: left;
      flex-basis: 100%
  }

  .ant-col-xs-24.ant-legacy-form-item-label label:after {
      display: none
  }
}

@media (max-width: 767px) {
  .ant-col-sm-24.ant-legacy-form-item-label {
      display:block;
      margin: 0;
      padding: 0 0 8px;
      line-height: 1.5715;
      white-space: normal;
      text-align: left;
      flex-basis: 100%
  }

  .ant-col-sm-24.ant-legacy-form-item-label label:after {
      display: none
  }
}

@media (max-width: 991px) {
  .ant-col-md-24.ant-legacy-form-item-label {
      display:block;
      margin: 0;
      padding: 0 0 8px;
      line-height: 1.5715;
      white-space: normal;
      text-align: left;
      flex-basis: 100%
  }

  .ant-col-md-24.ant-legacy-form-item-label label:after {
      display: none
  }
}

@media (max-width: 1199px) {
  .ant-col-lg-24.ant-legacy-form-item-label {
      display:block;
      margin: 0;
      padding: 0 0 8px;
      line-height: 1.5715;
      white-space: normal;
      text-align: left;
      flex-basis: 100%
  }

  .ant-col-lg-24.ant-legacy-form-item-label label:after {
      display: none
  }
}

@media (max-width: 1599px) {
  .ant-col-xl-24.ant-legacy-form-item-label {
      display:block;
      margin: 0;
      padding: 0 0 8px;
      line-height: 1.5715;
      white-space: normal;
      text-align: left;
      flex-basis: 100%
  }

  .ant-col-xl-24.ant-legacy-form-item-label label:after {
      display: none
  }
}

.ant-legacy-form-inline .ant-legacy-form-item {
  display: inline-block;
  margin-right: 16px;
  margin-bottom: 0
}

.ant-legacy-form-inline .ant-legacy-form-item-with-help {
  margin-bottom: 24px
}

.ant-legacy-form-inline .ant-legacy-form-item>.ant-legacy-form-item-control-wrapper,.ant-legacy-form-inline .ant-legacy-form-item>.ant-legacy-form-item-label {
  display: inline-block;
  vertical-align: top
}

.ant-legacy-form-inline .ant-legacy-form-text,.ant-legacy-form-inline .has-feedback {
  display: inline-block
}

.has-error.has-feedback .ant-legacy-form-item-children-icon,.has-success.has-feedback .ant-legacy-form-item-children-icon,.has-warning.has-feedback .ant-legacy-form-item-children-icon,.is-validating.has-feedback .ant-legacy-form-item-children-icon {
  position: absolute;
  top: 50%;
  right: 0;
  z-index: 1;
  width: 32px;
  height: 20px;
  margin-top: -10px;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  visibility: visible;
  animation: zoomIn .3s cubic-bezier(.12,.4,.29,1.46);
  pointer-events: none
}

.has-error.has-feedback .ant-legacy-form-item-children-icon svg,.has-success.has-feedback .ant-legacy-form-item-children-icon svg,.has-warning.has-feedback .ant-legacy-form-item-children-icon svg,.is-validating.has-feedback .ant-legacy-form-item-children-icon svg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto
}

.has-success.has-feedback .ant-legacy-form-item-children-icon {
  color: #52c41a;
  animation-name: diffZoomIn1!important
}

.has-warning .ant-legacy-form-explain,.has-warning .ant-legacy-form-split {
  color: #faad14
}

.has-warning .ant-input,.has-warning .ant-input:hover {
  background-color: #fff;
  border-color: #faad14
}

.has-warning .ant-input:focus {
  border-color: #ffc53d;
  border-right-width: 1px!important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(250,173,20,.2)
}

.has-warning .ant-input:not([disabled]):hover {
  border-color: #faad14
}

.has-warning .ant-calendar-picker-open .ant-calendar-picker-input {
  border-color: #ffc53d;
  border-right-width: 1px!important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(250,173,20,.2)
}

.has-warning .ant-input-affix-wrapper .ant-input,.has-warning .ant-input-affix-wrapper .ant-input:hover {
  background-color: #fff;
  border-color: #faad14
}

.has-warning .ant-input-affix-wrapper .ant-input:focus {
  border-color: #ffc53d;
  border-right-width: 1px!important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(250,173,20,.2)
}

.has-warning .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-color: #faad14
}

.has-warning .ant-input-prefix {
  color: #faad14
}

.has-warning .ant-input-group-addon {
  color: #faad14;
  background-color: #fff;
  border-color: #faad14
}

.has-warning .has-feedback {
  color: #faad14
}

.has-warning.has-feedback .ant-legacy-form-item-children-icon {
  color: #faad14;
  animation-name: diffZoomIn3!important
}

.has-warning .ant-select-selection,.has-warning .ant-select-selection:hover {
  border-color: #faad14
}

.has-warning .ant-select-focused .ant-select-selection,.has-warning .ant-select-open .ant-select-selection {
  border-color: #ffc53d;
  border-right-width: 1px!important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(250,173,20,.2)
}

.has-warning .ant-calendar-picker-icon:after,.has-warning .ant-cascader-picker-arrow,.has-warning .ant-picker-icon:after,.has-warning .ant-select-arrow,.has-warning .ant-time-picker-icon:after {
  color: #faad14
}

.has-warning .ant-input-number,.has-warning .ant-time-picker-input {
  border-color: #faad14
}

.has-warning .ant-input-number-focused,.has-warning .ant-input-number:focus,.has-warning .ant-time-picker-input-focused,.has-warning .ant-time-picker-input:focus {
  border-color: #ffc53d;
  border-right-width: 1px!important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(250,173,20,.2)
}

.has-warning .ant-input-number:not([disabled]):hover,.has-warning .ant-time-picker-input:not([disabled]):hover {
  border-color: #faad14
}

.has-warning .ant-cascader-picker:focus .ant-cascader-input {
  border-color: #ffc53d;
  border-right-width: 1px!important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(250,173,20,.2)
}

.has-warning .ant-cascader-picker:hover .ant-cascader-input,.has-warning .ant-input-affix-wrapper {
  border-color: #faad14
}

.has-warning .ant-input-affix-wrapper-focused {
  box-shadow: 0 0 0 2px rgba(250,173,20,.2)
}

.has-warning .ant-input-affix-wrapper .ant-input:focus {
  box-shadow: none
}

.has-error .ant-legacy-form-explain,.has-error .ant-legacy-form-split {
  color: #ff4d4f
}

.has-error .ant-input,.has-error .ant-input:hover {
  background-color: #fff;
  border-color: #ff4d4f
}

.has-error .ant-input:focus {
  border-color: #ff7875;
  border-right-width: 1px!important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(255,77,79,.2)
}

.has-error .ant-input:not([disabled]):hover {
  border-color: #ff4d4f
}

.has-error .ant-calendar-picker-open .ant-calendar-picker-input {
  border-color: #ff7875;
  border-right-width: 1px!important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(255,77,79,.2)
}

.has-error .ant-input-affix-wrapper .ant-input,.has-error .ant-input-affix-wrapper .ant-input:hover {
  background-color: #fff;
  border-color: #ff4d4f
}

.has-error .ant-input-affix-wrapper .ant-input:focus {
  border-color: #ff7875;
  border-right-width: 1px!important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(255,77,79,.2)
}

.has-error .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-color: #ff4d4f
}

.has-error .ant-input-prefix {
  color: #ff4d4f
}

.has-error .ant-input-group-addon {
  color: #ff4d4f;
  background-color: #fff;
  border-color: #ff4d4f
}

.has-error .has-feedback {
  color: #ff4d4f
}

.has-error.has-feedback .ant-legacy-form-item-children-icon {
  color: #ff4d4f;
  animation-name: diffZoomIn2!important
}

.has-error .ant-select-selection,.has-error .ant-select-selection:hover {
  border-color: #ff4d4f
}

.has-error .ant-select-focused .ant-select-selection,.has-error .ant-select-open .ant-select-selection {
  border-color: #ff7875;
  border-right-width: 1px!important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(255,77,79,.2)
}

.has-error .ant-select.ant-select-auto-complete .ant-input:focus {
  border-color: #ff4d4f
}

.has-error .ant-input-group-addon .ant-select-selection {
  border-color: transparent;
  box-shadow: none
}

.has-error .ant-calendar-picker-icon:after,.has-error .ant-cascader-picker-arrow,.has-error .ant-picker-icon:after,.has-error .ant-select-arrow,.has-error .ant-time-picker-icon:after {
  color: #ff4d4f
}

.has-error .ant-input-number,.has-error .ant-time-picker-input {
  border-color: #ff4d4f
}

.has-error .ant-input-number-focused,.has-error .ant-input-number:focus,.has-error .ant-time-picker-input-focused,.has-error .ant-time-picker-input:focus {
  border-color: #ff7875;
  border-right-width: 1px!important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(255,77,79,.2)
}

.has-error .ant-input-number:not([disabled]):hover,.has-error .ant-mention-wrapper .ant-mention-editor,.has-error .ant-mention-wrapper .ant-mention-editor:not([disabled]):hover,.has-error .ant-time-picker-input:not([disabled]):hover {
  border-color: #ff4d4f
}

.has-error .ant-cascader-picker:focus .ant-cascader-input,.has-error .ant-mention-wrapper.ant-mention-active:not([disabled]) .ant-mention-editor,.has-error .ant-mention-wrapper .ant-mention-editor:not([disabled]):focus {
  border-color: #ff7875;
  border-right-width: 1px!important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(255,77,79,.2)
}

.has-error .ant-cascader-picker:hover .ant-cascader-input,.has-error .ant-transfer-list {
  border-color: #ff4d4f
}

.has-error .ant-transfer-list-search:not([disabled]) {
  border-color: #d9d9d9
}

.has-error .ant-transfer-list-search:not([disabled]):hover {
  border-color: #40a9ff;
  border-right-width: 1px!important
}

.has-error .ant-transfer-list-search:not([disabled]):focus {
  border-color: #40a9ff;
  border-right-width: 1px!important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(24,144,255,.2)
}

.has-error .ant-input-affix-wrapper {
  border-color: #ff4d4f
}

.has-error .ant-input-affix-wrapper-focused {
  box-shadow: 0 0 0 2px rgba(255,77,79,.2)
}

.has-error .ant-input-affix-wrapper .ant-input:focus {
  box-shadow: none
}

.is-validating.has-feedback .ant-legacy-form-item-children-icon {
  display: inline-block;
  color: #1890ff
}

.ant-advanced-search-form .ant-legacy-form-item {
  margin-bottom: 24px
}

.ant-advanced-search-form .ant-legacy-form-item-with-help {
  margin-bottom: 4px
}

.show-help-appear,.show-help-enter,.show-help-leave {
  animation-duration: .3s;
  animation-fill-mode: both;
  animation-play-state: paused
}

.show-help-appear.show-help-appear-active,.show-help-enter.show-help-enter-active {
  animation-name: antShowHelpIn;
  animation-play-state: running
}

.show-help-leave.show-help-leave-active {
  animation-name: antShowHelpOut;
  animation-play-state: running;
  pointer-events: none
}

.show-help-appear,.show-help-enter {
  opacity: 0
}

.show-help-appear,.show-help-enter,.show-help-leave {
  animation-timing-function: cubic-bezier(.645,.045,.355,1)
}

@keyframes antShowHelpIn {
  0% {
      transform: translateY(-5px);
      opacity: 0
  }

  to {
      transform: translateY(0);
      opacity: 1
  }
}

@keyframes antShowHelpOut {
  to {
      transform: translateY(-5px);
      opacity: 0
  }
}

@keyframes diffZoomIn1 {
  0% {
      transform: scale(0)
  }

  to {
      transform: scale(1)
  }
}

@keyframes diffZoomIn2 {
  0% {
      transform: scale(0)
  }

  to {
      transform: scale(1)
  }
}

@keyframes diffZoomIn3 {
  0% {
      transform: scale(0)
  }

  to {
      transform: scale(1)
  }
}

.ant-mention-wrapper {
  box-sizing: border-box;
  margin: 0;
  font-size: 14px;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum";
  display: inline-block;
  vertical-align: middle
}

.ant-mention-wrapper,.ant-mention-wrapper .ant-mention-editor {
  padding: 0;
  color: rgba(0,0,0,.85);
  line-height: 1.5715;
  position: relative;
  width: 100%
}

.ant-mention-wrapper .ant-mention-editor {
  display: inline-block;
  min-width: 0;
  font-size: 14px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all .3s;
  display: block;
  height: auto;
  min-height: 32px
}

.ant-mention-wrapper .ant-mention-editor::-moz-placeholder {
  opacity: 1
}

.ant-mention-wrapper .ant-mention-editor::placeholder {
  color: #bfbfbf
}

.ant-mention-wrapper .ant-mention-editor:placeholder-shown {
  text-overflow: ellipsis
}

.ant-mention-wrapper .ant-mention-editor:hover {
  border-color: #40a9ff;
  border-right-width: 1px!important
}

.ant-mention-wrapper .ant-mention-editor-focused,.ant-mention-wrapper .ant-mention-editor:focus {
  border-color: #40a9ff;
  border-right-width: 1px!important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(24,144,255,.2)
}

.ant-mention-wrapper .ant-mention-editor-disabled {
  color: rgba(0,0,0,.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1
}

.ant-mention-wrapper .ant-mention-editor-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px!important
}

.ant-mention-wrapper .ant-mention-editor[disabled] {
  color: rgba(0,0,0,.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1
}

.ant-mention-wrapper .ant-mention-editor[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px!important
}

.ant-mention-wrapper .ant-mention-editor-borderless,.ant-mention-wrapper .ant-mention-editor-borderless-disabled,.ant-mention-wrapper .ant-mention-editor-borderless-focused,.ant-mention-wrapper .ant-mention-editor-borderless:focus,.ant-mention-wrapper .ant-mention-editor-borderless:hover,.ant-mention-wrapper .ant-mention-editor-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none
}

textarea.ant-mention-wrapper .ant-mention-editor {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all .3s,height 0s
}

.ant-mention-wrapper .ant-mention-editor-lg {
  padding: 6.5px 11px;
  font-size: 16px
}

.ant-mention-wrapper .ant-mention-editor-sm {
  padding: 0 7px
}

.ant-mention-wrapper .ant-mention-editor-wrapper {
  height: auto;
  overflow-y: auto
}

.ant-mention-wrapper.ant-mention-active:not(.disabled) .ant-mention-editor {
  border-color: #40a9ff;
  border-right-width: 1px!important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(24,144,255,.2)
}

.ant-mention-wrapper.disabled .ant-mention-editor {
  color: rgba(0,0,0,.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1
}

.ant-mention-wrapper.disabled .ant-mention-editor:hover {
  border-color: #d9d9d9;
  border-right-width: 1px!important
}

.ant-mention-wrapper .public-DraftEditorPlaceholder-root {
  position: absolute;
  pointer-events: none
}

.ant-mention-wrapper .public-DraftEditorPlaceholder-root .public-DraftEditorPlaceholder-inner {
  height: auto;
  padding: 5px 11px;
  color: #bfbfbf;
  white-space: pre-wrap;
  word-wrap: break-word;
  outline: none;
  opacity: 1
}

.ant-mention-wrapper .DraftEditor-editorContainer .public-DraftEditor-content {
  height: auto;
  padding: 5px 11px
}

.ant-mention-dropdown {
  box-sizing: border-box;
  padding: 0;
  color: rgba(0,0,0,.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  min-width: 120px;
  max-height: 250px;
  margin: 1.5em 0 0;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #fff;
  border-radius: 2px;
  outline: none;
  box-shadow: 0 3px 6px -4px rgba(0,0,0,.12),0 6px 16px 0 rgba(0,0,0,.08),0 9px 28px 8px rgba(0,0,0,.05)
}

.ant-mention-dropdown-placement-top {
  margin-top: -.1em
}

.ant-mention-dropdown-notfound.ant-mention-dropdown-item {
  color: rgba(0,0,0,.25)
}

.ant-mention-dropdown-notfound.ant-mention-dropdown-item .anticon-loading {
  display: block;
  color: #1890ff;
  text-align: center
}

.ant-mention-dropdown-item {
  position: relative;
  display: block;
  padding: 5px 12px;
  overflow: hidden;
  color: rgba(0,0,0,.85);
  font-weight: 400;
  line-height: 22px;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  transition: background .3s
}

.ant-mention-dropdown-item:hover {
  background-color: #f5f5f5
}

.ant-mention-dropdown-item-active,.ant-mention-dropdown-item.focus {
  background-color: #e6f7ff
}

.ant-mention-dropdown-item-disabled {
  color: rgba(0,0,0,.25);
  cursor: not-allowed
}

.ant-mention-dropdown-item-disabled:hover {
  color: rgba(0,0,0,.25);
  background-color: #fff;
  cursor: not-allowed
}

.ant-mention-dropdown-item-selected,.ant-mention-dropdown-item-selected:hover {
  color: rgba(0,0,0,.85);
  font-weight: 700;
  background-color: #f5f5f5
}

.ant-mention-dropdown-item-divider {
  height: 1px;
  margin: 1px 0;
  overflow: hidden;
  line-height: 0;
  background-color: #f0f0f0
}

.email-confirm-spin .ant-spin-dot i {
  background-color: #000
}

.check-left-time {
  border: 1px solid #ffe58f;
  background-color: #fffbe6;
  padding: 8px 15px;
  text-align: center
}

.check-email-website {
  padding: 14px 0 0;
  text-align: center
}

.bottom-msg {
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid #ccc
}

.bottom-msg ul li {
  list-style: disc;
  margin-left: 28px
}

h1 {
  margin-bottom: .5em
}

p,ul {
  margin-bottom: 1em
}

.resent-email {
  text-align: center;
  padding-top: 20px
}

.resent-email button {
  margin-left: 20px
}

.my-5 {
  margin-left: 5px;
  margin-right: 5px
}

body,html,main {
  scroll-behavior: smooth
}

li,ul {
  list-style: none
}

* {
  word-break: break-word
}

p {
  margin-bottom: 0
}

ul {
  -webkit-padding-start: 0;
  padding-left: 0
}

img {
  max-height: 100%;
  max-width: 100%
}

.truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden
}

.row-top {
  margin-top: 16px
}

.page-title {
  line-height: 50px;
  display: inline-block
}

.vertical-center-modal {
  text-align: center
}

.vertical-center-modal:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  width: 0
}

.vertical-center-modal .ant-modal {
  display: inline-block;
  vertical-align: middle;
  top: 8px;
  padding-bottom: 8px;
  text-align: left
}

.reset-scrollbar::-webkit-scrollbar {
  width: 6px
}

.reset-scrollbar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(20,20,20,.3);
  -webkit-box-shadow: inset 0 0 6px rgba(20,20,20,.3);
  border-radius: 10px
}

.reset-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(20,20,20,.1);
  box-shadow: inset 0 0 6px rgba(20,20,20,.5);
  -webkit-box-shadow: inset 0 0 6px rgba(20,20,20,.5)
}

.table-no-border .ant-table-tbody>tr>td,.table-no-border .ant-table-thead>tr>th {
  border: none
}

.table-no-border .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):before {
  display: none
}

.table-no-border .ant-table-placeholder {
  border: 0
}

.table-content-centered .ant-table-row td {
  vertical-align: middle
}

.table-centered .ant-table-tbody>tr>td,.table-centered .ant-table-thead>tr>th {
  text-align: center
}

.table-footer-transparent .ant-table {
  background-color: #fff
}

.ant-table-expand-icon-col,.ant-table-selection-col {
  width: 30px
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar
}

.table-grid-actions {
  display: flex;
  flex-direction: column
}

.table-grid-actions>a {
  display: block
}

.image-thumbnails {
  display: flex
}

.image-thumbnail {
  margin-bottom: 16px;
  margin-right: 16px
}

.image-thumbnail>img {
  display: block;
  height: 88px;
  width: 88px
}

.ant-form-item.hide-required-mark .ant-form-item-label label.ant-form-item-required:before,.ant-form-item.hide-required-mark .ant-legacy-form-item-label label.ant-legacy-form-item-required:before,.ant-legacy-form-item.hide-required-mark .ant-form-item-label label.ant-form-item-required:before,.ant-legacy-form-item.hide-required-mark .ant-legacy-form-item-label label.ant-legacy-form-item-required:before {
  display: none
}

.ant-form-item.show-required-mark .ant-form-item-label>label:before,.ant-form-item.show-required-mark .ant-legacy-form-item-label>label:before,.ant-legacy-form-item.show-required-mark .ant-form-item-label>label:before,.ant-legacy-form-item.show-required-mark .ant-legacy-form-item-label>label:before {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun,sans-serif;
  line-height: 1;
  content: "*"
}

.ant-form-item.read-only .ant-input,.ant-form-item.read-only .ant-input:focus,.ant-form-item.read-only .ant-input:hover {
  background-color: #f5f5f5;
  border-color: transparent;
  box-shadow: none
}

label.required:before {
  display: inline-block;
  margin-right: 4px;
  color: #f5222d;
  font-size: 14px;
  line-height: 1;
  content: "*"
}

.ant-select-loading .ant-select-selection-item {
  color: transparent
}

.ant-radio-group.vertical-radio-group>label {
  display: block;
  margin-bottom: 8px
}

.ant-tabs.rounded-tabs .ant-tabs-nav {
  padding: 12px 16px
}

.ant-tabs.rounded-tabs .ant-tabs-top-bar {
  margin-bottom: 0
}

.ant-tabs.rounded-tabs .ant-tabs-tab {
  border: 1px solid #e5e5e5;
  border-radius: 16px;
  padding: 3px 16px
}

.ant-tabs.rounded-tabs .ant-tabs-tab.ant-tabs-tab-active {
  background-color: #e4f5ff;
  border-color: #e4f5ff
}

.ant-tabs.rounded-tabs .ant-tabs-ink-bar {
  display: none!important
}

.ant-tabs.paged-tabs.ant-tabs-top>.ant-tabs-nav {
  margin-bottom: 0
}

.ant-tabs.paged-tabs.ant-tabs-top>.ant-tabs-nav:before {
  border: none
}

.ant-tabs.paged-tabs.ant-tabs-top .ant-tabs-tab {
  background: #f9fafb;
  border-radius: 10px 10px 0 0;
  margin: 0;
  padding: 16px 40px
}

.ant-tabs.paged-tabs.ant-tabs-top .ant-tabs-tab.ant-tabs-tab-active {
  background-color: #fff
}

.ant-tabs.paged-tabs.ant-tabs-top .ant-tabs-ink-bar {
  display: none
}

.ant-tabs.paged-tabs .ant-tabs-content {
  background-color: #fff;
  border-radius: 0 10px 10px 10px
}

a.patched-ant-btn {
  padding-top: 4px!important
}

.ant-btn.min-w-0 {
  min-width: 0
}

.ant-legacy-form-item-control.has-error .ant-select-selector:not([disabled]),.ant-legacy-form-item-control.has-error .ant-select-selector:not([disabled]):hover {
  border-color: #ff4d4f
}

.page-layout-nav {
  align-items: center;
  display: flex;
  font-size: 16px
}

.page-layout-nav .nav-item:not(:first-child) {
  margin-left: 16px
}

.page-layout-nav .nav-item a {
  border-bottom: 2px solid transparent;
  color: rgba(0,0,0,.85);
  display: block;
  line-height: 18px;
  padding: 8px 8px 6px
}

.page-layout-nav .nav-item a:hover {
  color: #1890ff
}

.page-layout-nav .nav-item a.active {
  color: #1890ff;
  border-bottom-color: #1890ff
}

.page-container {
  background-color: #f0f2f5;
  padding: 50px
}

.page-container .page-inner {
  margin-left: auto;
  margin-right: auto;
  max-width: 1440px
}

.full-content-height {
  height: calc(100vh - 96px)
}

.icon-container {
  align-items: center;
  border-radius: 50%;
  display: inline-flex;
  height: 24px;
  justify-content: center;
  width: 24px
}

.video-modal .ant-modal-body {
  padding: 0
}

.video-modal .ant-modal-close {
  right: -40px;
  top: -40px
}

.highlight-modal .ant-modal-content {
  border: 4px solid rgba(0,0,0,.8)
}

.highlight-modal .ant-modal-content .ant-modal-header {
  background-color: #1268bb;
  border-radius: 0
}

.highlight-modal .ant-modal-content .ant-modal-header .ant-modal-title {
  color: #fff
}

.highlight-modal .ant-btn {
  color: #1268bb;
  border-color: #1268bb
}

.highlight-modal .ant-btn.ant-btn-primary {
  background: #1268bb;
  color: #fff
}

.ant-tag.actionable {
  cursor: pointer
}

.ant-tag.actionable.is-selected {
  background-color: #e9f4fe;
  border-color: #1890ff;
  color: #4091f7
}

.add-tag-button {
  background: #fff;
  border-radius: 2px;
  border: 1px dashed #d9d9d9;
  color: #999;
  font-size: 12px;
  line-height: 20px;
  height: 22px;
  padding: 0 7px
}

.article img {
  max-width: 100%
}

.article p {
  margin-bottom: 1em
}

.article table {
  max-width: 100%
}

.article table,.article table td,.article table th {
  border-style: solid;
  border-width: 1px
}

.ant-form-vertical .horizontal-form-item {
  align-items: flex-start;
  flex-direction: row
}

.ant-form-vertical .horizontal-form-item .ant-form-item-label {
  padding-bottom: 5px;
  padding-right: .5em;
  padding-top: 5px
}

.ant-form-item-vertical .ant-form-item-label {
  text-align: left;
  width: 100%
}

#root .ant-image-preview-mask,#root .ant-modal-mask {
  background-color: rgba(0,0,0,.6)
}

.inblock {
  display: inline-block;
  vertical-align: top
}

* {
  margin: 0;
  padding: 0
}

body {
  -webkit-font-smoothing: antialiased;
  color: #222
}

body div p,body ul li p {
  margin-bottom: 0
}

body li,body ul {
  list-style: none
}

body ul {
  -webkit-padding-start: 0;
  padding-left: 0;
  margin-bottom: 0
}

.price {
  padding: 0 4px;
  color: #e04d48
}

html[lang=cn] #root .en-only,html[lang=en] #root .cn-only,html[lang=zh-Hans] #root .en-only {
  display: none
}

html:not([lang=cn]) .non-cn-pad {
  margin-left: 5px
}

body .d-none {
  display: none
}

body .d-block {
  display: block
}

body .d-inline-block {
  display: inline-block
}

body .d-flex {
  display: flex
}

body .d-inline-flex {
  display: inline-flex
}

body .d-none-empty:empty {
  display: none
}

body .align-middle {
  vertical-align: middle
}

body .items-start {
  align-items: flex-start
}

body .items-center {
  align-items: center
}

body .items-end {
  align-items: flex-end
}

body .self-start {
  align-self: flex-start
}

body .self-end {
  align-self: flex-end
}

body .justify-between {
  justify-content: space-between
}

body .justify-around {
  justify-content: space-around
}

body .justify-center {
  justify-content: center
}

body .flex-nowrap {
  flex-wrap: nowrap
}

body .order-last {
  order: 9999
}

@media (min-width: 1024px) {
  body .lg\:visible {
      display:block
  }
}

body .hidden {
  display: none!important
}

body .invisible {
  visibility: hidden
}

body .mx-auto {
  margin-left: auto;
  margin-right: auto
}

body .mx--5 {
  margin-left: -5px;
  margin-right: -5px
}

body .mt-auto {
  margin-top: auto
}

body .mt-4 {
  margin-top: 4px
}

body .mt-5 {
  margin-top: 5px
}

body .mt-8 {
  margin-top: 8px
}

body .mt-10 {
  margin-top: 10px
}

body .mt-12 {
  margin-top: 12px
}

body .mt-16 {
  margin-top: 16px
}

body .mt-20 {
  margin-top: 20px
}

body .mt-24 {
  margin-top: 24px
}

body .mt-30 {
  margin-top: 30px
}

body .mt-40 {
  margin-top: 40px
}

body .mt-60 {
  margin-top: 60px
}

body .-mt-4 {
  margin-top: -4px
}

body .-mt-8 {
  margin-top: -8px
}

body .mr-auto {
  margin-right: auto
}

body .mr-3 {
  margin-right: 3px
}

body .mr-4 {
  margin-right: 4px
}

body .mr-5 {
  margin-right: 5px
}

body .mr-8 {
  margin-right: 8px
}

body .mr-10 {
  margin-right: 10px
}

body .mr-12 {
  margin-right: 12px
}

body .mr-16 {
  margin-right: 16px
}

body .mr-20 {
  margin-right: 20px
}

body .mr-24 {
  margin-right: 24px
}

body .mr-30 {
  margin-right: 30px
}

body .mr-40 {
  margin-right: 40px
}

body .mb-0 {
  margin-bottom: 0
}

body .mb-4 {
  margin-bottom: 4px
}

body .mb-5 {
  margin-bottom: 5px
}

body .mb-8 {
  margin-bottom: 8px
}

body .mb-10 {
  margin-bottom: 10px
}

body .mb-12 {
  margin-bottom: 12px
}

body .mb-14 {
  margin-bottom: 14px
}

body .mb-16 {
  margin-bottom: 16px
}

body .mb-20 {
  margin-bottom: 20px
}

body .mb-22 {
  margin-bottom: 22px
}

body .mb-24 {
  margin-bottom: 24px
}

body .mb-30 {
  margin-bottom: 30px
}

body .mb-40 {
  margin-bottom: 40px
}

body .mb-60 {
  margin-bottom: 60px
}

body .mb-80 {
  margin-bottom: 80px
}

body .ml-auto {
  margin-left: auto
}

body .ml-4 {
  margin-left: 4px
}

body .ml-5 {
  margin-left: 5px
}

body .ml-8 {
  margin-left: 8px
}

body .ml-10 {
  margin-left: 10px
}

body .ml-12 {
  margin-left: 12px
}

body .ml-16 {
  margin-left: 16px
}

body .ml-20 {
  margin-left: 20px
}

body .ml-24 {
  margin-left: 24px
}

body .ml-30 {
  margin-left: 30px
}

body .ml-32 {
  margin-left: 32px
}

body .ml-40 {
  margin-left: 40px
}

body .-ml-10 {
  margin-left: -10px
}

body .m-16 {
  margin: 16px
}

body .p-8 {
  padding: 8px
}

body .p-10 {
  padding: 10px
}

body .p-16 {
  padding: 16px
}

body .p-20 {
  padding: 20px
}

body .px-5 {
  padding-left: 5px;
  padding-right: 5px
}

body .px-16 {
  padding-left: 16px;
  padding-right: 16px
}

body .px-14 {
  padding-left: 14px;
  padding-right: 14px
}

body .px-20 {
  padding-bottom: 20px;
  padding-top: 20px
}

body .py-8 {
  padding-left: 8px;
  padding-right: 8px
}

body .py-10 {
  padding-left: 10px;
  padding-right: 10px
}

body .py-20 {
  padding-left: 20px;
  padding-right: 20px
}

body .pt-5 {
  padding-top: 5px
}

body .pt-6 {
  padding-top: 6px
}

body .pt-10 {
  padding-top: 10px
}

body .pt-12 {
  padding-top: 12px
}

body .pt-16 {
  padding-top: 16px
}

body .pt-20 {
  padding-top: 20px
}

body .pt-30 {
  padding-top: 30px
}

body .pt-50 {
  padding-top: 50px
}

body .pt-60 {
  padding-top: 60px
}

body .pr-8 {
  padding-right: 8px
}

body .pb-24 {
  padding-top: 24px
}

body .pr-20 {
  padding-right: 20px
}

body .pr-30 {
  padding-right: 30px
}

body .pr-40 {
  padding-right: 40px
}

body .pb-0 {
  padding-bottom: 0
}

body .pb-5 {
  padding-bottom: 5px
}

body .pb-6 {
  padding-bottom: 6px
}

body .pb-8 {
  padding-bottom: 8px
}

body .pb-10 {
  padding-bottom: 10px
}

body .pb-12 {
  padding-bottom: 12px
}

body .pb-16 {
  padding-bottom: 16px
}

body .pb-20 {
  padding-bottom: 20px
}

body .pb-30 {
  padding-bottom: 30px
}

body .pb-50 {
  padding-bottom: 50px
}

body .pb-60 {
  padding-bottom: 60px
}

body .pr-10 {
  padding-right: 10px
}

body .pr-16 {
  padding-right: 16px
}

body .pr-24 {
  padding-right: 24px
}

body .pl-8 {
  padding-left: 8px
}

body .pl-10 {
  padding-left: 10px
}

body .pl-16 {
  padding-left: 16px
}

body .pl-24 {
  padding-left: 24px
}

body .pl-30 {
  padding-left: 30px
}

body .min-w-0 {
  min-width: 0
}

body .leading-5 {
  line-height: 20px
}

body .leading-6 {
  line-height: 24px
}

body .leading-7 {
  line-height: 28px
}

body .leading-8 {
  line-height: 32px
}

body .line-height-label {
  line-height: 22px
}

body .line-height-form-item-control {
  line-height: 32px
}

body .text-10 {
  font-size: 10px
}

body .text-12 {
  font-size: 12px
}

body .text-14 {
  font-size: 14px
}

body .text-16 {
  font-size: 16px
}

body .text-18 {
  font-size: 18px
}

body .text-20 {
  font-size: 20px
}

body .text-22 {
  font-size: 22px
}

body .text-24 {
  font-size: 24px
}

body .text-26 {
  font-size: 26px
}

body .text-28 {
  font-size: 28px
}

body .text-32 {
  font-size: 32px
}

body .text-72 {
  font-size: 72px
}

body .text-h1 {
  font-size: 38px
}

body .text-h2 {
  font-size: 30px
}

body .text-h3 {
  font-size: 24px
}

body .text-h4 {
  font-size: 20px
}

body .text-h5 {
  font-size: 16px
}

body .text-normal {
  font-weight: 400
}

body .text-bold {
  font-weight: 700
}

body .text-medium {
  font-weight: 500
}

body .text-white {
  color: #fff
}

body .text-gray,body .text-gray.anticon {
  color: #999
}

body .text-gray-dark {
  color: #666
}

body .hover\:text-black:hover,body .text-black {
  color: #333
}

body .text-cyan {
  color: #00d4fe
}

body .text-blue {
  color: #1890ff
}

body .text-green {
  color: #52c41a
}

body .text-yellow {
  color: #f8aa00
}

body .text-orange {
  color: #eb650a
}

body .text-red {
  color: #f3373d
}

body .text-primary,body .text-primary.anticon {
  color: #1890ff
}

body .text-left {
  text-align: left
}

body .text-right {
  text-align: right
}

body .text-center {
  text-align: center
}

body .uppercase {
  text-transform: uppercase
}

body .line-through {
  text-decoration-line: line-through
}

body .underline {
  text-decoration-line: underline
}

body .no-underline {
  text-decoration-line: none
}

body .truncate {
  overflow: hidden;
  text-overflow: ellipsis
}

body .truncate,body .whitespace-nowrap {
  white-space: nowrap
}

body .line-clamp-1 {
  -webkit-line-clamp: 1
}

body .line-clamp-1,body .line-clamp-2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden
}

body .line-clamp-2 {
  -webkit-line-clamp: 2
}

body .line-clamp-3 {
  -webkit-line-clamp: 3
}

body .line-clamp-3,body .line-clamp-4 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden
}

body .line-clamp-4 {
  -webkit-line-clamp: 4
}

body .bg-transparent {
  background: transparent
}

body .bg-white {
  background-color: #fff
}

body .bg-gray {
  background-color: #fafafa
}

body .bg-gray-dark {
  background-color: #eee
}

body .bg-secondary {
  background-color: #f9f9f9
}

body .bg-tertiary {
  background-color: #f2f2f2
}

body .relative {
  position: relative
}

body .absolute {
  position: absolute
}

body .overflow-auto {
  overflow: auto
}

body .overflow-hidden {
  overflow: hidden
}

body .flex-center-vertical {
  align-items: center;
  display: flex
}

body .flex-middle-horizontal {
  display: flex;
  justify-content: center
}

body .justify-end {
  justify-content: flex-end
}

body .flex-wrap {
  flex-wrap: wrap
}

body .flex-grow-0 {
  flex-grow: 0
}

body .flex-grow-1 {
  flex-grow: 1
}

body .flex-shrink-0 {
  flex-shrink: 0
}

body .capitalize {
  text-transform: capitalize
}

body .preformatted {
  word-break: break-word;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word
}

body .hyphens-auto {
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto
}

body .h-full {
  height: 100%
}

body .max-w-full {
  max-width: 100%
}

body .w-0 {
  width: 0
}

body .w-px {
  width: 1px
}

body .w-0\.5 {
  width: 2px
}

body .w-1 {
  width: 4px
}

body .w-1\.5 {
  width: 6px
}

body .w-2 {
  width: 8px
}

body .w-2\.5 {
  width: 10px
}

body .w-3 {
  width: 12px
}

body .w-3\.5 {
  width: 14px
}

body .w-4 {
  width: 16px
}

body .w-5 {
  width: 20px
}

body .w-6 {
  width: 24px
}

body .w-7 {
  width: 28px
}

body .w-8 {
  width: 32px
}

body .w-9 {
  width: 36px
}

body .w-10 {
  width: 40px
}

body .w-11 {
  width: 44px
}

body .w-12 {
  width: 48px
}

body .w-14 {
  width: 56px
}

body .w-16 {
  width: 64px
}

body .w-20 {
  width: 80px
}

body .w-24 {
  width: 96px
}

body .w-28 {
  width: 112px
}

body .w-32 {
  width: 128px
}

body .w-36 {
  width: 144px
}

body .w-40 {
  width: 160px
}

body .w-44 {
  width: 176px
}

body .w-48 {
  width: 192px
}

body .w-52 {
  width: 208px
}

body .w-56 {
  width: 224px
}

body .w-60 {
  width: 240px
}

body .w-64 {
  width: 256px
}

body .w-72 {
  width: 288px
}

body .w-80 {
  width: 320px
}

body .w-96 {
  width: 384px
}

body .w-auto {
  width: auto
}

body .w-full {
  width: 100%
}

body .width-1by2 {
  width: 50%
}

body .width-1by3 {
  width: 33.33333333%
}

body .width-2by3 {
  width: 66.66666667%
}

body .width-1by5 {
  width: 20%
}

body .basis-1by2 {
  flex-basis: 50%
}

body .basis-1by3 {
  flex-basis: 33.33333333%
}

body .basis-2by3 {
  flex-basis: 66.66666667%
}

body .basis-1by5 {
  flex-basis: 20%
}

body .basis-2by5 {
  flex-basis: 40%
}

body .basis-3by5 {
  flex-basis: 60%
}

body .aspect-square {
  aspect-ratio: 1
}

body .z-10 {
  z-index: 10
}

body .rounded {
  border-radius: 4px
}

body .square-container {
  height: 0;
  padding-bottom: 100%
}

body .cursor-default {
  cursor: default
}

body .cursor-pointer {
  cursor: pointer
}

body .cursor-move {
  cursor: move
}

body .select-none {
  user-select: none
}

body .animate-none {
  animation: none
}

body .list-decimal,body .list-disc {
  margin-left: 1em
}

body .list-decimal>.li,body .list-disc>.li {
  display: list-item
}

body .list-decimal>.li,body .list-decimal>li {
  list-style-type: decimal
}

body .list-disc>.li,body .list-disc>li {
  list-style-type: disc
}

body .absolute-center {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%,-50%)
}

body .tiled-image {
  position: relative
}

body .tiled-image img {
  bottom: -100%;
  max-width: 100%;
  max-height: 100%;
  left: -100%;
  margin: auto;
  position: absolute;
  right: -100%;
  top: -100%
}

body .default-image-width-patch img {
  max-width: 100%;
  width: auto
}

body .clickable {
  cursor: pointer;
  color: #1890ff
}

body .flying {
  background-color: #40a9ff;
  border-radius: 50%;
  height: 30px;
  opacity: 1;
  position: fixed;
  transform-origin: center bottom;
  transform: scale(1);
  width: 30px;
  z-index: 10000
}

body .dot-flashing {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dot-flashing 1s linear infinite alternate;
  animation-delay: .5s
}

body .dot-flashing:after,body .dot-flashing:before {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0
}

body .dot-flashing:before {
  left: -15px;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0s
}

body .dot-flashing:after,body .dot-flashing:before {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff
}

body .dot-flashing:after {
  left: 15px;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 1s
}

@keyframes dot-flashing {
  0% {
      background-color: #9880ff
  }

  50%,to {
      background-color: rgba(152,128,255,.2)
  }
}

body .visible-if-flipped {
  display: none
}

body .flipped .visible-if-flipped {
  display: block
}

body .flipped .hidden-if-flipped {
  display: none
}

body .patch-row {
  margin-left: 0!important;
  margin-right: 0!important
}

body .ant-btn.wide-btn:not(.ant-btn-icon-only) {
  min-width: 200px
}

body .lg\:visible,body .md\:visible {
  display: none
}

body .lg\:hidden {
  display: block
}

body .lg\:hidden.d-flex {
  display: flex
}

body .\2xl\:visible,body .\3xl\:visible,body .xl\:visible {
  display: none
}

@media (min-width: 768px) {
  body .md\:hidden {
      display:none
  }

  body .md\:visible {
      display: block
  }

  body .md\:visible.d-flex {
      display: flex
  }

  body .md\:d-inline {
      display: inline
  }
}

@media (min-width: 1024px) {
  body .lg\:hidden {
      display:none
  }

  body .lg\:visible {
      display: block
  }

  body .lg\:visible.d-flex {
      display: flex
  }

  body .lg\:visible.d-inline-flex {
      display: inline-flex
  }
}

@media (min-width: 1280px) {
  body .xl\:visible {
      display:block
  }
}

@media (min-width: 1536px) {
  body .\2xl\:visible {
      display:block
  }
}

@media (min-width: 1792px) {
  body .\3xl\:visible {
      display:block
  }
}

.hide {
  display: none
}

body {
  background: #f5f5f5
}

#container,.canvas-bg {
  width: 100%;
  height: 100%
}

#container {
  overflow: hidden;
  min-width: 940px;
  min-height: 620px;
  overflow-y: auto
}

#container .ant-message-notice-content {
  font-size: 16px;
  height: 45px;
  line-height: 28px;
  background: #f33831;
  color: #fff
}

#container .ant-message-notice-content i {
  color: #fff
}

.login-banner-row {
  // width: 950px;
  width: 535px;
  height: 630px;
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto
}

.login-banner-row .login-box {
  height: 100%;
  width: 535px;
  border-radius: 4px;
  box-sizing: border-box;
  background: #fff
}

.login-banner-row .login-box .mb-28 {
  margin-bottom: 28px
}

.login-banner-row .login-box .login-href {
  display: block;
  width: 300px;
  margin: 60px auto 50px;
  cursor: pointer;
  text-align: center
}

.login-banner-row .login-box .login-form {
  width: 400px;
  margin: 0 auto
}

.login-banner-row .login-box .login-form .has-error .ant-legacy-form-explain,.login-banner-row .login-box .login-form .has-error .ant-legacy-form-split {
  margin-top: 2px
}

.login-banner-row .login-box .ant-btn-primary {
  display: block;
  margin: 0 auto;
  width: 100%
}

.login-banner-row .login-box .ant-btn-primary:not(:disabled) {
  background-color: #004CBB;
  border-color: #004CBB
}

.login-banner-row .login-box .ant-checkbox+span {
  color: #333
}

.login-banner-row .login-box .ant-legacy-form-item {
  height: 69px;
  margin-bottom: 0
}

.login-banner-row .login-box .ant-btn-primary:focus:not(:disabled),.login-banner-row .login-box .ant-btn-primary:hover:not(:disabled) {
  background-color: #004CBB;
  border-color: #004CBB;
  opacity: .85
}

.login-banner-row .login-box .ant-input:not(.ant-input-lg):focus {
  border-color: #004CBB;
  box-shadow: 0 0 0 2px rgba(255,80,103,.2)
}

.login-banner-row .login-box .ant-input:not(.ant-input-lg):hover {
  border-color: #004CBB
}

.login-banner-row .login-box .ant-input-group-wrapper {
  display: block
}

.login-banner-row .login-box .ant-checkbox-checked .ant-checkbox-inner,.login-banner-row .login-box .ant-checkbox-indeterminate .ant-checkbox-inner {
  background-color: #004CBB;
  border-color: #004CBB
}

.login-banner-row .login-box .ant-checkbox-input:focus+.ant-checkbox-inner,.login-banner-row .login-box .ant-checkbox-wrapper:hover .ant-checkbox-inner,.login-banner-row .login-box .ant-checkbox:hover .ant-checkbox-inner,.login-banner-row .login-box .ant-select-auto-complete.ant-select .ant-input:focus,.login-banner-row .login-box .ant-select-auto-complete.ant-select .ant-input:hover {
  border-color: #004CBB
}

.login-banner-row .login-box .forgot-password {
  padding-bottom: 5px;
  text-align: center
}

.login-banner-row .login-box .goto-register {
  width: 300px;
  margin: 10px auto 20px;
  text-align: center;
  border-top: 1px solid #ddd;
  line-height: 50px;
  position: relative;
  zoom:1}

.login-banner-row .login-box .password-show {
  position: absolute;
  top: 50%;
  right: 10px;
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: pointer
}

.login-banner-row .banner-box {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 630px;
  overflow: hidden;
  width: 415px
}

.login-banner-row .banner-box-image {
  max-height: 100%;
  max-width: 100%
}

.policyBox {
  width: 950px;
  height: 630px;
  background: #fff;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  z-index: 12
}

.policyBox_header {
  height: 42px;
  display: flex;
  align-items: center;
  background-color: #f9f9f9;
  border-bottom: 1px solid #e5e5e5
}

.policyBox_header_title {
  padding-left: 24px;
  color: #333;
  font-weight: 500
}

.policyBox_header_img {
  padding-right: 24px;
  margin-left: auto;
  cursor: pointer
}

.policyBox_content {
  height: 538px;
  padding: 24px;
  overflow-y: auto
}

.policyBox_footer {
  height: 50px;
  padding-right: 24px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: #f9f9f9;
  background-color: #f9f9f9
}

.policyBox_footer_button {
  min-width: 120px;
  line-height: 32px;
  padding: 0 24px;
  text-align: center;
  color: #fff;
  background-color: #f3383e;
  border-radius: 4px;
  cursor: pointer
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  background: #000;
  opacity: .5;
  z-index: 11
}

/*# sourceMappingURL=login.b33c2bc6.css.map*/
