.lite-card {
  background-color: #fff
}

.lite-card.lite-card-type-inner .lite-card-header {
  background-color: #f9f9f9
}

.lite-card-header {
  align-items: center;
  display: flex;
  padding: 16px
}

.lite-card-title {
  color: #333;
  font-size: 14px;
  line-height: 20px;
  margin: 0
}

.lite-card-extra {
  margin-left: auto
}

.lite-card-body {
  padding: 16px
}