.list-shop {
}

.list-shop li {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

.list-shop li > span {
  display: block;
  margin-right: 4px;
}


