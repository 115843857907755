.products-main-filters {
  align-items: flex-start;
  background-color: #fff;
  border-radius: 4px;
  color: #333;
  display: flex;
  margin-bottom: 24px;
  padding: 16px 16px 0
}

.products-main-filters .ant-form-item {
  margin-bottom: 16px
}

.products-main-filters .sorter {
  padding-left: 0
}

.products-main-filters .ant-input-number {
  min-width: 100px
}

.products-main-filters-form-actions {
  display: flex;
  justify-content: end;
}

.products-main-filters.has-only-sorter-filters .products-main-filters-form-actions,.products-main-filters:not(.is-expanded) .is-collapsible {
  display: none
}

.products-main-filters-group {
  display: flex;
  margin-bottom: 12px;
}

.products-main-filters-label {
  flex-shrink: 0;
  font-weight: 500;
  margin-right: 16px;
  width: 160px
}

.products-main-filters-controls {
  flex-grow: 1;
  display: flex;
  align-items: center;
  height: 100%;
}

.products-main-filters-item {
  margin-right: 16px
}

.products-main-filters-toggle-more {
  cursor: pointer;
  line-height: 32px
}

.products-main-filter {
  align-items: center;
  display: inline-flex;
  padding-bottom: 8px;
  padding-top: 8px
}

.products-main-filter>label {
  margin-right: 12px
}

.products-main-filter>label:after {
  content: ":"
}

.products-main-filter:not(:last-child) {
  margin-right: 40px
}

.products-main-search-image-scanner .upload-preview {
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%
}

.products-main-search-image-scanner .upload-preview img {
  border-radius: 3px;
  bottom: 0;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0
}

.products-main-search-image-scanner.is-uploading .upload-preview .anticon {
  opacity: 0
}

.products-main-search-image-scanner.is-uploading .upload-preview img {
  opacity: .5
}

.upload-preview {
  align-items: center;
  display: flex;
  cursor: pointer;
  justify-content: center;
  position: relative
}

.upload-preview .anticon {
  position: relative;
  z-index: 0
}

.upload-preview img {
  z-index: 1
}

.upload-preview img,.upload-preview input {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0
}

.upload-preview input {
  cursor: pointer;
  height: 100%;
  font-size: 0;
  opacity: 0;
  width: 100%;
  z-index: 2
}