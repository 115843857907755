#container,.canvas-bg {
  width: 100%;
  height: 100%
}

#container {
  overflow: hidden;
  min-width: 940px;
  min-height: 820px;
  overflow-y: auto
}

#container .ant-message-notice-content {
  font-size: 16px;
  height: 45px;
  line-height: 28px;
  background: #f33831;
  color: #fff
}

#container .ant-message-notice-content i {
  color: #fff
}

.email-confirm-spin .ant-spin-dot i {
  background-color: #000
}

.check-left-time {
  border: 1px solid #ffe58f;
  background-color: #fffbe6;
  padding: 8px 15px;
  text-align: center
}

.check-email-website {
  padding: 14px 0 0;
  text-align: center
}

.bottom-msg {
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid #ccc
}

.bottom-msg ul li {
  list-style: disc;
  margin-left: 28px
}

.register-banner-row {
  width: 950px;
  height: 750px;
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto
}

.register-banner-row .register-box {
  width: 535px;
  border-radius: 4px;
  box-sizing: border-box;
  background: #fff
}

.register-banner-row .register-box h1 {
  margin: 50px auto 0;
  width: 299px;
  height: 102px;
  background: url(https://cdn.buckydrop.com/starit-buckydrop/2024/dist/logo.90ee28ae.png) no-repeat 50%
}

.register-banner-row .register-box .register-form {
  width: 420px;
  margin: 0 auto
}

.register-banner-row .register-box .register-form .tel-phone-code-select-dropdown {
  top: 39px!important;
  width: 420px!important
}

.register-banner-row .register-box .ant-btn-primary {
  display: block;
  margin: 0 auto;
  background-color: #004CBB;
  border-color: #004CBB;
  width: 100%
}

.register-banner-row .register-box .ant-btn-primary[disabled] {
  display: block;
  margin: 0 auto;
  background-color: #ddd;
  border-color: #ddd;
  width: 100%
}

.register-banner-row .register-box .ant-checkbox+span {
  color: #333
}

.register-banner-row .register-box .ant-legacy-form-item {
  height: 69px;
  margin-bottom: 0
}

.register-banner-row .register-box .ant-btn-primary:focus,.register-banner-row .register-box .ant-btn-primary:hover {
  background-color: #004CBB;
  border-color: #004CBB;
  opacity: .85
}

.register-banner-row .register-box .ant-btn-primary[disabled]:focus,.register-banner-row .register-box .ant-btn-primary[disabled]:hover {
  background-color: #ddd;
  border-color: #ddd
}

.register-banner-row .register-box .ant-input:not(.ant-input-lg):focus {
  border-color: #004CBB;
  box-shadow: 0 0 0 2px rgba(255,80,103,.2)
}

.register-banner-row .register-box .ant-input:not(.ant-input-lg):hover {
  border-color: #004CBB
}

.register-banner-row .register-box .ant-input-group-wrapper {
  display: block
}

.register-banner-row .register-box .ant-checkbox-checked .ant-checkbox-inner,.register-banner-row .register-box .ant-checkbox-indeterminate .ant-checkbox-inner {
  background-color: #004CBB;
  border-color: #004CBB
}

.register-banner-row .register-box .ant-checkbox-input:focus+.ant-checkbox-inner,.register-banner-row .register-box .ant-checkbox-wrapper:hover .ant-checkbox-inner,.register-banner-row .register-box .ant-checkbox:hover .ant-checkbox-inner,.register-banner-row .register-box .ant-select-auto-complete.ant-select .ant-input:focus,.register-banner-row .register-box .ant-select-auto-complete.ant-select .ant-input:hover {
  border-color: #004CBB
}

.register-banner-row .register-box .register-title {
  color: #222;
  font-size: 16px;
  text-align: center;
  padding: 10px 0 20px
}

.register-banner-row .register-box .password-show {
  position: absolute;
  top: 50%;
  right: 10px;
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: pointer
}

.register-banner-row .register-box .goto-login {
  width: 300px;
  margin: 10px auto 20px;
  text-align: center;
  border-top: 1px solid #ddd;
  line-height: 55px;
  position: relative;
  zoom:1}

.register-banner-row .banner-box {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 750px;
  overflow: hidden;
  width: 415px
}

.register-banner-row .banner-box-image {
  max-height: 100%;
  max-width: 100%
}

.ant-checkbox-wrapper {
  line-height: 1!important
}

.has-error .ant-select-arrow {
  color: rgba(0,0,0,.25)!important
}

#canvas,#root {
  height: 100%
}

#canvas {
  display: block;
  width: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  -moz-transform: translate(-50%,-50%);
  -o-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%)
}

/*# sourceMappingURL=register.a48c6c34.css.map*/
