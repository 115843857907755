
#page_head_content {
  height: 106px;
  background-color: #fff;
  width: 100%;
  padding: 0 24px;
}

*::-webkit-scrollbar {display:none;} 

.page_head {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  z-index: 99;
}

.page_head_title {
  height: 34px;
}

.inner-content {
  min-height: 360px;
  max-width: 1440px;
  margin: auto;
  height: calc(100vh - 64px);
  overflow: scroll;
}

.inner-content  {
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* IE 10+ */
}

.inner-content::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}
