.goods-product-detail {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.goods-product-detail .container {
  width: auto;
}

.goods-product-detail > div {
  width: 100%;
}

.goods-product-detail img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
}