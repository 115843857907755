.bg-cover-overlay {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3); /* 半透明的黑色 */
  z-index: 1;
}

// html,body {
//   overflow: hidden;
// }

.floatup-effect-mobile {
  animation: floatUpMobile 1s ease forwards;
}



@keyframes floatUpMobile {
  0% {
      transform: translateY(0);
  }
  100% {
      transform: translateY(-5rem);
  }
}

.floatup-fadeIn-mobile {
  animation: fadeInMobile .5s ease forwards;
}

@keyframes fadeInMobile {
  0% {
      opacity: 100;;
  }
  100% {
      opacity: 0;;
  }
}

.search-overlay {
  animation: fadeShowMobile 1s ease forwards;
}

@keyframes fadeShowMobile {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 100;
  }
}