// .products-main-categories .ant-cascader-picker {
//   display: none;
// }

// .products-main-categories>div:first-child {
//     position: relative!important
// }

.products-main-categories .ant-cascader-picker {
    visibility: hidden;
}

.products-main-categories .products-main-categories-popup {
    box-shadow: none;
    opacity: 1!important;
    pointer-events: all!important;
    width: 100%;
    z-index: 1;
    overflow: visible;
}

.products-main-categories .products-main-categories-popup .ant-cascader-menu {
    background-color: #fff;
    border-right: 0;
    overflow-x: hidden;
    padding-bottom: 0;
    padding-top: 0;
    width: 100%!important
}

.products-main-categories .products-main-categories-popup .ant-cascader-menu:not(:first-child) {
    width: 242px !important;
}

.products-main-categories .products-main-categories-popup .ant-cascader-menu::-webkit-scrollbar {
    background-color: transparent;
    width: 4px
}

.products-main-categories .products-main-categories-popup .ant-cascader-menu::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 10px
}

.products-main-categories .products-main-categories-popup .ant-cascader-menu .ant-empty {
    display: none
}

.products-main-categories .products-main-categories-popup .ant-cascader-menu .ant-cascader-menu-item {
    padding-bottom: 9px;
    padding-top: 9px;
    width: 240px
}

.products-main-categories .ant-cascader-menu {
    height: 482px
}

.products-main-categories:hover {
    overflow: visible
}

.products-main-search-top-categories {
    width: 232px
}

.products-main-search-top-categories .products-main-categories-popup {
    border-radius: 4px;
    box-shadow: 0 4px 12px 0 rgba(0,0,0,.12)
}

.products-main-search-top-categories .products-main-categories-popup .ant-cascader-menu .ant-cascader-menu-item {
    width: 232px
}

.ant-cascader-menu {
  display: inline-block;
  min-width: 111px;
  height: 180px;
  margin: 0;
  padding: 4px 0;
  overflow: auto;
  vertical-align: top;
  list-style: none;
  border-right: 1px solid #f0f0f0;
  -ms-overflow-style: -ms-autohiding-scrollbar
}

.ant-cascader-menu:first-child {
  border-radius: 2px 0 0 2px
}

.ant-cascader-menu:last-child {
  margin-right: -1px;
  border-right-color: transparent;
  border-radius: 0 2px 2px 0
}

.ant-cascader-menus .ant-cascader-menu {
    width: 236px;
}

.ant-cascader-menus .ant-cascader-menu:first-child {
    width: 100%;
}