.language-switcher {
  opacity: 0;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  background-color: #fff;
  display: none;
}

.language-action:hover .language-switcher {
  opacity: 100;
  display: block;
}

.ant-space-item .language-switcher {
  top: 64px !important;
}
