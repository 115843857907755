.saved-products .empty-product-list {
  padding-top: 100px;
  text-align: center
}

.saved-products .empty-product-list .strong {
  font-weight: 700;
  margin: 50px 0 10px
}

.saved-products .empty-product-list div:last-child {
  margin-top: 10px
}

.saved-products .empty-product-list div:last-child button:first-child {
  margin: 10px 10px 140px
}

.saved-products .products-table .ant-table-tbody>tr>td,.saved-products .products-table .ant-table-thead>tr>th {
  border: none;
  vertical-align: middle;
}

.saved-products .products-table .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):before {
  display: none
}

.saved-products .products-table .ant-table-placeholder {
  border: 0
}

.saved-products .products-table .tips {
  color: #fa8c16;
  margin-bottom: 10px
}

.saved-products .products-table .patch {
  margin: 0 0 10px;
  border-radius: 1px
}

.saved-products .products-table .patch .ant-radio-button-wrapper-checked:first-child {
  border-color: #d9d9d9;
  color: rgba(0,0,0,.65);
  cursor: default
}

.saved-products .products-table .create-manual-order-tip {
  background: linear-gradient(270deg,#f3f0ff,#eff9ff);
  border-radius: 18px;
  line-height: 20px;
  padding: 8px 24px 8px 12px
}

.saved-products .products-table .table .ant-table-row.fetching-product {
  position: relative
}

.saved-products .products-table .table .ant-table-row>.ant-table-cell {
  position: static;
}


.saved-products .products-table .table .ant-table-row.fetching-product>.ant-table-cell {
  position: static;
}

.saved-products .products-table .table .ant-table-row.fetching-product .fetching-tips {
  align-items: center;
  background-color: rgba(0,0,0,.25);
  bottom: 0;
  color: #fff;
  display: flex;
  left: 0;
  right: 0;
  top: 0;
  padding: 20px;
  position: absolute
}

.saved-products .products-table .table .ant-table-row.fetching-product:hover>td {
  background-color: transparent
}

.saved-products .products-table .table .ant-table-selection-column {
  padding: 16px 0!important
}

.saved-products .products-table .table .ant-table-column-title {
  font-weight: 700
}

.saved-products .products-table .table .status b {
  border-radius: 50%;
  display: inline-block;
  width: 11px;
  height: 11px;
  margin-right: 8px
}

.saved-products .products-table .table .push {
  cursor: pointer
}

.saved-products .products-table .table .push b {
  background: #0c0
}

.saved-products .products-table .table .no-push span {
  background: #ccc
}

.saved-products .products-table .table .inventory {
  width: 110px;
  padding: 4px 10px;
  border-radius: 15px
}

.saved-products .products-table .table .inventory span {
  margin-right: 8px;
  border-radius: 50%;
  display: inline-block;
  width: 11px;
  height: 11px
}

.saved-products .products-table .table .stock-up {
  background: #f6ffed
}

.saved-products .products-table .table .stock-up span {
  border: 2px solid #52c41a
}

.saved-products .products-table .table .not-stock-up {
  background: #f2f2f2
}

.saved-products .products-table .table .not-stock-up span {
  border: 2px solid #ccc
}

.saved-products .products-table .table .operation a {
  display: block
}

.saved-products .products-table .table .operation .delete-button {
  text-align: left;
  padding: 0;
  line-height: 20px;
  height: 20px
}

.inner-content {
  min-height: 360px;
  max-width: 1440px;
  margin: auto;
  height: calc(100vh - 64px);
  overflow: scroll;
}