.canvas-bg {
  width: 100%;
  height: 100%;
  background-color: #f3f3f3;
}


.ant-input-lg {
  padding: 6.5px 11px;
  font-size: 16px
}

.ant-input-sm {
  padding: 0 7px
}

.ant-input-rtl {
  direction: rtl
}