.search-upload-icon {
  width: 32px;
  height: 32px;
  border: 0;
  position: absolute;
  right: 96px;
  top: 4px;
  z-index: 10;
  background-color: transparent;
  outline: none;
  min-width: 32px !important;
}

.search-upload-icon:hover {
  background-color: transparent !important;
  border-color: transparent !important;
}
