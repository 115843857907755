.goods-img-container {
  display: inline-block;
  width: 100%;
  overflow: hidden
}

.goods-img-container .goods-img-detail {
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: contain;
  height: 0;
  padding-bottom: 100%;
  width: 100%
}

.goods-img-container .goods-img-list {
  width: 100%;
  height: 50px;
  margin-top: 10px
}

.goods-img-container .goods-img-list ul {
  display: flex;
  align-items: center
}

.goods-img-container .goods-img-list ul.gap-10px>li {
  margin-right: 10px
}

.goods-img-container .goods-img-list ul li {
  display: block;
  width: 50px;
  height: 50px;
  overflow: hidden
}

.goods-img-container .goods-img-list ul li .li-item-img {
  height: 100%;
  width: 100%;
  border: 2px solid transparent;
  position: relative
}

.goods-img-container .goods-img-list ul li .li-item-img .ant-image {
  height: 100%;
  width: 100%;
}

.goods-img-container .goods-img-list ul li .li-item-img.li-item-select {
  border-color: red
}

.goods-img-container .goods-img-list ul li .li-item-img img {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  margin: auto;
  max-height: 100%;
  max-width: 100%
}

.product-description .ant-descriptions {
  margin-bottom: 10px
}

.product-description .ant-descriptions-item-label {
  color: #999
}

.product-description .ant-descriptions-item-content {
  color: #333
}

.product-description .ant-descriptions-row>td {
  padding-bottom: 8px
}

.product-description .product-attributeList {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #f5f4f4
}