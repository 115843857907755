.layout-header .user-info {
    display: inline-block;
    color: #fff;
    width: 250px;
    padding: 0;
    position: relative
}

.layout-header .user-info img {
    vertical-align: unset
}

.layout-header .user-info-box {
    width: 100%;
    margin-top: 0;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out
}

.layout-header .user-name {
    line-height: 16px;
    width: 100%;
    margin: 24px 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden
}
