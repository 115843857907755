.page {
  background-color: rgba(225, 229, 232, 1);
  position: relative;
  height: 4270px;
  overflow: hidden;
}

.flex-row {
  display: flex;
}
.flex-col {
  display: flex;
  flex-direction: column;
}

.justify-end {
  justify-content: end;
}

.justify-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}
.section_1 {
  height: 72px;
  background: url(http://184.73.37.21/img/SketchPngec5fc50ffd6f0d8f9d31c84e011fa9e7be1e84d5bb134f6b58c892289fa79d04)
    100% no-repeat;
  background-size: 100% 100%;
  justify-content: flex-center;
}

.logo-wrap{
  background-color: rgba(255, 255, 255, 1);
  width: 171px;
  height: 44px;
  margin: 14px 0 0 0px;
}

.text_1 {
  width: 60px;
  height: 34px;
  overflow-wrap: break-word;
  color: rgba(0, 85, 204, 1);
  font-size: 22px;
  font-family: AlibabaSans-Medium;
  font-weight: 500;
  text-align: justify;
  white-space: nowrap;
  line-height: 34px;
  margin: 5px 0 0 9px;
}

.text-wrapper_1 {
  background-color: rgba(0, 85, 204, 1);
  height: 20px;
  width: 83px;
  margin: 12px 17px 0 2px;
}

.text_2 {
  width: 75px;
  height: 14px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 9px;
  font-family: AlibabaSans-Regular;
  font-weight: normal;
  text-align: justify;
  white-space: nowrap;
  line-height: 14px;
  margin: 3px 0 0 4px;
}

.block_2 {
  width: 211px;
  height: 72px;
  margin-left: 644px;
  cursor: pointer;
}

.block_2:hover .group_1_wrap {
  display: block;
}

.block_23 {
  width: 195px;
  height: 25px;
  margin: 24px 0 0 8px;
}

.label_1 {
  width: 22px;
  height: 22px;
  margin-top: 1px;
}

.text_3 {
  width: 57px;
  height: 25px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  font-family: AlibabaSans-Medium;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  line-height: 25px;
  margin-left: 8px;
}

.text_62 {
  width: 3px;
  height: 25px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  font-family: AlibabaSans-Light;
  font-weight: 300;
  text-align: center;
  white-space: nowrap;
  line-height: 25px;
  margin-left: 8px;
}

.text_63 {
  width: 71px;
  height: 25px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  font-family: AlibabaSans-Medium;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  line-height: 25px;
  margin-left: 8px;
}

.thumbnail_1 {
  width: 12px;
  height: 6px;
  margin: 9px 0 0 6px;
}

.box_2 {
  background-color: rgba(6, 215, 162, 1);
  width: 211px;
  height: 4px;
  margin-top: 19px;
}

.block_3 {
  background-color: rgba(216, 216, 216, 0);
  width: 90px;
  height: 72px;
  margin: 0 0px 0 24px;
  cursor: pointer;
}

.label_2 {
  width: 22px;
  height: 22px;
  margin: 25px 0 0 8px;
}

.text_4 {
  width: 44px;
  height: 25px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  font-family: AlibabaSans-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 25px;
  margin: 24px 8px 0 0;
}

.section_9 {
  min-height: 1008px;
  background: url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/e0625e36a25f4895b1ca8d11ee1eea09_mergeImage.png)
    100% no-repeat;
  background-size: 100% 100%;
}

.group_1_wrap {
  position: absolute;
  left: 0;
  top: 72px;
}

.group_1 {
  width: 526px;
  height: 168px;
  background: url(http://184.73.37.21/img/SketchPngc7d1c0fb506a17e70b44c16ad34d5e533921c51d60ac29ebb7e8476aa408c339)
    100% no-repeat;
  background-size: 100% 100%;
  justify-content: flex-center;
  margin: 8px 0 0 932px;
}

.text_5 {
  width: 333px;
  height: 28px;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 18px;
  font-family: AlibabaSans-Medium;
  font-weight: 500;
  text-align: right;
  white-space: nowrap;
  line-height: 28px;
  margin: 16px 0 0 97px;
}

.group_19 {
  width: 502px;
  height: 92px;
  margin: 16px 0 16px 12px;
}

.section_11 {
  width: 103px;
  height: 92px;
}

.text-wrapper_2 {
  background-color: rgba(216, 216, 216, 0);
  height: 46px;
  width: 103px;
}

.text_6 {
  width: 32px;
  height: 22px;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 16px;
  font-family: AlibabaSans-Regular;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 25px;
  margin: 12px 0 0 20px;
}

.text-wrapper_4 {
  background-color: rgba(216, 216, 216, 0);
  height: 46px;
  width: 103px;
}

.text_8 {
  width: 63px;
  height: 25px;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 16px;
  font-family: AlibabaSans-Regular;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 25px;
  margin: 11px 0 0 20px;
}

.section_12 {
  width: 173px;
  height: 81px;
  margin-top: 11px;
}

.text_7 {
  width: 57px;
  height: 25px;
  overflow-wrap: break-word;
  color: rgba(0, 85, 204, 1);
  font-size: 16px;
  font-family: AlibabaSans-Medium;
  font-weight: 500;
  text-align: justify;
  white-space: nowrap;
  line-height: 25px;
  margin-left: 20px;
}

.text-wrapper_5 {
  height: 46px;
  background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng020ba4d912e947cc2beddf02bacb3f571bcd7fc53151e271ac17496dd857523e)
    100% no-repeat;
  background-size: 100% 100%;
  margin-top: 10px;
  width: 173px;
}

.text_9 {
  width: 133px;
  height: 25px;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 16px;
  font-family: AlibabaSans-Regular;
  font-weight: normal;
  text-align: justify;
  white-space: nowrap;
  line-height: 25px;
  margin: 10px 0 0 20px;
}

.image_4 {
  width: 1px;
  height: 92px;
  margin-left: 12px;
}

.section_13 {
  width: 92px;
  height: 92px;
  margin-left: 11px;
}

.text-wrapper_15 {
  background-color: rgba(216, 216, 216, 0);
  height: 46px;
  width: 92px;
}

.text_64 {
  width: 34px;
  height: 22px;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 16px;
  font-family: PingFangSC-Regular;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 22px;
  margin: 12px 0 0 20px;
}

.text-wrapper_16 {
  background-color: rgba(216, 216, 216, 0);
  height: 46px;
  width: 92px;
}

.text_65 {
  width: 52px;
  height: 22px;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 16px;
  font-family: PingFangSC-Regular;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 22px;
  margin: 12px 0 0 20px;
}

.section_14 {
  width: 110px;
  height: 92px;
}

.text-wrapper_17 {
  height: 46px;
  background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng9eaa446ef38d1ce52ddf3ec0e06b47e0674aaf4c4c0b24cba7229aaaf1825a11)
    100% no-repeat;
  background-size: 100% 100%;
  width: 110px;
}

.text_66 {
  width: 71px;
  height: 25px;
  overflow-wrap: break-word;
  color: rgba(0, 85, 204, 1);
  font-size: 16px;
  font-family: AlibabaSans-Medium;
  font-weight: 500;
  text-align: justify;
  white-space: nowrap;
  line-height: 25px;
  margin: 11px 0 0 20px;
}

.text-wrapper_18 {
  height: 46px;
  background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng61cc6ce2fe042bea6974b07bb3d352cd7ee63ec4597f4993d0cb872242a3f9d0)
    100% no-repeat;
  background-size: 100% 100%;
  width: 110px;
}

.text_67 {
  width: 35px;
  height: 25px;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 16px;
  font-family: AlibabaSans-Regular;
  font-weight: normal;
  text-align: justify;
  white-space: nowrap;
  line-height: 25px;
  margin: 10px 0 0 20px;
}

.text-wrapper_6 {
  width: 753px;
  height: 56px;
  overflow-wrap: break-word;
  font-size: 0;
  font-family: AlibabaSans-Bold;
  font-weight: 700;
  text-align: right;
  white-space: nowrap;
  line-height: 56px;
  margin: 184px 0 0 0;
}

.text_10 {
  width: 753px;
  height: 56px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 36px;
  font-family: AlibabaSans-Bold;
  font-weight: 700;
  text-align: left;
  white-space: nowrap;
  line-height: 56px;
}

.text_11 {
  width: 753px;
  height: 56px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 36px;
  font-family: PingFangSC-Semibold;
  font-weight: 600;
  text-align: left;
  white-space: nowrap;
  line-height: 56px;
}

.group_4 {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 28px;
  width: 660px;
  height: 56px;
  margin: 24px 0 0 0;
}

.text_12 {
  width: 496px;
  height: 100%;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 16px;
  font-family: PingFangSC-Regular;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 22px;
  margin: 0 0 0 28px;
  outline: none;
  border: none;
}

.text_12:focus {
  outline: none;
}

.block_4 {
  background-color: rgba(0, 85, 204, 1);
  border-radius: 28px;
  width: 125px;
  height: 56px;
  margin-left: 15px;
  cursor: pointer;
}

.align-items-center {
  align-items: center;
}

.image-text_15 {
  width: 77px;
  height: 22px;
  margin: 17px 0 0 24px;
}

.thumbnail_2 {
  width: 16px;
  height: 16px;
  margin-top: 3px;
}

.text-group_1 {
  width: 53px;
  height: 22px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  font-family: PingFangSC-Semibold;
  font-weight: 600;
  text-align: right;
  white-space: nowrap;
  line-height: 22px;
}

.box_33 {

  height: 2910px;
}

.block_5 {

  height: 762px;
  background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng5b986d0a768b9f5fad2a155f9d5d882cb6c641a1cfd05e23ad426fbb4ae9ba30)
    100% no-repeat;
  background-size: 100% 100%;
}

.image_1 {
  height: 72px;
}

.group_20 {
  width: 1136px;
  height: 72px;
  margin: 24px 0 0 0;
}

.text_13 {
  width: 551px;
  height: 56px;
  overflow-wrap: break-word;
  color: rgba(0, 85, 204, 1);
  font-size: 36px;
  font-family: AlibabaSans-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 56px;
  margin-top: 8px;
}

.block_6 {
  background-color: rgba(0, 85, 204, 1);
  width: 93px;
  height: 72px;
  margin-left: 290px;
  cursor: pointer;
}

.image-text_16 {
  width: 58px;
  height: 25px;
  margin: 24px 0 0 17px;
}

.label_3 {
  width: 22px;
  height: 22px;
  margin-top: 1px;
}

.text-group_2 {
  width: 28px;
  height: 25px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  font-family: AlibabaSans-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 25px;
}

.block_7 {
  background-color: rgba(216, 216, 216, 0);
  width: 93px;
  height: 72px;
  margin-left: 8px;
  cursor: pointer;
}

.image-text_17 {
  width: 58px;
  height: 25px;
  margin: 24px 0 0 18px;
}

.label_4 {
  width: 22px;
  height: 22px;
  margin-top: 1px;
}

.text-group_3 {
  width: 28px;
  height: 25px;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 16px;
  font-family: AlibabaSans-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 25px;
}

.box_4 {
  background-color: transparent;
  width: 93px;
  height: 4px;
  margin-top: 19px;
}

.block_6:hover .box_4, .block_7:hover .box_4, .block_8:hover .box_4 {
  background-color: rgba(6, 215, 162, 1);
}

.block_8 {
  background-color: rgba(216, 216, 216, 0);
  width: 93px;
  height: 72px;
  margin-left: 8px;
  cursor: pointer;
}

.image-text_18 {
  width: 69px;
  height: 25px;
  margin: 24px 0 0 12px;
}

.label_5 {
  width: 22px;
  height: 22px;
  margin-top: 1px;
}

.text-group_4 {
  width: 39px;
  height: 25px;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 16px;
  font-family: AlibabaSans-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 25px;
}

.group_21 {
  width: 1248px;
  height: 506px;
  margin: 24px 0 64px 0;
}

.label_6 {
  width: 40px;
  height: 40px;
  margin-top: 233px;
  position: absolute;
}

.text_14 {
  width: 122px;
  height: 54px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 34px;
  font-family: AlibabaSans-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 54px;
  margin: 425px 0 0 16px;
}

.group_5 {
  background-color: rgba(255, 255, 255, 1);
  width: 364px;
  height: 506px;
  margin-left: -120px;
}

.block_9 {
  background-image: url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/6001fef4b2de4775a4e5a3cbfaab8477_mergeImage.png);
  width: 364px;
  height: 364px;
}

.block_10 {
  width: 43px;
  height: 37px;
  background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng6dced845a8ee89be3007ac90a7f44e19fb724746dfc0fe9768a19151061af798)
    100% no-repeat;
  background-size: 100% 100%;
  margin-left: 16px;
}

.text-group_22 {
  position: relative;
  width: 22px;
  height: 37px;
}

.text_15 {
  width: 18px;
  height: 16px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 10px;
  font-family: AlibabaSans-Bold;
  font-weight: 700;
  text-align: left;
  white-space: nowrap;
  line-height: 16px;
  margin-left: 2px;
}

.text_16 {
  position: absolute;
  left: 0;
  top: 9px;
  width: 22px;
  height: 28px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 18px;
  font-family: AlibabaSans-Bold;
  font-weight: 700;
  text-align: left;
  white-space: nowrap;
  line-height: 28px;
}

.text_17 {
  width: 332px;
  height: 36px;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 12px;
  font-family: AlibabaSans-Regular;
  font-weight: normal;
  text-align: left;
  line-height: 18px;
  margin: 9px 0 0 15px;
}

.group_22 {
  width: 331px;
  height: 28px;
  margin: 12px 0 20px 16px;
}

.text_18 {
  width: 37px;
  height: 28px;
  overflow-wrap: break-word;
  color: rgba(0, 85, 204, 1);
  font-size: 18px;
  font-family: AlibabaSans-Regular;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 28px;
}

.text_19 {
  width: 128px;
  height: 18px;
  overflow-wrap: break-word;
  color: rgba(153, 153, 153, 1);
  font-size: 12px;
  font-family: AlibabaSans-Regular;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  line-height: 18px;
  margin: 5px 0 0 109px;
}

.thumbnail_3 {
  width: 1px;
  height: 8px;
  margin: 10px 0 0 4px;
}

.text_20 {
  width: 45px;
  height: 18px;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 12px;
  font-family: AlibabaSans-Regular;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 18px;
  margin: 5px 0 0 7px;
}

.text_21 {
  width: 122px;
  height: 54px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 34px;
  font-family: AlibabaSans-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 54px;
  margin: 425px 0 0 19px;
}

.group_6 {
  background-color: rgba(255, 255, 255, 1);
  width: 364px;
  height: 506px;
  margin-left: -120px;
}

.box_6 {
  background-image: url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/dd03865bba7e459084e490f993879f11_mergeImage.png);
  width: 364px;
  height: 364px;
}

.box_7 {
  width: 43px;
  height: 37px;
  background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng6dced845a8ee89be3007ac90a7f44e19fb724746dfc0fe9768a19151061af798)
    100% no-repeat;
  background-size: 100% 100%;
  margin-left: 16px;
}

.text-group_23 {
  width: 22px;
  height: 37px;
}

.text_22 {
  width: 18px;
  height: 16px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 10px;
  font-family: AlibabaSans-Bold;
  font-weight: 700;
  text-align: left;
  white-space: nowrap;
  line-height: 16px;
  margin-left: 2px;
}

.text_23 {
  width: 22px;
  height: 28px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 18px;
  font-family: AlibabaSans-Bold;
  font-weight: 700;
  text-align: left;
  white-space: nowrap;
  line-height: 28px;
  margin-top: -7px;
}

.text_24 {
  width: 332px;
  height: 36px;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 12px;
  font-family: AlibabaSans-Regular;
  font-weight: normal;
  text-align: left;
  line-height: 18px;
  margin: 9px 0 0 15px;
}

.block_24 {
  width: 331px;
  height: 28px;
  margin: 12px 0 20px 16px;
}

.text_25 {
  width: 37px;
  height: 28px;
  overflow-wrap: break-word;
  color: rgba(0, 85, 204, 1);
  font-size: 18px;
  font-family: AlibabaSans-Regular;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 28px;
}

.text_26 {
  width: 128px;
  height: 18px;
  overflow-wrap: break-word;
  color: rgba(153, 153, 153, 1);
  font-size: 12px;
  font-family: AlibabaSans-Regular;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  line-height: 18px;
  margin: 5px 0 0 109px;
}

.thumbnail_4 {
  width: 1px;
  height: 8px;
  margin: 10px 0 0 4px;
}

.text_27 {
  width: 45px;
  height: 18px;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 12px;
  font-family: AlibabaSans-Regular;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 18px;
  margin: 5px 0 0 7px;
}

.text_28 {
  width: 122px;
  height: 54px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 34px;
  font-family: AlibabaSans-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 54px;
  margin: 425px 0 0 19px;
}

.group_7 {
  background-color: rgba(255, 255, 255, 1);
  width: 364px;
  height: 506px;
  margin-left: -120px;
}

.block_12 {
  background-image: url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/e1cba64079984e1a80a818d450c3e469_mergeImage.png);
  width: 364px;
  height: 364px;
}

.block_13 {
  width: 43px;
  height: 37px;
  background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng6dced845a8ee89be3007ac90a7f44e19fb724746dfc0fe9768a19151061af798)
    100% no-repeat;
  background-size: 100% 100%;
  margin-left: 16px;
}

.text-group_24 {
  position: relative;
  width: 22px;
  height: 37px;
}

.text_29 {
  width: 22px;
  height: 28px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 18px;
  font-family: AlibabaSans-Bold;
  font-weight: 700;
  text-align: left;
  white-space: nowrap;
  line-height: 28px;
  margin-top: 9px;
}

.text_30 {
  position: absolute;
  left: 2px;
  top: 0;
  width: 18px;
  height: 16px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 10px;
  font-family: AlibabaSans-Bold;
  font-weight: 700;
  text-align: left;
  white-space: nowrap;
  line-height: 16px;
}

.text_31 {
  width: 332px;
  height: 36px;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 12px;
  font-family: AlibabaSans-Regular;
  font-weight: normal;
  text-align: left;
  line-height: 18px;
  margin: 9px 0 0 15px;
}

.box_34 {
  width: 331px;
  height: 28px;
  margin: 12px 0 20px 16px;
}

.text_32 {
  width: 37px;
  height: 28px;
  overflow-wrap: break-word;
  color: rgba(0, 85, 204, 1);
  font-size: 18px;
  font-family: AlibabaSans-Regular;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 28px;
}

.text_33 {
  width: 128px;
  height: 18px;
  overflow-wrap: break-word;
  color: rgba(153, 153, 153, 1);
  font-size: 12px;
  font-family: AlibabaSans-Regular;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  line-height: 18px;
  margin: 5px 0 0 109px;
}

.thumbnail_5 {
  width: 1px;
  height: 8px;
  margin: 10px 0 0 4px;
}

.text_34 {
  width: 45px;
  height: 18px;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 12px;
  font-family: AlibabaSans-Regular;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 18px;
  margin: 5px 0 0 7px;
}

.label_7 {
  width: 40px;
  height: 40px;
  margin: 233px 0 0 16px;
}

.block_15 {

  height: 752px;
  background: url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/994c0f3b2c804cdaa85f61693b49283b_mergeImage.png)
    100% no-repeat;
  background-size: 100% 100%;
}

.text_35 {
  width: 490px;
  height: 56px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 36px;
  font-family: AlibabaSans-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 56px;
  margin: 64px 0 0 0;
}

.list_4 {
  width: 1136px;
  height: 284px;
  justify-content: space-between;
  margin: 60px 0 0 0;
}

.list-items_4-0 {
  width: 490px;
  height: 284px;
  margin-right: 156px;
}

.text-wrapper_7-0 {
  background-color: rgba(216, 216, 216, 1);
  height: 229px;
  width: 490px;
}

.text_36-0 {
  width: 29px;
  height: 28px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 20px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: right;
  white-space: nowrap;
  line-height: 28px;
  margin: 101px 0 0 0;
}

.text-group_25-0 {
  width: 490px;
  height: 247px;
  margin: 24px 0 216px 0;
}

.text_37-0 {
  width: 323px;
  height: 31px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 20px;
  font-family: AlibabaSans-Medium;
  font-weight: 500;
  text-align: right;
  white-space: nowrap;
  line-height: 31px;
  margin-left: 84px;
}

.text_38-0 {
  width: 490px;
  height: 200px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  font-family: AlibabaSans-Regular;
  font-weight: normal;
  text-align: left;
  line-height: 25px;
  margin-top: 16px;
}

.list-items_4-1 {
  width: 490px;
  height: 284px;
  margin-right: 156px;
}

.text-wrapper_7-1 {
  background-color: rgba(216, 216, 216, 1);
  height: 229px;
  width: 490px;
}

.text_36-1 {
  width: 29px;
  height: 28px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 20px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: right;
  white-space: nowrap;
  line-height: 28px;
  margin: 101px 0 0 0;
}

.text-group_25-1 {
  width: 490px;
  height: 247px;
  margin: 24px 0 216px 0;
}

.text_37-1 {
  width: 323px;
  height: 31px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 20px;
  font-family: AlibabaSans-Medium;
  font-weight: 500;
  text-align: right;
  white-space: nowrap;
  line-height: 31px;
  margin-left: 84px;
}

.text_38-1 {
  width: 490px;
  height: 200px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  font-family: AlibabaSans-Regular;
  font-weight: normal;
  text-align: left;
  line-height: 25px;
  margin-top: 16px;
}

.text-wrapper_21 {
  width: 1136px;
  height: 200px;
  margin: 16px 0 72px 0;
}

.text_38 {
  width: 490px;
  height: 200px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  font-family: AlibabaSans-Regular;
  font-weight: normal;
  text-align: left;
  line-height: 25px;
}

.text_39 {
  width: 490px;
  height: 125px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  font-family: AlibabaSans-Regular;
  font-weight: normal;
  text-align: left;
  line-height: 25px;
}

.block_16 {
  background-color: rgba(255, 255, 255, 1);

  height: 599px;
}

.image_2 {
  width: 351px;
  height: 351px;
  margin: 124px 0 0 0;
}

.section_4 {
  background-color: rgba(216, 216, 216, 0);
  width: 769px;
  height: 399px;
  margin: 100px 392px 0 0;
}

.text_40 {
  width: 578px;
  height: 112px;
  overflow-wrap: break-word;
  color: rgba(0, 76, 187, 1);
  font-size: 36px;
  font-family: AlibabaSans-Medium;
  font-weight: 500;
  text-align: left;
  line-height: 56px;
  margin-left: 81px;
}

.text_41 {
  width: 608px;
  height: 175px;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 16px;
  font-family: AlibabaSans-Regular;
  font-weight: normal;
  text-align: left;
  line-height: 25px;
  margin: 24px 0 0 81px;
}

.group_23 {
  width: 415px;
  height: 31px;
  margin: 21px 0 36px 81px;
}

.image-text_19 {
  // width: 196px; 
  height: 31px;
}

.label_8 {
  width: 28px;
  height: 28px;
  margin-top: 2px;
}

.text-group_15 {
  width: 160px;
  height: 31px;
  overflow-wrap: break-word;
  color: rgba(0, 76, 187, 1);
  font-size: 20px;
  font-family: AlibabaSans-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 31px;
}

.text_68 {
  width: 211px;
  height: 31px;
  overflow-wrap: break-word;
  color: rgba(0, 76, 187, 1);
  font-size: 20px;
  font-family: AlibabaSans-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 31px;
}

.block_17 {
  background-color: rgba(225, 229, 232, 1);
  position: relative;

  height: 796px;
  margin-bottom: 1px;
}

.text_43 {
  width: 260px;
  height: 56px;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 36px;
  font-family: AlibabaSans-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 56px;
  margin: 64px 0 0 0;
}

.box_10 {
  background-color: rgba(255, 255, 255, 1);
  width: 558px;
  height: 588px;
  margin: 24px 0 0 0;
}

.box_11 {
  background-color: rgba(137, 137, 137, 1);
  width: 558px;
  height: 310px;
}

.group_24 {
  width: 222px;
  height: 54px;
  margin: 24px 0 0 17px;
}

.text-wrapper_11 {
  background-color: rgba(216, 216, 216, 1);
  border-radius: 50%;
  height: 54px;
  width: 54px;
}

.text_45 {
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  font-family: AlibabaSans-Medium;
  font-weight: 500;
  text-align: right;
  white-space: nowrap;
  line-height: 22px;
}

.text_46 {
  width: 155px;
  height: 31px;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 20px;
  font-family: AlibabaSans-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 31px;
  margin-top: 10px;
}

.text_47 {
  width: 525px;
  height: 100px;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 16px;
  font-family: AlibabaSans-Regular;
  font-weight: normal;
  text-align: left;
  line-height: 25px;
  margin: 16px 0 84px 16px;
}

.image_5 {
  width: 1248px;
  height: 40px;
  margin: -314px 0 338px 336px;
}

.box_13 {
  background-color: rgba(255, 255, 255, 1);
  width: 558px;
  height: 588px;
  margin: 24px 0 0 10px;
}

.group_8 {
  background-color: rgba(137, 137, 137, 1);
  width: 558px;
  height: 310px;
}

.group_25 {
  width: 222px;
  height: 54px;
  margin: 24px 0 0 17px;
}

.text-wrapper_12 {
  background-color: rgba(216, 216, 216, 1);
  border-radius: 50%;
  height: 54px;
  width: 54px;
}

.text_48 {
  width: 31px;
  height: 22px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  font-family: AlibabaSans-Medium;
  font-weight: 500;
  text-align: right;
  white-space: nowrap;
  line-height: 22px;
  margin: 16px 0 0 12px;
}

.text_49 {
  width: 155px;
  height: 31px;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 20px;
  font-family: AlibabaSans-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 31px;
  margin-top: 10px;
}

.text_50 {
  width: 525px;
  height: 125px;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 16px;
  font-family: AlibabaSans-Regular;
  font-weight: normal;
  text-align: left;
  line-height: 25px;
  margin: 16px 0 59px 16px;
}

.section_5 {
  background-color: rgba(51, 51, 51, 1);

  height: 281px;
  margin-top: -1px;
}

.text-wrapper_13 {
  background-color: rgba(51, 51, 51, 1);
  width: 380px;
  height: 281px;
}

.text_51 {
  width: 124px;
  height: 25px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  font-family: AlibabaSans-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 25px;
  margin: 48px 0 0 24px;
}

.text_52 {
  width: 34px;
  height: 18px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 12px;
  font-family: AlibabaSans-Regular;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 18px;
  margin: 16px 0 0 24px;
}

.text_53 {
  width: 39px;
  height: 18px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 12px;
  font-family: AlibabaSans-Regular;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 18px;
  margin: 8px 0 0 24px;
}

.text_54 {
  width: 70px;
  height: 18px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 12px;
  font-family: AlibabaSans-Regular;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 18px;
  margin: 8px 0 0 24px;
}

.text_55 {
  width: 60px;
  height: 18px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 12px;
  font-family: AlibabaSans-Regular;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 18px;
  margin: 8px 0 0 24px;
}

.text_56 {
  width: 61px;
  height: 18px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 12px;
  font-family: AlibabaSans-Regular;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 18px;
  margin: 8px 0 70px 24px;
}

.box_14 {
  background-color: rgba(51, 51, 51, 1);
  width: 380px;
  height: 281px;
  margin-left: 260px;
}

.text-group_26 {
  width: 51px;
  height: 59px;
  margin: 48px 0 0 24px;
}

.text_57 {
  width: 40px;
  height: 25px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  font-family: AlibabaSans-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 25px;
}

.text_58 {
  width: 51px;
  height: 18px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 12px;
  font-family: AlibabaSans-Regular;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 18px;
  margin-top: 16px;
}

.image_3 {
  width: 331px;
  height: 1px;
  margin: 24px 0 0 24px;
}

.text_59 {
  width: 74px;
  height: 25px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  font-family: AlibabaSans-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 25px;
  margin: 24px 0 0 24px;
}

.section_15 {
  width: 76px;
  height: 20px;
  margin: 16px 0 64px 24px;
}

.thumbnail_6 {
  width: 20px;
  height: 20px;
}

.thumbnail_7 {
  width: 20px;
  height: 20px;
  margin-left: 8px;
}

.box_15 {
  background-color: rgba(0, 0, 0, 0);
  height: 20px;
  margin-left: 8px;
  width: 20px;
}

.group_11 {
  border-radius: 2px;
  background-image: url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/98ddc4d957364035b3d65a2518050cfe_mergeImage.png);
  width: 16px;
  height: 16px;
  margin: 2px 0 0 2px;
}

.box_16 {
  background-color: rgba(51, 51, 51, 1);
  width: 380px;
  height: 281px;
  margin-right: 390px;
}

.text-wrapper_14 {
  background-color: rgba(73, 73, 73, 1);
  height: 36px;
  width: 107px;
  margin: 48px 0 0 249px;
}

.text_60 {
  width: 33px;
  height: 22px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 22px;
  margin: 6px 0 0 36px;
}

.text_61 {
  width: 302px;
  height: 18px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 12px;
  font-family: AlibabaSans-Regular;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 18px;
  margin: 109px 0 70px 54px;
}



.s_box_4 {
  background-image: url(https://lanhu-dds-backend.s_oss-cn-beijing.s_aliyuncs.s_com/merge_image/imgs/0dff0ecbe6d34b44b216d85b9d29a1e3_mergeImage.s_png);
  width: 220px;
  height: 392px;
  margin-left: -73px;
  cursor: pointer;
  background-color: #fff;
  margin-right: 10px;
  margin-left: 0;
  margin-bottom: 8px;
}
.s_image-text_1 {
  width: 220px;
  height: 272px;
}
.s_group_6 {
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 12px;
  font-family: AlibabaSans-Regular;
  font-weight: normal;
  text-align: left;
  line-height: 18px;
  background-image: url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/76fc3c44cd514810aed5ceb5fb12ffd0_mergeImage.png); 
  width: 220px;
  height: 220px;
  background-size: cover;
}
.s_text-group_1 {
  width: 184px;
  height: 36px;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 12px;
  font-family: AlibabaSans-Regular;
  font-weight: normal;
  text-align: left;
  line-height: 18px;
  margin: 16px 0 0 18px;
  overflow:hidden; 
  text-overflow:ellipsis;
  display:-webkit-box; 
  -webkit-box-orient:vertical;
  -webkit-line-clamp:2; 
}
.s_text-wrapper_3 {
  height: 18px;
  border: 0.8px solid rgba(244, 103, 0, 1);
  width: 76px;
  margin: 8px 0 0 16px;
}
.s_text_10 {
  width: 60px;
  height: 16px;
  overflow-wrap: break-word;
  color: rgba(244, 103, 0, 1);
  font-size: 10px;
  font-family: AlibabaSans-Regular;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 16px;
  margin: 1px 0 0 8px;
}
.s_block_2 {
  width: 186px;
  height: 28px;
  margin: 7px 0 0 18px;
}
.s_text_11 {
  width: 37px;
  height: 28px;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 18px;
  font-family: AlibabaSans-Regular;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 28px;
}
.s_text-wrapper_4 {
  width: 39px;
  height: 18px;
  overflow-wrap: break-word;
  font-size: 0;
  font-family: AlibabaSans-Regular;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 18px;
  margin: 5px 0 0 50px;
}
.s_text_12 {
  width: 39px;
  height: 18px;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 12px;
  font-family: AlibabaSans-Regular;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 18px;
}
.s_text_13 {
  width: 39px;
  height: 18px;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 12px;
  font-family: PingFangSC-Regular;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 18px;
}
.s_text_14 {
  width: 39px;
  height: 18px;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 12px;
  font-family: AlibabaSans-Regular;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 18px;
}
.s_thumbnail_3 {
  width: 1px;
  height: 8px;
  margin: 10px 0 0 7px;
}
.s_text-wrapper_5 {
  width: 44px;
  height: 18px;
  overflow-wrap: break-word;
  font-size: 0;
  font-family: AlibabaSans-Regular;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 18px;
  margin: 5px 0 0 8px;
}
.s_text_15 {
  width: 44px;
  height: 18px;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 12px;
  font-family: AlibabaSans-Regular;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 18px;
}
.s_text_16 {
  width: 44px;
  height: 18px;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 12px;
  font-family: PingFangSC-Regular;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 18px;
}
.s_text_17 {
  width: 44px;
  height: 18px;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 12px;
  font-family: AlibabaSans-Regular;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 18px;
}
.s_text_18 {
  width: 128px;
  height: 18px;
  overflow-wrap: break-word;
  color: rgba(153, 153, 153, 1);
  font-size: 12px;
  font-family: AlibabaSans-Regular;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 18px;
  margin: 1px 0 40px 18px;
}

.s_icon-wrapper {
  display: flex;
}

.s_icon {
  display: block;
  width: 16px;
  height: 16px;
  margin-left: 18px;
}

.s_section_5 {
  background: url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/73df1d58a42a408d8676c089b84ecf10_mergeImage.png)
  100% no-repeat;
  background-size: 100% 100%;
  min-height: calc(100vh);
}


.list_container {
  display: flex;
  width: 1150px;
  margin: 24px 388px 0px;
  flex-wrap: wrap;
  flex-grow: 0;
  flex-shrink: 0;
}

.s_group_4 {
  margin-bottom: 24px;
}

.page {
  height: auto;
}

.react-tabs__tab:focus {
  outline: none; /* 移除默认轮廓 */
}

.react-tabs__tab--selected {
  outline: none;
}

.react-tabs__tab--selected > div {
  background-color: #0055CC;
}

.react-tabs__tab--selected > div span {
  @apply text-white;
}

.floatup-effect {
  animation: floatUp 1s ease forwards;
}

@keyframes floatUp {
  0% {
      transform: translateY(0);
  }
  100% {
      transform: translateY(-100px);
  }
}

.product-banner {
  transform: scale(1.1);
  transform-origin: center center;
  opacity: 0; /* 初始状态设置为隐藏 */
  transition: opacity 0.5s ease, transform 0.5s ease; /* 使用过渡效果 */
}

.index-search-upload-icon {
  top: 12px !important;
  right: 124px !important;
}