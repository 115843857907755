@tailwind base;
@tailwind components;
@tailwind utilities;

@import './style/common.less';

/* global.css */
@font-face {
  font-family: 'AlibabaSans';
  src: url('./asset/fonts/AlibabaSans-Regular.otf') format('otf'),
    url('./asset/fonts/AlibabaSans-Regular.ttf') format('ttf');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  /* 可选，用于改进字体加载行为 */
}

@font-face {
  font-family: 'AlibabaSans';
  src: url('./asset/fonts/AlibabaSans-Medium.otf') format('otf'),
    url('./asset/fonts/AlibabaSans-Medium.ttf') format('ttf');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AlibabaSans';
  src: url('./asset/fonts/AlibabaSans-Bold.otf') format('otf'),
    url('./asset/fonts/AlibabaSans-Bold.ttf') format('ttf');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

/*!
 * 
 * antd v3.17.0
 * 
 * Copyright 2015-present, Alipay, Inc.
 * All rights reserved.
 *       
 */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-no-unknown */
html,
body {
  width: 100%;
  height: 100%;

  &::-webkit-scrollbar {
    display: none;
    /* Chrome Safari */
  }
}

input::-ms-clear,
input::-ms-reveal {
  display: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@-ms-viewport {
  width: device-width;
}

article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  margin: 0;
  color: #333;
  /* color: rgba(0, 0, 0, 0.65); */
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  font-variant: tabular-nums;
  line-height: 1.5;
  background-color: #fff;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', "tnum";
}

[tabindex='-1']:focus {
  outline: none !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
}

p {
  margin-top: 0;
  margin-bottom: 1em;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  border-bottom: 0;
  cursor: help;
}

address {
  margin-bottom: 1em;
  font-style: normal;
  line-height: inherit;
}

input[type='text'],
input[type='password'],
input[type='number'],
textarea {
  -webkit-appearance: none;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1em;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 500;
}

dd {
  margin-bottom: 0.5em;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1em;
}

dfn {
  font-style: italic;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #1890ff;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  -webkit-text-decoration-skip: objects;
}

a:hover {
  color: #40a9ff;
}

a:active {
  color: #096dd9;
}

a:active,
a:hover {
  text-decoration: none;
  outline: 0;
}

a[disabled] {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
  pointer-events: none;
}

pre,
code,
kbd,
samp {
  font-size: 1em;
  font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
}

pre {
  margin-top: 0;
  margin-bottom: 1em;
  overflow: auto;
}

figure {
  margin: 0 0 1em;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

a,
area,
button,
[role='button'],
input:not([type='range']),
label,
select,
summary,
textarea {
  touch-action: manipulation;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75em;
  padding-bottom: 0.3em;
  color: rgba(0, 0, 0, 0.45);
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type='radio'],
input[type='checkbox'] {
  box-sizing: border-box;
  padding: 0;
}

input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  margin: 0;
  padding: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  margin-bottom: 0.5em;
  padding: 0;
  color: inherit;
  font-size: 1.5em;
  line-height: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

[type='search'] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

mark {
  padding: 0.2em;
  background-color: #feffe6;
}

::-moz-selection {
  color: #fff;
  background: #1890ff;
}

::selection {
  color: #fff;
  background: #1890ff;
}

.clearfix {
  zoom: 1;
}

.clearfix::before,
.clearfix::after {
  display: table;
  content: '';
}

.clearfix::after {
  clear: both;
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, "SF UI Text", "Helvetica Neue", Arial, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "WenQuanYi Zen Hei", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  margin: 0;
}

ol,
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.container {
  width: 1140px;
  margin: 0 auto;
}

.products-main-categories .products-main-categories-popup {
  box-shadow: none;
  opacity: 1 !important;
  pointer-events: all !important;
  width: 100%;
  z-index: 1;
}

.products-main-categories {
    height: 482px;
    max-height: 482px;
    overflow: hidden;
    position: relative;
    width: 240px;
    z-index: 2
}

/* .products-main-categories>div:first-child {
    position: relative!important
}

.products-main-categories .ant-cascader-picker {
    display: none
}

.products-main-categories .products-main-categories-popup {
    box-shadow: none;
    opacity: 1!important;
    pointer-events: all!important;
    width: 100%;
    z-index: 1
}

.products-main-categories .products-main-categories-popup .ant-cascader-menu {
    background-color: #fff;
    border-right: 0;
    overflow-x: hidden;
    padding-bottom: 0;
    padding-top: 0;
    width: 100%!important
}

.products-main-categories .products-main-categories-popup .ant-cascader-menu::-webkit-scrollbar {
    background-color: transparent;
    width: 4px
}

.products-main-categories .products-main-categories-popup .ant-cascader-menu::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 10px
}

.products-main-categories .products-main-categories-popup .ant-cascader-menu .ant-empty {
    display: none
}

.products-main-categories .products-main-categories-popup .ant-cascader-menu .ant-cascader-menu-item {
    padding-bottom: 9px;
    padding-top: 9px;
    width: 240px
}

.products-main-categories .ant-cascader-menu {
    height: 482px
}

.products-main-categories:hover {
    overflow: visible
}

.products-main-search-top-categories {
    width: 232px
}

.products-main-search-top-categories .products-main-categories-popup {
    border-radius: 4px;
    box-shadow: 0 4px 12px 0 rgba(0,0,0,.12)
}

.products-main-search-top-categories .products-main-categories-popup .ant-cascader-menu .ant-cascader-menu-item {
    width: 232px
} */


.products-main-flows {
  background-color: #fff;
  border-radius: 4px;
  color: #333;
  margin-top: 20px;
  overflow: hidden;
  width: 680px
}

.products-main-flow {
  align-items: center;
  display: flex;
  position: relative
}

.products-main-flow:nth-child(2n) {
  background-color: #f9f9fb
}

.products-main-flow.active .products-main-flow-step:not(.active,.expand),.products-main-flow.expand .products-main-flow-step:not(.active,.expand) {
  flex-shrink: 1;
  width: 16.66667%
}

.products-main-flow.active .products-main-flow-step-indicator .anticon-arrow-right,.products-main-flow.expand .products-main-flow-step-indicator .anticon-arrow-right {
  display: none
}

.products-main-flow.active .products-main-flow-step-title,.products-main-flow.expand .products-main-flow-step-title {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden
}

.products-main-flow.active:before {
  background-color: #40a9ff
}

.products-main-flow.active .products-main-flow-step-indicator,.products-main-flow.active .products-main-flow-step-indicator-icon,.products-main-flow.active .products-main-flow-step-title,.products-main-flow.active .products-main-flow-title {
  color: #fff
}

.products-main-flow:before {
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0
}

.products-main-flow-title {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  font-weight: 500;
  height: 115px;
  padding: 16px;
  position: relative;
  width: 20%
}

.products-main-flow-steps {
  align-items: center;
  display: flex;
  width: 80%
}

.products-main-flow-step {
  align-items: flex-start;
  cursor: pointer;
  flex-direction: column;
  height: 115px;
  padding: 22px 10px;
  position: relative;
  transition: all .2s;
  width: 25%
}

.products-main-flow-step.active,.products-main-flow-step.expand {
  flex-grow: 1;
  width: 50%
}

.products-main-flow-step.active .products-main-flow-step-indicator-icon {
  visibility: hidden
}

.products-main-flow-step.active .products-main-flow-step-details {
  opacity: 1
}

.products-main-flow-step.active .products-main-flow-step-details-background {
  opacity: 1;
  transform: scaleX(1) scaleY(2)
}

.products-main-flow-step.active .products-main-flow-step-details-content {
  height: 200%;
  opacity: 1;
  overflow: hidden;
  width: 100%
}

.products-main-flow-step-indicator {
  align-items: center;
  display: flex
}

.products-main-flow-step-indicator .anticon:not(.anticon-arrow-right) {
  margin-right: 5px
}

.products-main-flow-step-indicator-icon {
  color: #40a9ff;
  font-size: 20px;
  height: 20px;
  width: 20px
}

.products-main-flow-step-title {
  font-weight: 500;
  height: 36px;
  line-height: 18px
}

.products-main-flow-step-details {
  color: #fff;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  transform-origin: top left;
  top: 0;
  width: 100%;
  z-index: 1
}

.products-main-flow-step-details-background {
  background-color: #40a9ff;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  transition: all .2s ease;
  top: 0;
  transform: scale(0);
  width: 100%
}

.products-main-flow-step-details-step-indicator {
  color: #62b8ff;
  font-size: 32px;
  font-weight: 700;
  line-height: 42px;
  position: absolute;
  right: 20px;
  top: 12px
}

.products-main-flow-step-details-content {
  height: 100%;
  opacity: 0;
  overflow: auto;
  padding: 20px 12px;
  position: absolute;
  transition: opacity .2s ease .2s;
  width: 100%;
  z-index: 1
}

.products-main-flow-step-indicator {
  color: #999
}

.products-main-flow-step-indicator .anticon-arrow-right {
  color: #bbb
}

.products-main-flow-step-details-title {
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 10px;
  position: relative
}

.products-main-flow-step-details-description {
  color: #fff;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 10px
}

.products-main-flow-step-details-highlights {
  font-size: 14px;
  font-weight: 700;
  margin-left: 16px
}

.products-main-flow-step-details-highlights>li {
  list-style-type: disc
}