.iIzGMbY1 .ant-descriptions-row>td,.iIzGMbY1 .ant-descriptions-row>th {
  padding-bottom: 8px
}

.iIzGMbY1 .ant-descriptions-item-label {
  display: block!important;
  flex-shrink: 0;
  min-width: 70px
}

.tmgvEj5X {
  font-size: 14px;
  width: 100%;
  font-weight: 400;
  line-height: 32px
}

.tmgvEj5X .ant-descriptions-row>td,.tmgvEj5X .ant-descriptions-row>th {
  padding-bottom: 0
}

.ant-descriptions-view {
  border-radius: 0 !important;
}

.inner-content {
  background-color: #f5f5f5;
}