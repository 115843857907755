@import './animation.less';
@import './antd.less';
@import './component.less';

.settings-exchange-rates-fields.is-collapsed {
    max-height: 336px;
    overflow: hidden
}

div.check_exchange {
    margin-bottom: 0
}

body,html,main {
    scroll-behavior: smooth
}

li,ul {
    list-style: none
}

* {
    word-break: break-word
}

p {
    margin-bottom: 0
}

ul {
    -webkit-padding-start: 0;
    padding-left: 0
}

img {
    max-height: 100%;
    max-width: 100%
}

.truncate {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden
}

.row-top {
    margin-top: 16px
}

.page-title {
    line-height: 50px;
    display: inline-block
}

.vertical-center-modal {
    text-align: center
}

.vertical-center-modal:before {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    width: 0
}

.vertical-center-modal .ant-modal {
    display: inline-block;
    vertical-align: middle;
    top: 8px;
    padding-bottom: 8px;
    text-align: left
}

.reset-scrollbar::-webkit-scrollbar {
    width: 6px
}

.reset-scrollbar::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(20,20,20,.3);
    -webkit-box-shadow: inset 0 0 6px rgba(20,20,20,.3);
    border-radius: 10px
}

.reset-scrollbar::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(20,20,20,.1);
    box-shadow: inset 0 0 6px rgba(20,20,20,.5);
    -webkit-box-shadow: inset 0 0 6px rgba(20,20,20,.5)
}

.table-no-border .ant-table-tbody>tr>td,.table-no-border .ant-table-thead>tr>th {
    border: none
}

.table-no-border .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):before {
    display: none
}

.table-no-border .ant-table-placeholder {
    border: 0
}

.table-content-centered .ant-table-row td {
    vertical-align: middle
}

.table-centered .ant-table-tbody>tr>td,.table-centered .ant-table-thead>tr>th {
    text-align: center
}

.table-footer-transparent .ant-table {
    background-color: #fff
}

.ant-table-expand-icon-col,.ant-table-selection-col {
    width: 30px
}

.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar
}

.table-grid-actions {
    display: flex;
    flex-direction: column
}

.table-grid-actions>a {
    display: block
}

.image-thumbnails {
    display: flex
}

.image-thumbnail {
    margin-bottom: 16px;
    margin-right: 16px
}

.image-thumbnail>img {
    display: block;
    height: 88px;
    width: 88px
}

.ant-form-item.hide-required-mark .ant-form-item-label label.ant-form-item-required:before,.ant-form-item.hide-required-mark .ant-legacy-form-item-label label.ant-legacy-form-item-required:before,.ant-legacy-form-item.hide-required-mark .ant-form-item-label label.ant-form-item-required:before,.ant-legacy-form-item.hide-required-mark .ant-legacy-form-item-label label.ant-legacy-form-item-required:before {
    display: none
}

.ant-form-item.show-required-mark .ant-form-item-label>label:before,.ant-form-item.show-required-mark .ant-legacy-form-item-label>label:before,.ant-legacy-form-item.show-required-mark .ant-form-item-label>label:before,.ant-legacy-form-item.show-required-mark .ant-legacy-form-item-label>label:before {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun,sans-serif;
    line-height: 1;
    content: "*"
}

.ant-form-item.read-only .ant-input,.ant-form-item.read-only .ant-input:focus,.ant-form-item.read-only .ant-input:hover {
    background-color: #f5f5f5;
    border-color: transparent;
    box-shadow: none
}

label.required:before {
    display: inline-block;
    margin-right: 4px;
    color: #f5222d;
    font-size: 14px;
    line-height: 1;
    content: "*"
}

.ant-select-loading .ant-select-selection-item {
    color: transparent
}

.ant-radio-group.vertical-radio-group>label {
    display: block;
    margin-bottom: 8px
}

.ant-tabs.paged-tabs.ant-tabs-top>.ant-tabs-nav {
    margin-bottom: 0
}

.ant-tabs.paged-tabs.ant-tabs-top>.ant-tabs-nav:before {
    border: none
}

.ant-tabs.paged-tabs.ant-tabs-top .ant-tabs-tab {
    background: #f9fafb;
    border-radius: 10px 10px 0 0;
    margin: 0;
    padding: 16px 40px
}

.ant-tabs.paged-tabs.ant-tabs-top .ant-tabs-tab.ant-tabs-tab-active {
    background-color: #fff
}

.ant-tabs.paged-tabs.ant-tabs-top .ant-tabs-ink-bar {
    display: none
}

.ant-tabs.paged-tabs .ant-tabs-content {
    background-color: #fff;
    border-radius: 0 10px 10px 10px
}

a.patched-ant-btn {
    padding-top: 4px!important
}

.ant-btn.min-w-0 {
    min-width: 0
}

.ant-legacy-form-item-control.has-error .ant-select-selector:not([disabled]),.ant-legacy-form-item-control.has-error .ant-select-selector:not([disabled]):hover {
    border-color: #ff4d4f
}

.page-layout-nav {
    align-items: center;
    display: flex;
    font-size: 16px
}

.page-layout-nav .nav-item:not(:first-child) {
    margin-left: 16px
}

.page-layout-nav .nav-item a {
    border-bottom: 2px solid transparent;
    color: rgba(0,0,0,.85);
    display: block;
    line-height: 18px;
    padding: 8px 8px 6px
}

.page-layout-nav .nav-item a:hover {
    color: #1890ff
}

.page-layout-nav .nav-item a.active {
    color: #1890ff;
    border-bottom-color: #1890ff
}

.page-container {
    background-color: #f0f2f5;
    padding: 50px
}

.page-container .page-inner {
    margin-left: auto;
    margin-right: auto;
    max-width: 1440px
}

.full-content-height {
    height: calc(100vh - 96px)
}

.icon-container {
    align-items: center;
    border-radius: 50%;
    display: inline-flex;
    height: 24px;
    justify-content: center;
    width: 24px
}

.video-modal .ant-modal-body {
    padding: 0
}

.video-modal .ant-modal-close {
    right: -40px;
    top: -40px
}

.highlight-modal .ant-modal-content {
    border: 4px solid rgba(0,0,0,.8)
}

.highlight-modal .ant-modal-content .ant-modal-header {
    background-color: #1268bb;
    border-radius: 0
}

.highlight-modal .ant-modal-content .ant-modal-header .ant-modal-title {
    color: #fff
}

.highlight-modal .ant-btn {
    color: #1268bb;
    border-color: #1268bb
}

.highlight-modal .ant-btn.ant-btn-primary {
    background: #1268bb;
    color: #fff
}

.ant-tag.actionable {
    cursor: pointer
}

.ant-tag.actionable.is-selected {
    background-color: #e9f4fe;
    border-color: #1890ff;
    color: #4091f7
}

.add-tag-button {
    background: #fff;
    border-radius: 2px;
    border: 1px dashed #d9d9d9;
    color: #999;
    font-size: 12px;
    line-height: 20px;
    height: 22px;
    padding: 0 7px
}

.article img {
    max-width: 100%
}

.article p {
    margin-bottom: 1em
}

.article table {
    max-width: 100%
}

.article table,.article table td,.article table th {
    border-style: solid;
    border-width: 1px
}

.ant-form-vertical .horizontal-form-item {
    align-items: flex-start;
    flex-direction: row
}

.ant-form-vertical .horizontal-form-item .ant-form-item-label {
    padding-bottom: 5px;
    padding-right: .5em;
    padding-top: 5px
}

.ant-form-item-vertical .ant-form-item-label {
    text-align: left;
    width: 100%
}

#root .ant-image-preview-mask,#root .ant-modal-mask {
    background-color: rgba(0,0,0,.6)
}

.ant-btn:not(.ant-btn-icon-only) {
    min-width: 96px
}

.ant-layout {
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out
}

.ant-alert-warning {
    background-color: #fff7e6;
    border: 1px solid #ffad00
}

.ant-back-top {
    bottom: 38px!important
}

.ant-btn-link {
    padding: 0
}

.ant-statistic {
    display: inline-block
}

.ant-input-affix-wrapper {
    padding: 0 11px!important
}

.ant-input-affix-wrapper>input {
    padding: 4px 0!important
}

.ant-modal {
    top: 64px
}

@media screen and (max-width: 1160px) {
    .shop-list {
        width:23%!important
    }
}

@media screen and (max-width: 950px) {
    .shop-list {
        width:21%!important
    }
}

#root,body,html {
    height: 100%;
}

.trigger {
    cursor: pointer
}

.layout-header {
    background: #0055cc!important;
    padding: 0 32px!important;
    min-width: 850px;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out
}

.layout-header .tab-group {
    display: inline-block;
    font-size: 16px;
    font-weight: 600
}

.layout-header .tab-group a {
    color: #fff;
    margin-left: 8px;
    line-height: 48px;
    display: inline-block
}

.layout-header .tab-group a.active {
    border-bottom: 2px solid #fff
}

.layout-header .header-bell,.layout-header .help-link {
    display: inline-block;
    vertical-align: top;
    color: #fff;
    cursor: pointer;
    height: 64px
}

.layout-header .user-info {
    display: inline-block;
    color: #fff;
    width: 250px;
    padding: 0;
    position: relative
}

.layout-header .user-info img {
    vertical-align: unset
}

.layout-header .user-info-box {
    width: 100%;
    margin-top: 0;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out
}

.layout-header .user-name {
    line-height: 16px;
    width: 100%;
    margin: 24px 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden
}

.layout-header .level-leftDay {
    line-height: 20px
}

.layout-header .level-leftDay>p {
    display: inline-block;
    line-height: 12px;
    vertical-align: top;
    padding: 3px 5px
}

.layout-header .user-level {
    font-size: 12px;
    background: #ff5067;
    border-radius: 2px;
    color: #fff;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden
}

.layout-header .go-to-help,.layout-header .go-to-profile,.layout-header .log-out {
    font-size: 12px;
    color: #666;
    cursor: pointer
}

.layout-header .go-to-help:hover,.layout-header .go-to-profile:hover,.layout-header .log-out:hover {
    color: #108ee9
}

.footer-feedback-box {
    margin: 16px
}

.footer-feedback-box .not-feedback-cont {
    width: 510px;
    height: 50px;
    line-height: 50px;
    background: #fff;
    margin: 0 auto;
    text-align: center;
    border-radius: 45px
}

.footer-feedback-box .not-feedback-cont span {
    cursor: pointer;
    color: #108ee9;
    margin-left: 30px
}

.side-default {
    box-shadow: 2px 0 4px rgba(0,0,0,.08);
    z-index: 2
}

.side-default .ant-menu-inline .ant-menu-submenu-title {
    overflow: visible
}

.side-default .menu-left {
    border-right: 1px solid transparent
}

.side-default .ant-menu-inline-collapsed {
    width: 60px
}

.side-default .ant-menu-inline-collapsed .ant-menu-item,.side-default .ant-menu-inline-collapsed .ant-menu-submenu>.ant-menu-submenu-title {
    padding: 0 22px!important
}

.side-default .side-inner {
    height: calc(100% - 49px);
    overflow: hidden auto
}

.side-default .side-default-new-feature-tag {
    margin: 0;
    position: absolute;
    border-radius: 5px 5px 5px 0;
    font-weight: 500;
    line-height: 16px;
    padding-left: 5px;
    padding-right: 5px;
    right: 35px;
    top: -8px;
    animation: float 5s infinite
}

@keyframes float {
    0%,to {
        transform: translateY(0)
    }

    50% {
        transform: translateY(4px)
    }
}

.pop-notice .notice-icon,.top-notice .notice-icon {
    display: inline-block;
    width: 24px;
    height: 24px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAexJREFUSA21lrtLA0EQxnPxjQh2CiKCqJ3aCSmEWNj5L1gIWllpZ2tpZ6ONlRY+KouAhYigIAqCtUFLRRuxsIgv4m/iTBgvuRjPZOFjZndmvi+3t7OXRKLKkc/nO8G8pKt/jV0DqSopotMg6QAXYMMJMC2OE7zRaIYKEQrbwalSmUDAPAVWwLPGXrELFahKQxS0giMlEFMQ8JmsydatS1DHso9H+iQ3gwOrUlsiYATEp8GH5s3aellLUhPY12RvIgWEiMRZTX7BDkaRNxDc08SwsXfQRmAGtIdJWNvVou1wTH5BEmxpQjljArLvMp5A2hMx7wVvQLarX0j/MkxAXv6xFuaw4yGRHY0tJn2gWj8IghyYIH8JtIBVCANXn1F/MtYTOCKptx5J2zprQ/oEt7GewIjU7qgdcesP6nfVQuBeyXqcQNGthcCAst0UWROJbvUf/yXAPjdCNKdkl05gTP1sbAHI2yDZAtKxGU7VlZKKmVL/0K0VWl0abVNPQDljfSA37KMm3GFtm+RUSaO9g+9G+6HAhIBcFdbuuD+GCVgnnxHt8xzMrbb0qrBEkhpB5GVHTG7aYcs3y9rvl51LjnNdfyIio/J17UTq98FxIvX7ZDoR+eifg/BLlu2QEf+j70Tk5Pi/LVnmv/5t+QIWMTWPZAGcSwAAAABJRU5ErkJggg==) no-repeat 50%;
    background-size: 100%
}

.pop-notice .notice-level,.top-notice .notice-level {
    display: inline-block;
    margin-bottom: 0;
    padding: 0 10px;
    font-size: 20px;
    color: #fff;
    text-align: center
}

.pop-notice .more-notice,.top-notice .more-notice {
    display: block;
    text-decoration: underline;
    color: #fff
}

.pop-notice .ant-modal-header {
    border: none;
    height: 80px
}

.pop-notice .ant-modal-header .ant-modal-title {
    height: 100%
}

.pop-notice .ant-modal-close-x {
    color: #fff
}

.pop-notice .pop-notice-title {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center
}

.pop-notice .pop-notice-content {
    padding: 20px;
    background-color: #fff
}

.pop-notice .ant-modal-body {
    padding: 0 5px 5px
}

.pop-notice .pop-notice-content {
    min-height: 224px;
    max-height: 400px;
    font-size: 14px;
    line-height: 25px;
    overflow: auto
}

.pop-notice .pop-notice-content::-webkit-scrollbar {
    width: 5px
}

.pop-notice .pop-notice-content::-webkit-scrollbar-track {
    background-color: transparent
}

.pop-notice .pop-notice-content::-webkit-scrollbar-thumb {
    border-radius: 5px;
    box-shadow: inset 0 0 1px hsla(0,0%,88.2%,.3);
    background-color: rgba(0,0,0,.3)
}

.pop-notice .pop-notice-content p {
    font-size: 14px;
    line-height: 25px
}

.pop-notice.level0 .ant-modal-body,.pop-notice.level0 .ant-modal-header,.pop-notice.level10 .ant-modal-body,.pop-notice.level10 .ant-modal-header {
    background-color: #f35b53
}

.pop-notice.level0 .pop-notice-content,.pop-notice.level0 .pop-notice-content .more-notice,.pop-notice.level10 .pop-notice-content,.pop-notice.level10 .pop-notice-content .more-notice {
    color: #f35b53
}

.pop-notice.level20 .ant-modal-body,.pop-notice.level20 .ant-modal-header {
    background-color: #ffbd2f
}

.pop-notice.level20 .pop-notice-content,.pop-notice.level20 .pop-notice-content .more-notice {
    color: #ffbd2f
}

.pop-notice.level30 .ant-modal-body,.pop-notice.level30 .ant-modal-header {
    background-color: #1586ef
}

.pop-notice.level30 .pop-notice-content,.pop-notice.level30 .pop-notice-content .more-notice {
    color: #1586ef
}

.top-notice {
    position: relative;
    padding: 0 32px;
    max-height: 0;
    transition: all .8s ease-in-out
}

.top-notice.level0,.top-notice.level10 {
    background-color: #f35b53
}

.top-notice.level20 {
    background-color: #ffbd2f
}

.top-notice.level30 {
    background-color: #1586ef
}

.top-notice.show-notice {
    max-height: 100%
}

.top-notice.none-notice {
    max-height: 0
}

.top-notice .top-notice-main {
    display: flex;
    width: 100%;
    align-items: center;
    padding: 15px 0
}

.top-notice .top-notice-main .top-notice-title {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    max-width: 600px;
    color: #fff
}

.top-notice .top-notice-main .pop-notice-content {
    padding-left: 10px;
    flex-grow: 1;
    max-height: 108px;
    overflow: hidden auto
}

.top-notice .top-notice-main .pop-notice-content h3 {
    color: #fff;
    font-size: 18px;
    padding-bottom: 8px
}

.top-notice .top-notice-main .pop-notice-content .pop-notice-content-html {
    color: #fff;
    font-size: 14px;
    line-height: 1.5
}

.top-notice .top-notice-main .pop-notice-content .pop-notice-content-html p {
    font-size: 14px;
    line-height: 1.5
}

.top-notice .close {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 6px;
    right: 6px;
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPBAMAAADJ+Ih5AAAALVBMVEUAAAD///////////////////////////////////////////////////////+hSKubAAAAD3RSTlMA6SpBPleBYE39NzUZFw0USNFAAAAAUUlEQVQI12O4wQACLAoMigEghqMBA5MoSEAYSACFQAIMQCGwAFgIJAASEgMKgEFlAoRmEQdqhJgB1Ag2A6gRIgDSCDEULLTRAGxWA8MriFkHAHvmCoETpJWLAAAAAElFTkSuQmCC") 50% no-repeat;
    text-indent: -9000em;
    cursor: pointer
}

.purchase-modal-content>div {
    display: inline-block;
    width: 80%
}

.purchase-modal-content-detail {
    padding-left: 32px
}

.purchase-modal-content-detail strong {
    display: inline-block;
    margin-bottom: 5px
}

.purchase-modal-content-detail p {
    color: #999;
    margin-bottom: 5px
}

.purchase-modal-content-detail>div:first-child {
    margin-top: 22px
}

.purchase-modal-content-detail>div form {
    display: inline-block;
    vertical-align: middle;
    width: 100%
}

.purchase-modal-content-detail>div form .ant-form-item {
    margin-bottom: 0
}

.purchase-modal-content-detail>div form .ant-row:first-child .ant-form-item-control-wrapper {
    display: inline-block
}

.purchase-modal-content-detail label {
    margin-right: 5px
}

.purchase-modal-content .no-type-text-area {
    margin-top: -20px;
    position: relative;
    display: block!important
}

.purchase-modal-content .no-type-text-area .tips {
    position: absolute;
    z-index: 2;
    right: 0;
    cursor: pointer;
    margin-top: -40px
}

.purchase-modal-content .no-type-text-area .ant-tooltip-content .ant-tooltip-inner {
    color: #333!important
}

.purchase-modal-content .no-type-text-area .ant-tooltip-content .ant-tooltip-inner h4 {
    font-size: 14px;
    color: #333;
    margin-bottom: 5px
}

.purchase-modal-content .no-type-text-area .ant-tooltip-content .ant-tooltip-inner p {
    color: #666;
    overflow: auto;
    height: 80px
}

.purchase-modal-content .no-type-text-area .text-value {
    position: absolute;
    right: 8px;
    bottom: -19px;
    color: #bbb
}

.purchase-modal-content .no-type-text-area .tip-content {
    max-height: 150px;
    overflow: auto;
    line-height: 18px
}

.show-privilege-modal .ant-modal-body {
    padding: 40px 30px 20px
}

.show-privilege-modal .ant-modal-footer {
    border-top: none!important;
    text-align: center
}

.show-privilege-modal .ant-modal-footer a,.show-privilege-modal .ant-modal-footer span {
    background: #1890ff;
    color: #fff;
    height: 32px;
    display: inline-block;
    line-height: 32px;
    width: 140px;
    border-radius: 4px;
    cursor: pointer
}

.show-privilege-modal .ant-modal-footer span {
    margin-left: 60px;
    box-shadow: 0 2px 0 rgba(0,0,0,.015);
    color: rgba(0,0,0,.65);
    background-color: #fff;
    border: 1px solid #d9d9d9
}

.push-status-list {
    display: inline-block;
    color: rgba(0,0,0,.65);
    margin-left: 25px
}

@media (max-width: 1350px) {
    .push-status-list {
        display:none
    }
}

.push-status-list i {
    float: right!important;
    margin-top: 9px;
    font-size: 14px
}

.push-status-list div {
    padding: 0 15px;
    height: 32px;
    line-height: 32px;
    display: inline-block;
    background: #f2f2f2;
    margin-right: 10px;
    border-radius: 4px
}

.push-status-list div span {
    display: inline-block;
    color: #f5222d;
    padding-right: 15px;
    vertical-align: top
}

.list-content {
    max-width: 420px
}

.list-content .no-data {
    margin: 5px;
    text-align: center;
    border-bottom: 1px dashed #e8e8e8
}

.list-content .no-data img {
    margin: 50px 0 30px
}

.list-content .no-data p {
    text-align: center;
    margin-bottom: 50px
}

.list-content li {
    margin: 5px;
    border-bottom: 1px dashed #e8e8e8
}

.list-content li .shop-name {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-right: 10px
}

.list-content li .status {
    width: 95px;
    height: 30px;
    line-height: 30px;
    padding-left: 13px;
    border-radius: 15px
}

.list-content li .status span {
    margin-right: 8px;
    border-radius: 50%;
    display: inline-block;
    width: 11px;
    height: 11px
}

.list-content li .status-0 {
    background: #e6f7ff
}

.list-content li .status-0 span {
    border: 2px solid #91d5ff
}

.list-content li .status-1 {
    background: #fff7e6
}

.list-content li .status-1 span {
    border: 2px solid #fa8c16
}

.list-content li .status-3 {
    background: #fff1f0
}

.list-content li .status-3 span {
    border: 2px solid #f5222d
}

.list-content li:last-child {
    border: none
}

.list-content a {
    display: block;
    text-align: center;
    color: #1890ff
}

.ant-table-row td {
    vertical-align: top
}

#top_panel {
    position: absolute;
    top: 0;
    z-index: 10;
    right: 0;
    width: 60%;
    line-height: 64px;
    padding: 0 16px;
    background: #24292e;
    text-align: right
}

.block-item {
    margin-bottom: -8px!important
}

.block-item .ant-form-item>.ant-form-item-control-wrapper,.block-item .ant-form-item>.ant-form-item-label {
    line-height: 22px!important;
    display: block!important
}

.block-item .ant-form-item>.ant-form-item-control-wrapper {
    margin-bottom: 8px
}

.block-item :last-child.ant-form-item>.ant-form-item-control-wrapper {
    margin-bottom: 0
}

.block-item .ant-legacy-form-item>.ant-legacy-form-item-control-wrapper,.block-item .ant-legacy-form-item>.ant-legacy-form-item-label {
    line-height: 22px!important;
    display: block!important
}

.block-item .ant-legacy-form-item>.ant-legacy-form-item-control-wrapper {
    margin-bottom: 8px
}

.block-item :last-child.ant-legacy-form-item>.ant-legacy-form-item-control-wrapper {
    margin-bottom: 0
}

.block-item .ant-legacy-form-item-control {
    line-height: unset!important;
    margin-top: 8px
}

.block-item .preset-date-range-select+.ant-legacy-form-item .ant-legacy-form-item-control {
    margin-top: 0
}

.preset-date-range .ant-form-item-control-input-content {
    display: flex
}

.preset-date-range-select+.ant-form-item {
    margin-bottom: 0
}

.guideIcon_box {
    display: inline-block;
    vertical-align: top;
    text-align: center;
    margin-right: 22px;
    position: relative
}

.guideIcon {
    width: 18px;
    height: 18px;
    margin-bottom: 6px;
    cursor: pointer
}

.guideIcon_box_dot {
    width: 6px;
    height: 6px;
    background: #f5222d;
    border-radius: 100%;
    box-shadow: 0 0 0 1px #fff;
    position: absolute;
    top: 18px;
    right: -6px
}

.guideBox_pop {
    top: 15px;
    right: 30px;
    width: 240px;
    height: 32px;
    padding: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #333;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0,0,0,.15);
    animation: mymove .75s linear 2.5s infinite
}

.guideBox_pop,.guideBox_pop:before {
    position: absolute;
    background-color: #fff
}

.guideBox_pop:before {
    width: 12px;
    height: 12px;
    content: "";
    bottom: 50%;
    left: 97%;
    transform: translateY(50%) rotate(140deg);
    border-color: #e5e5e5 #fff #fff #e5e5e5;
    border-style: solid;
    border-width: 1px
}

@keyframes mymove {
    0% {
        transform: translateX(0)
    }

    50% {
        transform: translateX(6px)
    }

    to {
        transform: translateX(0)
    }
}

.guideContainer {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 999;
    background-color: #f0f2f5;
    color: #333;
    overflow-y: auto;
    opacity: 1;
    visibility: visible;
    transition: all .25s linear
}

.guideContainer_none {
    opacity: 0;
    visibility: hidden
}

.guideContainer_titleWrapper {
    background-color: #fff;
    margin-bottom: 12px
}

.guideContainer_titleWrapper_box {
    width: 1200px;
    height: 64px;
    margin: 0 auto;
    display: flex;
    align-items: center
}

.guideContainer_titleWrapper_title {
    font-size: 24px;
    font-weight: 500;
    margin-right: auto;
    margin-bottom: 0
}

.guideContainer_titleWrapper_close {
    color: #1890ff;
    cursor: pointer;
    font-weight: 400;
    margin-bottom: 0
}

.guideContainer_content {
    width: 1200px;
    margin: 0 auto 24px;
    background-color: #fff;
    line-height: 1
}

.guideContainer_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    background: #f9f9f9;
    padding: 0 24px;
    font-weight: 700;
    border-bottom: 1px solid #e5e5e5
}

.guideMain_wrapper {
    padding: 0 24px 24px
}

.guideMain_title {
    line-height: 20px;
    margin-top: 32px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 500
}

.guideMain_desc {
    margin-top: 8px;
    margin-bottom: 0;
    line-height: 1.8
}

.notNewUser {
    padding-bottom: 24px;
    border-top: 1px solid #e5e5e5
}

.guideMain_select_box {
    padding-left: 24px;
    margin: 24px 0
}

.guideMain_select {
    width: 260px;
    height: 32px
}

.guideContent {
    display: flex;
    min-width: 800px;
    min-height: 290px;
    margin: 0 24px;
    border: 1px solid #e5e5e5
}

.guideContent_leftContainer {
    border-right: 1px solid #e5e5e5;
    background-color: #f9f9f9
}

.guideContent_left {
    display: flex;
    align-items: flex-start;
    width: 240px;
    height: 76px;
    padding-left: 16px;
    padding-top: 20px;
    font-size: 14px;
    border-bottom: 1px solid #e5e5e5;
    line-height: 1;
    cursor: pointer
}

.guideContent_left:last-of-type {
    border-bottom: none
}

.guideContent_left_no {
    cursor: default;
    border-bottom: none
}

.guideContent_left_active {
    position: relative;
    border-right: none;
    background-color: #fff
}

.guideContent_left_active:after {
    display: block;
    height: 100%;
    width: 1px;
    background: #fff;
    content: "";
    position: absolute;
    right: -1px;
    top: 0
}

.guideContent_left_dot {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    border-radius: 100%;
    background-color: #bbb;
    font-size: 12px;
    color: #fff
}

.guideContent_left_dot_active {
    background-color: #1890ff
}

.guideContent_left_check {
    width: 16px;
    height: 16px
}

.guideContent_left_box_title {
    flex: 1;
    margin-left: 10px;
    line-height: 1.2
}

.guideContent_left_box_title_active {
    color: #1890ff
}

.guideContent_right {
    flex: 1;
    display: flex;
    padding: 16px 16px 0 32px
}

.guideContent_right_box {
    flex: 1;
    margin-right: 32px;
    position: relative
}

.guideContent_right_box_title {
    margin-bottom: 8px;
    font-weight: 700
}

.guideContent_right_box_desc {
    line-height: 24px
}

.guideContent_right_box_button {
    left: 0;
    min-width: 170px;
    line-height: 32px;
    color: #fff;
    background-color: #1890ff;
    border-radius: 4px;
    border: none
}

.guideContent_right_box_button,.guideContent_right_box_button_saveProducts {
    display: inline-block;
    position: absolute;
    bottom: 16px;
    padding: 0 12px;
    text-align: center;
    text-decoration: none;
    outline: none;
    cursor: pointer
}

.guideContent_right_box_button_saveProducts {
    left: 186px;
    min-width: 140px;
    line-height: 30px;
    color: #1890ff;
    background-color: #fff;
    border: 1px solid #1890ff;
    border-radius: 4px
}

.guideContent_right_box_button_saveProducts_none {
    display: none
}

.guideContent_right_img {
    width: 120px;
    height: 120px;
    margin-top: 32px
}

.newUserGuide {
    margin: 0 24px
}

.newUserGuide_box {
    display: flex;
    align-items: flex-start;
    padding-bottom: 24px
}

.newUserGuide_box_border {
    margin-bottom: 22px;
    border-bottom: 1px solid #e5e5e5
}

.newUserGuide_box_img {
    width: 222px;
    height: 130px;
    margin-right: 24px
}

.newUserGuide_box_right {
    flex: 1;
    line-height: 1
}

.newUserGuide_box_right_title {
    font-size: 14px;
    font-weight: 500;
    color: #333;
    line-height: 20px;
    margin-bottom: 8px
}

.newUserGuide_box_right_desc {
    font-size: 14px;
    line-height: 24px;
    color: #666;
    margin-bottom: 22px
}

.newUserGuide_box_right_button {
    width: 120px;
    height: 32px;
    background: #1890ff;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    cursor: pointer;
    outline: none;
    border: none
}

.bill-board {
    flex: none;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 132px;
    width: 100%;
    transition: height .35s;
    background: #fff7e6;
    padding-left: 20px;
    padding-right: 60px
}

.bill-board.cn {
    height: 106px
}

.bill-board.cn .control-bar {
    height: 137px
}

.bill-board.hide {
    height: 0
}

.bill-board.hide .control-bar {
    height: 43px
}

.bill-board .title {
    color: #333;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px
}

.bill-board .content {
    color: #333;
    font-size: 14px
}

.bill-board .control-bar {
    height: 157px;
    transition: height .35s;
    position: absolute;
    right: 26px;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 9
}

.bill-board .control-bar .bar {
    flex: 1;
    width: 4px;
    background: #fff;
    box-shadow: 0 0 12px 0 rgba(0,0,0,.2)
}

.bill-board .control-bar .red-dot {
    flex-shrink: 0;
    cursor: pointer;
    height: 16px;
    width: 16px;
    background: #f3383e;
    display: flex;
    align-items: center;
    justify-content: center
}

.ant-image-preview,.ant-modal {
    pointer-events: none
}

.ant-image-preview.zoom-appear,.ant-image-preview.zoom-enter,.ant-modal.zoom-appear,.ant-modal.zoom-enter {
    transform: none;
    opacity: 0;
    animation-duration: .3s;
    user-select: none
}

.ant-image-preview-mask,.ant-modal-mask {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    height: 100%;
    background-color: rgba(0,0,0,.45);
    filter: alpha(opacity=50)
}

.ant-image-preview-mask-hidden,.ant-modal-mask-hidden {
    display: none
}

.ant-image-preview-wrap,.ant-modal-wrap {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    outline: 0;
    -webkit-overflow-scrolling: touch
}

.ant-legacy-form {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0,0,0,.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum"
}

.ant-legacy-form legend {
    display: block;
    width: 100%;
    margin-bottom: 20px;
    padding: 0;
    color: rgba(0,0,0,.45);
    font-size: 16px;
    line-height: inherit;
    border: 0;
    border-bottom: 1px solid #d9d9d9
}

.ant-legacy-form label {
    font-size: 14px
}

.ant-legacy-form input[type=search] {
    box-sizing: border-box
}

.ant-legacy-form input[type=checkbox],.ant-legacy-form input[type=radio] {
    line-height: normal
}

.ant-legacy-form input[type=file] {
    display: block
}

.ant-legacy-form input[type=range] {
    display: block;
    width: 100%
}

.ant-legacy-form select[multiple],.ant-legacy-form select[size] {
    height: auto
}

.ant-legacy-form input[type=checkbox]:focus,.ant-legacy-form input[type=file]:focus,.ant-legacy-form input[type=radio]:focus {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px
}

.ant-legacy-form output {
    display: block;
    padding-top: 15px;
    color: rgba(0,0,0,.85);
    font-size: 14px;
    line-height: 1.5715
}

.ant-legacy-form-item-required:before {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun,sans-serif;
    line-height: 1;
    content: "*"
}

.ant-legacy-form-hide-required-mark .ant-legacy-form-item-required:before {
    display: none
}

.ant-legacy-form-item-label>label {
    color: rgba(0,0,0,.85)
}

.ant-legacy-form-item-label>label:after {
    content: ":";
    position: relative;
    top: -.5px;
    margin: 0 8px 0 2px
}

.ant-legacy-form-item-label>label.ant-legacy-form-item-no-colon:after {
    content: " "
}

.ant-legacy-form-item {
    box-sizing: border-box;
    padding: 0;
    color: rgba(0,0,0,.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum";
    margin: 0 0 24px;
    vertical-align: top
}

.ant-legacy-form-item label {
    position: relative
}

.ant-legacy-form-item label>.anticon {
    font-size: 14px;
    vertical-align: top
}

.ant-legacy-form-item-control {
    position: relative;
    line-height: 40px
}

.ant-legacy-form-item-control:before {
    display: table;
    content: ""
}

.ant-legacy-form-item-control:after {
    display: table;
    clear: both;
    content: ""
}

.ant-legacy-form-item-children {
    position: relative
}

.ant-legacy-form-item-with-help {
    margin-bottom: 4px
}

.ant-legacy-form-item-label {
    display: inline-block;
    overflow: hidden;
    line-height: 39.9999px;
    white-space: nowrap;
    text-align: right;
    vertical-align: middle;
    flex-grow: 0
}

.ant-legacy-form-item-label-left {
    text-align: left
}

.ant-legacy-form-item-control-wrapper {
    flex: 1 1 0
}

.ant-legacy-form-item .ant-switch {
    margin: 2px 0 4px
}

.ant-legacy-form-explain,.ant-legacy-form-extra {
    clear: both;
    min-height: 23px;
    margin-top: -2px;
    color: rgba(0,0,0,.45);
    font-size: 14px;
    line-height: 1.5715;
    transition: color .3s cubic-bezier(.215,.61,.355,1)
}

.ant-legacy-form-explain {
    margin-bottom: -1px
}

.ant-legacy-form-extra {
    padding-top: 4px
}

.ant-legacy-form-text {
    display: inline-block;
    padding-right: 8px
}

.ant-legacy-form-split {
    display: block;
    text-align: center
}

form .has-feedback .ant-input {
    padding-right: 30px
}

form .has-feedback .ant-input-affix-wrapper .ant-input-suffix {
    padding-right: 18px
}

form .has-feedback .ant-input-affix-wrapper .ant-input {
    padding-right: 49px
}

form .has-feedback .ant-input-affix-wrapper.ant-input-affix-wrapper-input-with-clear-btn .ant-input {
    padding-right: 68px
}

form .has-feedback :not(.ant-input-group-addon)>.ant-select .ant-select-arrow,form .has-feedback :not(.ant-input-group-addon)>.ant-select .ant-select-selection__clear,form .has-feedback>.ant-select .ant-select-arrow,form .has-feedback>.ant-select .ant-select-selection__clear {
    right: 28px
}

form .has-feedback :not(.ant-input-group-addon)>.ant-select .ant-select-selection-selected-value,form .has-feedback>.ant-select .ant-select-selection-selected-value {
    padding-right: 42px
}

form .has-feedback .ant-cascader-picker-arrow {
    margin-right: 17px
}

form .has-feedback .ant-calendar-picker-clear,form .has-feedback .ant-calendar-picker-icon,form .has-feedback .ant-cascader-picker-clear,form .has-feedback .ant-input-search:not(.ant-input-search-enter-button) .ant-input-suffix,form .has-feedback .ant-time-picker-clear,form .has-feedback .ant-time-picker-icon {
    right: 28px
}

form .has-feedback .ant-picker .ant-picker-suffix {
    padding-right: 17px
}

form .ant-mentions,form textarea.ant-input {
    height: auto;
    margin-bottom: 4px
}

form .ant-upload {
    background: transparent
}

form input[type=checkbox],form input[type=radio] {
    width: 14px;
    height: 14px
}

form .ant-checkbox-inline,form .ant-radio-inline {
    display: inline-block;
    margin-left: 8px;
    font-weight: 400;
    vertical-align: middle;
    cursor: pointer
}

form .ant-checkbox-inline:first-child,form .ant-radio-inline:first-child {
    margin-left: 0
}

form .ant-checkbox-vertical,form .ant-radio-vertical {
    display: block
}

form .ant-checkbox-vertical+.ant-checkbox-vertical,form .ant-radio-vertical+.ant-radio-vertical {
    margin-left: 0
}

form .ant-input-number+.ant-legacy-form-text {
    margin-left: 8px
}

form .ant-input-number-handler-wrap {
    z-index: 2
}

form .ant-cascader-picker,form .ant-select {
    width: 100%
}

form .ant-input-group .ant-cascader-picker,form .ant-input-group .ant-select {
    width: auto
}

form .ant-input-group-wrapper,form :not(.ant-input-group-wrapper)>.ant-input-group {
    position: relative;
    top: -1px;
    display: inline-block;
    vertical-align: middle
}

.ant-col-24.ant-legacy-form-item-label,.ant-col-xl-24.ant-legacy-form-item-label,.ant-legacy-form-vertical .ant-legacy-form-item-label {
    display: block;
    margin: 0;
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: normal;
    text-align: left;
    flex-basis: 100%
}

.ant-col-24.ant-legacy-form-item-label label:after,.ant-col-xl-24.ant-legacy-form-item-label label:after,.ant-legacy-form-vertical .ant-legacy-form-item-label label:after {
    display: none
}

.ant-legacy-form-vertical .ant-legacy-form-item {
    padding-bottom: 8px
}

.ant-legacy-form-vertical .ant-legacy-form-item-control {
    line-height: 1.5715
}

.ant-legacy-form-vertical .ant-legacy-form-explain {
    margin-top: 2px;
    margin-bottom: -5px
}

.ant-legacy-form-vertical .ant-legacy-form-extra {
    margin-top: 2px;
    margin-bottom: -4px
}

@media (max-width: 575px) {
    .ant-legacy-form-item-control-wrapper,.ant-legacy-form-item-label {
        display:block;
        width: 100%
    }

    .ant-legacy-form-item-label {
        display: block;
        margin: 0;
        padding: 0 0 8px;
        line-height: 1.5715;
        white-space: normal;
        text-align: left;
        flex-basis: 100%
    }

    .ant-legacy-form-item-label label:after {
        display: none
    }

    .ant-legacy-form-item-control-wrapper {
        flex-basis: 100%
    }

    .ant-col-xs-24.ant-legacy-form-item-label {
        display: block;
        margin: 0;
        padding: 0 0 8px;
        line-height: 1.5715;
        white-space: normal;
        text-align: left;
        flex-basis: 100%
    }

    .ant-col-xs-24.ant-legacy-form-item-label label:after {
        display: none
    }
}

@media (max-width: 767px) {
    .ant-col-sm-24.ant-legacy-form-item-label {
        display:block;
        margin: 0;
        padding: 0 0 8px;
        line-height: 1.5715;
        white-space: normal;
        text-align: left;
        flex-basis: 100%
    }

    .ant-col-sm-24.ant-legacy-form-item-label label:after {
        display: none
    }
}

@media (max-width: 991px) {
    .ant-col-md-24.ant-legacy-form-item-label {
        display:block;
        margin: 0;
        padding: 0 0 8px;
        line-height: 1.5715;
        white-space: normal;
        text-align: left;
        flex-basis: 100%
    }

    .ant-col-md-24.ant-legacy-form-item-label label:after {
        display: none
    }
}

@media (max-width: 1199px) {
    .ant-col-lg-24.ant-legacy-form-item-label {
        display:block;
        margin: 0;
        padding: 0 0 8px;
        line-height: 1.5715;
        white-space: normal;
        text-align: left;
        flex-basis: 100%
    }

    .ant-col-lg-24.ant-legacy-form-item-label label:after {
        display: none
    }
}

@media (max-width: 1599px) {
    .ant-col-xl-24.ant-legacy-form-item-label {
        display:block;
        margin: 0;
        padding: 0 0 8px;
        line-height: 1.5715;
        white-space: normal;
        text-align: left;
        flex-basis: 100%
    }

    .ant-col-xl-24.ant-legacy-form-item-label label:after {
        display: none
    }
}

.ant-legacy-form-inline .ant-legacy-form-item {
    display: inline-block;
    margin-right: 16px;
    margin-bottom: 0
}

.ant-legacy-form-inline .ant-legacy-form-item-with-help {
    margin-bottom: 24px
}

.ant-legacy-form-inline .ant-legacy-form-item>.ant-legacy-form-item-control-wrapper,.ant-legacy-form-inline .ant-legacy-form-item>.ant-legacy-form-item-label {
    display: inline-block;
    vertical-align: top
}

.ant-legacy-form-inline .ant-legacy-form-text,.ant-legacy-form-inline .has-feedback {
    display: inline-block
}

.has-error.has-feedback .ant-legacy-form-item-children-icon,.has-success.has-feedback .ant-legacy-form-item-children-icon,.has-warning.has-feedback .ant-legacy-form-item-children-icon,.is-validating.has-feedback .ant-legacy-form-item-children-icon {
    position: absolute;
    top: 50%;
    right: 0;
    z-index: 1;
    width: 32px;
    height: 20px;
    margin-top: -10px;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    visibility: visible;
    animation: zoomIn .3s cubic-bezier(.12,.4,.29,1.46);
    pointer-events: none
}

.has-error.has-feedback .ant-legacy-form-item-children-icon svg,.has-success.has-feedback .ant-legacy-form-item-children-icon svg,.has-warning.has-feedback .ant-legacy-form-item-children-icon svg,.is-validating.has-feedback .ant-legacy-form-item-children-icon svg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto
}

.has-success.has-feedback .ant-legacy-form-item-children-icon {
    color: #52c41a;
    animation-name: diffZoomIn1!important
}

.has-warning .ant-legacy-form-explain,.has-warning .ant-legacy-form-split {
    color: #faad14
}

.has-warning .ant-input,.has-warning .ant-input:hover {
    background-color: #fff;
    border-color: #faad14
}

.has-warning .ant-input:focus {
    border-color: #ffc53d;
    border-right-width: 1px!important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(250,173,20,.2)
}

.has-warning .ant-input:not([disabled]):hover {
    border-color: #faad14
}

.has-warning .ant-calendar-picker-open .ant-calendar-picker-input {
    border-color: #ffc53d;
    border-right-width: 1px!important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(250,173,20,.2)
}

.has-warning .ant-input-affix-wrapper .ant-input,.has-warning .ant-input-affix-wrapper .ant-input:hover {
    background-color: #fff;
    border-color: #faad14
}

.has-warning .ant-input-affix-wrapper .ant-input:focus {
    border-color: #ffc53d;
    border-right-width: 1px!important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(250,173,20,.2)
}

.has-warning .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
    border-color: #faad14
}

.has-warning .ant-input-prefix {
    color: #faad14
}

.has-warning .ant-input-group-addon {
    color: #faad14;
    background-color: #fff;
    border-color: #faad14
}

.has-warning .has-feedback {
    color: #faad14
}

.has-warning.has-feedback .ant-legacy-form-item-children-icon {
    color: #faad14;
    animation-name: diffZoomIn3!important
}

.has-warning .ant-select-selection,.has-warning .ant-select-selection:hover {
    border-color: #faad14
}

.has-warning .ant-select-focused .ant-select-selection,.has-warning .ant-select-open .ant-select-selection {
    border-color: #ffc53d;
    border-right-width: 1px!important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(250,173,20,.2)
}

.has-warning .ant-calendar-picker-icon:after,.has-warning .ant-cascader-picker-arrow,.has-warning .ant-picker-icon:after,.has-warning .ant-select-arrow,.has-warning .ant-time-picker-icon:after {
    color: #faad14
}

.has-warning .ant-input-number,.has-warning .ant-time-picker-input {
    border-color: #faad14
}

.has-warning .ant-input-number-focused,.has-warning .ant-input-number:focus,.has-warning .ant-time-picker-input-focused,.has-warning .ant-time-picker-input:focus {
    border-color: #ffc53d;
    border-right-width: 1px!important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(250,173,20,.2)
}

.has-warning .ant-input-number:not([disabled]):hover,.has-warning .ant-time-picker-input:not([disabled]):hover {
    border-color: #faad14
}

.has-warning .ant-cascader-picker:focus .ant-cascader-input {
    border-color: #ffc53d;
    border-right-width: 1px!important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(250,173,20,.2)
}

.has-warning .ant-cascader-picker:hover .ant-cascader-input,.has-warning .ant-input-affix-wrapper {
    border-color: #faad14
}

.has-warning .ant-input-affix-wrapper-focused {
    box-shadow: 0 0 0 2px rgba(250,173,20,.2)
}

.has-warning .ant-input-affix-wrapper .ant-input:focus {
    box-shadow: none
}

.has-error .ant-legacy-form-explain,.has-error .ant-legacy-form-split {
    color: #ff4d4f
}

.has-error .ant-input,.has-error .ant-input:hover {
    background-color: #fff;
    border-color: #ff4d4f
}

.has-error .ant-input:focus {
    border-color: #ff7875;
    border-right-width: 1px!important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(255,77,79,.2)
}

.has-error .ant-input:not([disabled]):hover {
    border-color: #ff4d4f
}

.has-error .ant-calendar-picker-open .ant-calendar-picker-input {
    border-color: #ff7875;
    border-right-width: 1px!important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(255,77,79,.2)
}

.has-error .ant-input-affix-wrapper .ant-input,.has-error .ant-input-affix-wrapper .ant-input:hover {
    background-color: #fff;
    border-color: #ff4d4f
}

.has-error .ant-input-affix-wrapper .ant-input:focus {
    border-color: #ff7875;
    border-right-width: 1px!important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(255,77,79,.2)
}

.has-error .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
    border-color: #ff4d4f
}

.has-error .ant-input-prefix {
    color: #ff4d4f
}

.has-error .ant-input-group-addon {
    color: #ff4d4f;
    background-color: #fff;
    border-color: #ff4d4f
}

.has-error .has-feedback {
    color: #ff4d4f
}

.has-error.has-feedback .ant-legacy-form-item-children-icon {
    color: #ff4d4f;
    animation-name: diffZoomIn2!important
}

.has-error .ant-select-selection,.has-error .ant-select-selection:hover {
    border-color: #ff4d4f
}

.has-error .ant-select-focused .ant-select-selection,.has-error .ant-select-open .ant-select-selection {
    border-color: #ff7875;
    border-right-width: 1px!important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(255,77,79,.2)
}

.has-error .ant-select.ant-select-auto-complete .ant-input:focus {
    border-color: #ff4d4f
}

.has-error .ant-input-group-addon .ant-select-selection {
    border-color: transparent;
    box-shadow: none
}

.has-error .ant-calendar-picker-icon:after,.has-error .ant-cascader-picker-arrow,.has-error .ant-picker-icon:after,.has-error .ant-select-arrow,.has-error .ant-time-picker-icon:after {
    color: #ff4d4f
}

.has-error .ant-input-number,.has-error .ant-time-picker-input {
    border-color: #ff4d4f
}

.has-error .ant-input-number-focused,.has-error .ant-input-number:focus,.has-error .ant-time-picker-input-focused,.has-error .ant-time-picker-input:focus {
    border-color: #ff7875;
    border-right-width: 1px!important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(255,77,79,.2)
}

.has-error .ant-input-number:not([disabled]):hover,.has-error .ant-mention-wrapper .ant-mention-editor,.has-error .ant-mention-wrapper .ant-mention-editor:not([disabled]):hover,.has-error .ant-time-picker-input:not([disabled]):hover {
    border-color: #ff4d4f
}

.has-error .ant-cascader-picker:focus .ant-cascader-input,.has-error .ant-mention-wrapper.ant-mention-active:not([disabled]) .ant-mention-editor,.has-error .ant-mention-wrapper .ant-mention-editor:not([disabled]):focus {
    border-color: #ff7875;
    border-right-width: 1px!important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(255,77,79,.2)
}

.has-error .ant-cascader-picker:hover .ant-cascader-input,.has-error .ant-transfer-list {
    border-color: #ff4d4f
}

.has-error .ant-transfer-list-search:not([disabled]) {
    border-color: #d9d9d9
}

.has-error .ant-transfer-list-search:not([disabled]):hover {
    border-color: #40a9ff;
    border-right-width: 1px!important
}

.has-error .ant-transfer-list-search:not([disabled]):focus {
    border-color: #40a9ff;
    border-right-width: 1px!important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(24,144,255,.2)
}

.has-error .ant-input-affix-wrapper {
    border-color: #ff4d4f
}

.has-error .ant-input-affix-wrapper-focused {
    box-shadow: 0 0 0 2px rgba(255,77,79,.2)
}

.has-error .ant-input-affix-wrapper .ant-input:focus {
    box-shadow: none
}

.is-validating.has-feedback .ant-legacy-form-item-children-icon {
    display: inline-block;
    color: #1890ff
}

.ant-advanced-search-form .ant-legacy-form-item {
    margin-bottom: 24px
}

.ant-advanced-search-form .ant-legacy-form-item-with-help {
    margin-bottom: 4px
}

.show-help-appear,.show-help-enter,.show-help-leave {
    animation-duration: .3s;
    animation-fill-mode: both;
    animation-play-state: paused
}

.show-help-appear.show-help-appear-active,.show-help-enter.show-help-enter-active {
    animation-name: antShowHelpIn;
    animation-play-state: running
}

.show-help-leave.show-help-leave-active {
    animation-name: antShowHelpOut;
    animation-play-state: running;
    pointer-events: none
}

.show-help-appear,.show-help-enter {
    opacity: 0
}

.show-help-appear,.show-help-enter,.show-help-leave {
    animation-timing-function: cubic-bezier(.645,.045,.355,1)
}

@keyframes antShowHelpIn {
    0% {
        transform: translateY(-5px);
        opacity: 0
    }

    to {
        transform: translateY(0);
        opacity: 1
    }
}

@keyframes antShowHelpOut {
    to {
        transform: translateY(-5px);
        opacity: 0
    }
}

@keyframes diffZoomIn1 {
    0% {
        transform: scale(0)
    }

    to {
        transform: scale(1)
    }
}

@keyframes diffZoomIn2 {
    0% {
        transform: scale(0)
    }

    to {
        transform: scale(1)
    }
}

@keyframes diffZoomIn3 {
    0% {
        transform: scale(0)
    }

    to {
        transform: scale(1)
    }
}

.ant-mention-wrapper {
    box-sizing: border-box;
    margin: 0;
    font-size: 14px;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: "tnum";
    display: inline-block;
    vertical-align: middle
}

.ant-mention-wrapper,.ant-mention-wrapper .ant-mention-editor {
    padding: 0;
    color: rgba(0,0,0,.85);
    line-height: 1.5715;
    position: relative;
    width: 100%
}

.ant-mention-wrapper .ant-mention-editor {
    display: inline-block;
    min-width: 0;
    font-size: 14px;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    transition: all .3s;
    display: block;
    height: auto;
    min-height: 32px
}

.ant-mention-wrapper .ant-mention-editor::-moz-placeholder {
    opacity: 1
}

.ant-mention-wrapper .ant-mention-editor::placeholder {
    color: #bfbfbf
}

.ant-mention-wrapper .ant-mention-editor:placeholder-shown {
    text-overflow: ellipsis
}

.ant-mention-wrapper .ant-mention-editor:hover {
    border-color: #40a9ff;
    border-right-width: 1px!important
}

.ant-mention-wrapper .ant-mention-editor-focused,.ant-mention-wrapper .ant-mention-editor:focus {
    border-color: #40a9ff;
    border-right-width: 1px!important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(24,144,255,.2)
}

.ant-mention-wrapper .ant-mention-editor-disabled {
    color: rgba(0,0,0,.25);
    background-color: #f5f5f5;
    cursor: not-allowed;
    opacity: 1
}

.ant-mention-wrapper .ant-mention-editor-disabled:hover {
    border-color: #d9d9d9;
    border-right-width: 1px!important
}

.ant-mention-wrapper .ant-mention-editor[disabled] {
    color: rgba(0,0,0,.25);
    background-color: #f5f5f5;
    cursor: not-allowed;
    opacity: 1
}

.ant-mention-wrapper .ant-mention-editor[disabled]:hover {
    border-color: #d9d9d9;
    border-right-width: 1px!important
}

.ant-mention-wrapper .ant-mention-editor-borderless,.ant-mention-wrapper .ant-mention-editor-borderless-disabled,.ant-mention-wrapper .ant-mention-editor-borderless-focused,.ant-mention-wrapper .ant-mention-editor-borderless:focus,.ant-mention-wrapper .ant-mention-editor-borderless:hover,.ant-mention-wrapper .ant-mention-editor-borderless[disabled] {
    background-color: transparent;
    border: none;
    box-shadow: none
}

textarea.ant-mention-wrapper .ant-mention-editor {
    max-width: 100%;
    height: auto;
    min-height: 32px;
    line-height: 1.5715;
    vertical-align: bottom;
    transition: all .3s,height 0s
}

.ant-mention-wrapper .ant-mention-editor-lg {
    padding: 6.5px 11px;
    font-size: 16px
}

.ant-mention-wrapper .ant-mention-editor-sm {
    padding: 0 7px
}

.ant-mention-wrapper .ant-mention-editor-wrapper {
    height: auto;
    overflow-y: auto
}

.ant-mention-wrapper.ant-mention-active:not(.disabled) .ant-mention-editor {
    border-color: #40a9ff;
    border-right-width: 1px!important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(24,144,255,.2)
}

.ant-mention-wrapper.disabled .ant-mention-editor {
    color: rgba(0,0,0,.25);
    background-color: #f5f5f5;
    cursor: not-allowed;
    opacity: 1
}

.ant-mention-wrapper.disabled .ant-mention-editor:hover {
    border-color: #d9d9d9;
    border-right-width: 1px!important
}

.ant-mention-wrapper .public-DraftEditorPlaceholder-root {
    position: absolute;
    pointer-events: none
}

.ant-mention-wrapper .public-DraftEditorPlaceholder-root .public-DraftEditorPlaceholder-inner {
    height: auto;
    padding: 5px 11px;
    color: #bfbfbf;
    white-space: pre-wrap;
    word-wrap: break-word;
    outline: none;
    opacity: 1
}

.ant-mention-wrapper .DraftEditor-editorContainer .public-DraftEditor-content {
    height: auto;
    padding: 5px 11px
}

.ant-mention-dropdown {
    box-sizing: border-box;
    padding: 0;
    color: rgba(0,0,0,.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum";
    position: absolute;
    top: -9999px;
    left: -9999px;
    z-index: 1050;
    min-width: 120px;
    max-height: 250px;
    margin: 1.5em 0 0;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: #fff;
    border-radius: 2px;
    outline: none;
    box-shadow: 0 3px 6px -4px rgba(0,0,0,.12),0 6px 16px 0 rgba(0,0,0,.08),0 9px 28px 8px rgba(0,0,0,.05)
}

.ant-mention-dropdown-placement-top {
    margin-top: -.1em
}

.ant-mention-dropdown-notfound.ant-mention-dropdown-item {
    color: rgba(0,0,0,.25)
}

.ant-mention-dropdown-notfound.ant-mention-dropdown-item .anticon-loading {
    display: block;
    color: #1890ff;
    text-align: center
}

.ant-mention-dropdown-item {
    position: relative;
    display: block;
    padding: 5px 12px;
    overflow: hidden;
    color: rgba(0,0,0,.85);
    font-weight: 400;
    line-height: 22px;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
    transition: background .3s
}

.ant-mention-dropdown-item:hover {
    background-color: #f5f5f5
}

.ant-mention-dropdown-item-active,.ant-mention-dropdown-item.focus {
    background-color: #e6f7ff
}

.ant-mention-dropdown-item-disabled {
    color: rgba(0,0,0,.25);
    cursor: not-allowed
}

.ant-mention-dropdown-item-disabled:hover {
    color: rgba(0,0,0,.25);
    background-color: #fff;
    cursor: not-allowed
}

.ant-mention-dropdown-item-selected,.ant-mention-dropdown-item-selected:hover {
    color: rgba(0,0,0,.85);
    font-weight: 700;
    background-color: #f5f5f5
}

.ant-mention-dropdown-item-divider {
    height: 1px;
    margin: 1px 0;
    overflow: hidden;
    line-height: 0;
    background-color: #f0f0f0
}

.verification-code.ant-legacy-form-item-with-help {
    margin-bottom: 3px
}

.profile-box .payment-password-reset .ant-form-item-control-input,.profile-box .payment-password-setting .ant-form-item-control-input {
    width: 50%
}

.login-password-reset .verification-code .ant-form-item-explain-error {
    display: none
}

.login-password-reset .verification-code .ant-form-item-control-input-content .ant-form-item-explain-error {
    display: block
}

.Y\+aPvvo6 legend {
    border-bottom: none!important;
    color: #333!important;
    font-weight: 700
}

.Y\+aPvvo6 .ant-radio-wrapper {
    margin-right: 16px
}

.Y\+aPvvo6 .ant-legacy-form-item-control-wrapper {
    flex: unset!important;
    width: 100%
}

.Xy55GpKl {
    padding: 0 16px 16px;
    background: #f9f9f9
}

.onboarding-popup {
    bottom: 0;
    right: 0;
    width: 300px;
    z-index: 3001
}

.onboarding-popup .popup-body,.onboarding-popup .popup-header {
    padding: 20px
}

.onboarding-popup .popup-header {
    background-image: url(https://cdn.buckydrop.com/starit-buckydrop/2024/dist/header-bg.ac08217c.jpg);
    background-repeat: no-repeat;
    background-size: 100%;
    border-bottom: 0
}

.onboarding-popup .ant-progress-bg {
    background-color: #1890ff
}

.onboarding-popup .ant-steps-vertical>.ant-steps-item>.ant-steps-item-container>.ant-steps-item-tail:after {
    background-color: #d8d8d8;
    width: 2px
}

.onboarding-popup .ant-steps-vertical>.ant-steps-item .ant-steps-item-icon {
    border-color: #d8d8d8;
    border-width: 2px;
    height: 18px;
    line-height: 18px;
    margin-right: 10px;
    width: 18px
}

.onboarding-popup .ant-steps-vertical>.ant-steps-item .ant-steps-item-icon>.ant-steps-icon {
    color: transparent
}

.onboarding-popup .ant-steps-vertical>.ant-steps-item.ant-steps-item-finish .ant-steps-item-icon {
    border-color: #1890ff;
    background-color: #1890ff
}

.onboarding-popup .ant-steps-vertical>.ant-steps-item.ant-steps-item-finish .ant-steps-item-icon .ant-steps-icon {
    color: #fff;
    top: -1px
}

.onboarding-popup .ant-steps-vertical>.ant-steps-item.ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail:after {
    background-color: #d8d8d8
}

.onboarding-popup .ant-steps-vertical>.ant-steps-item.ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-icon {
    background-color: transparent;
    border-color: #d8d8d8
}

.onboarding-popup .ant-steps-vertical .ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
    color: #333;
    font-weight: 500;
    margin-bottom: 6px
}

.onboarding-popup .ant-steps-vertical .ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-description {
    color: #999
}

.onboarding-popup .ant-steps-vertical.ant-steps-small .ant-steps-item-container .ant-steps-item-tail {
    left: 8px
}

.onboarding-popup .ant-steps-vertical.ant-steps-small .ant-steps-item-container .ant-steps-item-title {
    line-height: 18px
}

.onboarding-popup-footer {
    border-top: 1px solid #e5e5e5;
    color: #666;
    padding-top: 20px;
    text-align: center
}

.inblock {
    display: inline-block;
    vertical-align: top
}

* {
    margin: 0;
    padding: 0
}

body {
    -webkit-font-smoothing: antialiased;
    color: #222
}

body div p,body ul li p {
    margin-bottom: 0
}

body li,body ul {
    list-style: none
}

body ul {
    -webkit-padding-start: 0;
    padding-left: 0;
    margin-bottom: 0
}

.price {
    padding: 0 4px;
    color: #e04d48
}

html[lang=cn] #root .en-only,html[lang=en] #root .cn-only,html[lang=zh-Hans] #root .en-only {
    display: none
}

html:not([lang=cn]) .non-cn-pad {
    margin-left: 5px
}

body .d-none {
    display: none
}

body .d-block {
    display: block
}

body .d-inline-block {
    display: inline-block
}

body .d-flex {
    display: flex
}

body .d-inline-flex {
    display: inline-flex
}

body .d-none-empty:empty {
    display: none
}

body .align-middle {
    vertical-align: middle
}

body .items-start {
    align-items: flex-start
}

body .items-center {
    align-items: center
}

body .items-end {
    align-items: flex-end
}

body .self-start {
    align-self: flex-start
}

body .self-end {
    align-self: flex-end
}

body .justify-between {
    justify-content: space-between
}

body .justify-around {
    justify-content: space-around
}

body .justify-center {
    justify-content: center
}

body .flex-nowrap {
    flex-wrap: nowrap
}

body .order-last {
    order: 9999
}

@media (min-width: 1024px) {
    body .lg\:visible {
        display:block
    }
}

body .hidden {
    display: none!important
}

body .invisible {
    visibility: hidden
}

body .mx-auto {
    margin-left: auto;
    margin-right: auto
}

body .mx--5 {
    margin-left: -5px;
    margin-right: -5px
}

body .mt-auto {
    margin-top: auto
}

body .mt-4 {
    margin-top: 4px
}

body .mt-5 {
    margin-top: 5px
}

body .mt-8 {
    margin-top: 8px
}

body .mt-10 {
    margin-top: 10px
}

body .mt-12 {
    margin-top: 12px
}

body .mt-16 {
    margin-top: 16px
}

body .mt-20 {
    margin-top: 20px
}

body .mt-24 {
    margin-top: 24px
}

body .mt-30 {
    margin-top: 30px
}

body .mt-40 {
    margin-top: 40px
}

body .mt-60 {
    margin-top: 60px
}

body .mr-auto {
    margin-right: auto
}

body .mr-3 {
    margin-right: 3px
}

body .mr-4 {
    margin-right: 4px
}

body .mr-5 {
    margin-right: 5px
}

body .mr-8 {
    margin-right: 8px
}

body .mr-10 {
    margin-right: 10px
}

body .mr-12 {
    margin-right: 12px
}

body .mr-16 {
    margin-right: 16px
}

body .mr-20 {
    margin-right: 20px
}

body .mr-24 {
    margin-right: 24px
}

body .mr-30 {
    margin-right: 30px
}

body .mr-40 {
    margin-right: 40px
}

body .mb-0 {
    margin-bottom: 0
}

body .mb-4 {
    margin-bottom: 4px
}

body .mb-5 {
    margin-bottom: 5px
}

body .mb-8 {
    margin-bottom: 8px
}

body .mb-10 {
    margin-bottom: 10px
}

body .mb-12 {
    margin-bottom: 12px
}

body .mb-14 {
    margin-bottom: 14px
}

body .mb-16 {
    margin-bottom: 16px
}

body .mb-20 {
    margin-bottom: 20px
}

body .mb-22 {
    margin-bottom: 22px
}

body .mb-24 {
    margin-bottom: 24px
}

body .mb-30 {
    margin-bottom: 30px
}

body .mb-40 {
    margin-bottom: 40px
}

body .mb-60 {
    margin-bottom: 60px
}

body .mb-80 {
    margin-bottom: 80px
}

body .ml-auto {
    margin-left: auto
}

body .ml-4 {
    margin-left: 4px
}

body .ml-5 {
    margin-left: 5px
}

body .ml-8 {
    margin-left: 8px
}

body .ml-10 {
    margin-left: 10px
}

body .ml-12 {
    margin-left: 12px
}

body .ml-16 {
    margin-left: 16px
}

body .ml-20 {
    margin-left: 20px
}

body .ml-24 {
    margin-left: 24px
}

body .ml-30 {
    margin-left: 30px
}

body .ml-32 {
    margin-left: 32px
}

body .ml-40 {
    margin-left: 40px
}

body .-ml-10 {
    margin-left: -10px
}

body .m-16 {
    margin: 16px
}

body .p-8 {
    padding: 8px
}

body .p-10 {
    padding: 10px
}

body .p-16 {
    padding: 16px
}

body .p-20 {
    padding: 20px
}

body .px-5 {
    padding-left: 5px;
    padding-right: 5px
}

body .px-16 {
    padding-left: 16px;
    padding-right: 16px
}

body .px-14 {
    padding-left: 14px;
    padding-right: 14px
}

body .px-20 {
    padding-bottom: 20px;
    padding-top: 20px
}

body .py-8 {
    padding-left: 8px;
    padding-right: 8px
}

body .py-10 {
    padding-left: 10px;
    padding-right: 10px
}

body .py-20 {
    padding-left: 20px;
    padding-right: 20px
}

body .pt-5 {
    padding-top: 5px
}

body .pt-6 {
    padding-top: 6px
}

body .pt-10 {
    padding-top: 10px
}

body .pt-12 {
    padding-top: 12px
}

body .pt-16 {
    padding-top: 16px
}

body .pt-20 {
    padding-top: 20px
}

body .pt-30 {
    padding-top: 30px
}

body .pt-50 {
    padding-top: 50px
}

body .pt-60 {
    padding-top: 60px
}

body .pr-8 {
    padding-right: 8px
}

body .pb-24 {
    padding-top: 24px
}

body .pr-20 {
    padding-right: 20px
}

body .pr-30 {
    padding-right: 30px
}

body .pr-40 {
    padding-right: 40px
}

body .pb-0 {
    padding-bottom: 0
}

body .pb-5 {
    padding-bottom: 5px
}

body .pb-6 {
    padding-bottom: 6px
}

body .pb-8 {
    padding-bottom: 8px
}

body .pb-10 {
    padding-bottom: 10px
}

body .pb-12 {
    padding-bottom: 12px
}

body .pb-16 {
    padding-bottom: 16px
}

body .pb-20 {
    padding-bottom: 20px
}

body .pb-30 {
    padding-bottom: 30px
}

body .pb-50 {
    padding-bottom: 50px
}

body .pb-60 {
    padding-bottom: 60px
}

body .pr-10 {
    padding-right: 10px
}

body .pr-16 {
    padding-right: 16px
}

body .pr-24 {
    padding-right: 24px
}

body .pl-8 {
    padding-left: 8px
}

body .pl-10 {
    padding-left: 10px
}

body .pl-16 {
    padding-left: 16px
}

body .pl-24 {
    padding-left: 24px
}

body .pl-30 {
    padding-left: 30px
}

body .min-w-0 {
    min-width: 0
}

body .leading-5 {
    line-height: 20px
}

body .leading-6 {
    line-height: 24px
}

body .leading-7 {
    line-height: 28px
}

body .leading-8 {
    line-height: 32px
}

body .line-height-label {
    line-height: 22px
}

body .line-height-form-item-control {
    line-height: 32px
}

body .text-10 {
    font-size: 10px
}

body .text-12 {
    font-size: 12px
}

body .text-14 {
    font-size: 14px
}

body .text-16 {
    font-size: 16px
}

body .text-18 {
    font-size: 18px
}

body .text-20 {
    font-size: 20px
}

body .text-22 {
    font-size: 22px
}

body .text-24 {
    font-size: 24px
}

body .text-26 {
    font-size: 26px
}

body .text-28 {
    font-size: 28px
}

body .text-32 {
    font-size: 32px
}

body .text-72 {
    font-size: 72px
}

body .text-h1 {
    font-size: 38px
}

body .text-h2 {
    font-size: 30px
}

body .text-h3 {
    font-size: 24px
}

body .text-h4 {
    font-size: 20px
}

body .text-h5 {
    font-size: 16px
}

body .text-normal {
    font-weight: 400
}

body .text-bold {
    font-weight: 700
}

body .text-medium {
    font-weight: 500
}

body .text-white {
    color: #fff
}

body .text-gray,body .text-gray.anticon {
    color: #999
}

body .text-gray-dark {
    color: #666
}

body .hover\:text-black:hover,body .text-black {
    color: #333
}

body .text-cyan {
    color: #00d4fe
}

body .text-blue {
    color: #1890ff
}

body .text-green {
    color: #52c41a
}

body .text-yellow {
    color: #f8aa00
}

body .text-orange {
    color: #eb650a
}

body .text-red {
    color: #f3373d
}

body .text-primary,body .text-primary.anticon {
    color: #1890ff
}

body .text-left {
    text-align: left
}

body .text-right {
    text-align: right
}

body .text-center {
    text-align: center
}

body .uppercase {
    text-transform: uppercase
}

body .line-through {
    text-decoration-line: line-through
}

body .underline {
    text-decoration-line: underline
}

body .no-underline {
    text-decoration-line: none
}

body .truncate {
    overflow: hidden;
    text-overflow: ellipsis
}

body .truncate,body .whitespace-nowrap {
    white-space: nowrap
}

body .line-clamp-1 {
    -webkit-line-clamp: 1
}

body .line-clamp-1,body .line-clamp-2 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden
}

body .line-clamp-2 {
    -webkit-line-clamp: 2
}

body .line-clamp-3 {
    -webkit-line-clamp: 3
}

body .line-clamp-3,body .line-clamp-4 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden
}

body .line-clamp-4 {
    -webkit-line-clamp: 4
}

body .bg-transparent {
    background: transparent
}

body .bg-white {
    background-color: #fff
}

body .bg-gray {
    background-color: #fafafa
}

body .bg-gray-dark {
    background-color: #eee
}

body .bg-secondary {
    background-color: #f9f9f9
}

body .bg-tertiary {
    background-color: #f2f2f2
}

body .relative {
    position: relative
}

body .absolute {
    position: absolute
}

body .overflow-auto {
    overflow: auto
}

body .overflow-hidden {
    overflow: hidden
}

body .flex-center-vertical {
    align-items: center;
    display: flex
}

body .flex-middle-horizontal {
    display: flex;
    justify-content: center
}

body .justify-end {
    justify-content: flex-end
}

body .flex-wrap {
    flex-wrap: wrap
}

body .flex-grow-0 {
    flex-grow: 0
}

body .flex-grow-1 {
    flex-grow: 1
}

body .flex-shrink-0 {
    flex-shrink: 0
}

body .capitalize {
    text-transform: capitalize
}

body .preformatted {
    word-break: break-word;
    white-space: pre-wrap;
    word-wrap: break-word;
    overflow-wrap: break-word
}

body .hyphens-auto {
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto
}

body .h-full {
    height: 100%
}

body .max-w-full {
    max-width: 100%
}

body .w-0 {
    width: 0
}

body .w-px {
    width: 1px
}

body .w-0\.5 {
    width: 2px
}

body .w-1 {
    width: 4px
}

body .w-1\.5 {
    width: 6px
}

body .w-2 {
    width: 8px
}

body .w-2\.5 {
    width: 10px
}

body .w-3 {
    width: 12px
}

body .w-3\.5 {
    width: 14px
}

body .w-4 {
    width: 16px
}

body .w-5 {
    width: 20px
}

body .w-6 {
    width: 24px
}

body .w-7 {
    width: 28px
}

body .w-8 {
    width: 32px
}

body .w-9 {
    width: 36px
}

body .w-10 {
    width: 40px
}

body .w-11 {
    width: 44px
}

body .w-12 {
    width: 48px
}

body .w-14 {
    width: 56px
}

body .w-16 {
    width: 64px
}

body .w-20 {
    width: 80px
}

body .w-24 {
    width: 96px
}

body .w-28 {
    width: 112px
}

body .w-32 {
    width: 128px
}

body .w-36 {
    width: 144px
}

body .w-40 {
    width: 160px
}

body .w-44 {
    width: 176px
}

body .w-48 {
    width: 192px
}

body .w-52 {
    width: 208px
}

body .w-56 {
    width: 224px
}

body .w-60 {
    width: 240px
}

body .w-64 {
    width: 256px
}

body .w-72 {
    width: 288px
}

body .w-80 {
    width: 320px
}

body .w-96 {
    width: 384px
}

body .w-auto {
    width: auto
}

body .w-full {
    width: 100%
}

body .width-1by2 {
    width: 50%
}

body .width-1by3 {
    width: 33.33333333%
}

body .width-2by3 {
    width: 66.66666667%
}

body .width-1by5 {
    width: 20%
}

body .basis-1by2 {
    flex-basis: 50%
}

body .basis-1by3 {
    flex-basis: 33.33333333%
}

body .basis-2by3 {
    flex-basis: 66.66666667%
}

body .basis-1by5 {
    flex-basis: 20%
}

body .basis-2by5 {
    flex-basis: 40%
}

body .basis-3by5 {
    flex-basis: 60%
}

body .aspect-square {
    aspect-ratio: 1
}

body .z-10 {
    z-index: 10
}

body .rounded {
    border-radius: 4px
}

body .square-container {
    height: 0;
    padding-bottom: 100%
}

body .cursor-default {
    cursor: default
}

body .cursor-pointer {
    cursor: pointer
}

body .cursor-move {
    cursor: move
}

body .select-none {
    user-select: none
}

body .animate-none {
    animation: none
}

body .list-decimal,body .list-disc {
    margin-left: 1em
}

body .list-decimal>.li,body .list-disc>.li {
    display: list-item
}

body .list-decimal>.li,body .list-decimal>li {
    list-style-type: decimal
}

body .list-disc>.li,body .list-disc>li {
    list-style-type: disc
}

body .absolute-center {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%,-50%)
}

body .tiled-image {
    position: relative
}

body .tiled-image img {
    bottom: -100%;
    max-width: 100%;
    max-height: 100%;
    left: -100%;
    margin: auto;
    position: absolute;
    right: -100%;
    top: -100%
}

body .default-image-width-patch img {
    max-width: 100%;
    width: auto
}

body .clickable {
    cursor: pointer;
    color: #1890ff
}

body .flying {
    background-color: #40a9ff;
    border-radius: 50%;
    height: 30px;
    opacity: 1;
    position: fixed;
    transform-origin: center bottom;
    transform: scale(1);
    width: 30px;
    z-index: 10000
}

body .dot-flashing {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #9880ff;
    color: #9880ff;
    animation: dot-flashing 1s linear infinite alternate;
    animation-delay: .5s
}

body .dot-flashing:after,body .dot-flashing:before {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0
}

body .dot-flashing:before {
    left: -15px;
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 0s
}

body .dot-flashing:after,body .dot-flashing:before {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #9880ff;
    color: #9880ff
}

body .dot-flashing:after {
    left: 15px;
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 1s
}

@keyframes dot-flashing {
    0% {
        background-color: #9880ff
    }

    50%,to {
        background-color: rgba(152,128,255,.2)
    }
}

body .visible-if-flipped {
    display: none
}

body .flipped .visible-if-flipped {
    display: block
}

body .flipped .hidden-if-flipped {
    display: none
}

body .patch-row {
    margin-left: 0!important;
    margin-right: 0!important
}

body .ant-btn.wide-btn:not(.ant-btn-icon-only) {
    min-width: 200px
}

body .lg\:visible,body .md\:visible {
    display: none
}

body .lg\:hidden {
    display: block
}

body .lg\:hidden.d-flex {
    display: flex
}

body .\2xl\:visible,body .\3xl\:visible,body .xl\:visible {
    display: none
}

@media (min-width: 768px) {
    body .md\:hidden {
        display:none
    }

    body .md\:visible {
        display: block
    }

    body .md\:visible.d-flex {
        display: flex
    }

    body .md\:d-inline {
        display: inline
    }
}

@media (min-width: 1024px) {
    body .lg\:hidden {
        display:none
    }

    body .lg\:visible {
        display: block
    }

    body .lg\:visible.d-flex {
        display: flex
    }

    body .lg\:visible.d-inline-flex {
        display: inline-flex
    }
}

@media (min-width: 1280px) {
    body .xl\:visible {
        display:block
    }
}

@media (min-width: 1536px) {
    body .\2xl\:visible {
        display:block
    }
}

@media (min-width: 1792px) {
    body .\3xl\:visible {
        display:block
    }
}

.message {
    margin-bottom: 16px
}

.message ol,.message ul {
    margin-left: 1em
}

.message ol>li,.message ul>li {
    display: list-item
}

.message ol>li {
    list-style-type: decimal
}

.message ul>li {
    list-style-type: disc
}

.message ol,.message ul {
    margin-bottom: 0
}

.message.is-my-self .message-main {
    flex-direction: row-reverse
}

.message.is-my-self .message-avatar {
    margin-left: 8px;
    margin-right: 0
}

.message.is-my-self .message-body {
    align-items: flex-end
}

.message.is-my-self .message-timestamp {
    text-align: right
}

.message.is-my-self .message-content {
    background-color: #e4f5ff
}

.message-main {
    display: flex
}

.message-avatar {
    border-radius: 50%;
    flex-shrink: 0;
    margin-right: 8px;
    overflow: hidden;
    height: 30px;
    width: 30px
}

.message-avatar img {
    object-fit: cover;
    min-width: 100%;
    min-height: 100%
}

.message-body {
    align-items: flex-start;
    display: flex;
    flex-direction: column
}

.message-timestamp {
    color: #999;
    display: block
}

.message-username {
    color: #333;
    font-weight: 500
}

.message-content {
    background-color: #f0f2f5;
    border-radius: 4px;
    margin-top: 10px;
    max-width: 700px;
    padding: 16px
}

.assistant {
    display: flex;
    flex-direction: column
}

.assistant-body,.assistant-footer {
    background-color: #fff;
    border-radius: 2px
}

.assistant-body {
    flex-grow: 1;
    height: 40vh;
    margin-bottom: 16px;
    max-height: 480px;
    overflow: auto
}

.assistant-footer {
    height: 120px
}

.assistant-footer textarea {
    resize: none
}

.assistant-trigger {
    cursor: pointer;
    position: fixed;
    z-index: 2000
}

.assistant-trigger .icon-close {
    position: absolute;
    top: 8px;
    right: 2px;
    width: 16px;
    height: 16px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAAeJJREFUWEfNl81KQkEUx399gGEUQVGLFkVQtKmdJEEIuRB8B30AwacRfAB9B8GFgQRhuGsVBVGLFkFBFElCRvwvd+B29X5YxjRw4cKcOec352tmpog/poEVYA1YAObcTxre3e8VeAAegc84qqdiCMnQDrAOzMaQl8gHcA9cuWCBy8IAZoBtYAvQ/0/GALgBrgH9D40gAO06BSz9xOqINc9Ad5Q3RgEsAgee+E6IwcmTc+DFq9APoJ0f/YFxY1MQp15PeAEU58MJuj3IcwrHmckJL8Cum3STcnmYHiXlpQQMgFx//ItsHxdaFXGiUBiAfWBjXC2/lL8DLgSgDpeLajKpVIpyuUy1WqXT6Yy0nU6nKZVKVCoVul1VXehQs2oKYNUtu1DpWq1GoVCg1+uRz+dpt9vf5DOZDI1Gg2QySb1ep1gsRgFo/lwAe8BmlLR212q1HAN+CK9xzWWz2UAv+ezcCkCltxwFoHm/IXlCw+w8yDshup8EoOyfjwPgh+j3+86yRCIRGJoIvW8C0DbGOmzkiWaz6RjWEEgulxvKixibGvwLAOshsJ6E1svQeiOy3opVLVYPIwFYP44FYfVCIgDrVzITCmuXUtO6rV7LDYTVh4mBsPo0856k1h6n/uP8T57nX/NP9DdWrNuVAAAAAElFTkSuQmCC);
    background-size: 100%;
    z-index: 10
}

.assistant-trigger-popover .popover-btn,.tool-assistant-popover .popover-btn {
    background-color: #fff;
    border: 1px solid #fff;
    border-radius: 2px;
    color: #333;
    padding: 0 7px
}

.assistant-trigger-popover .ant-popover-inner,.tool-assistant-popover .ant-popover-inner {
    background-color: #1890ff
}

.assistant-trigger-popover .ant-popover-title,.tool-assistant-popover .ant-popover-title {
    border-bottom-color: transparent;
    color: #fff
}

.assistant-trigger-popover .ant-popover-inner-content,.tool-assistant-popover .ant-popover-inner-content {
    color: #fff;
    padding-top: 0
}

.assistant-trigger-popover .ant-popover-inner-content a:not(.popover-btn),.tool-assistant-popover .ant-popover-inner-content a:not(.popover-btn) {
    color: inherit
}

.assistant-trigger-popover.ant-popover-placement-left .ant-popover-content .ant-popover-arrow,.tool-assistant-popover.ant-popover-placement-left .ant-popover-content .ant-popover-arrow {
    border-top-color: #1890ff;
    border-right-color: #1890ff
}

.upgrade-shop-subscription-system-modal .ant-radio-group {
    max-height: 400px;
    overflow: auto;
    width: 100%
}

.upgrade-shop-subscription-system-modal .ant-radio-wrapper {
    display: flex;
    margin-bottom: 5px
}

.ant-table-tbody>tr>td {
    white-space: pre-wrap
}

.ant-tabs.rounded-tabs .ant-tabs-nav {
    padding: 12px 16px
}

.ant-tabs.rounded-tabs .ant-tabs-top-bar {
    margin-bottom: 0
}

.ant-tabs.rounded-tabs .ant-tabs-tab {
    border: 1px solid #e5e5e5;
    border-radius: 16px;
    padding: 3px 16px
}

.ant-tabs.rounded-tabs .ant-tabs-tab.ant-tabs-tab-active {
    background-color: #e4f5ff;
    border-color: #e4f5ff
}

.ant-tabs.rounded-tabs .ant-tabs-ink-bar {
    display: none!important
}

.ant-carousel .slick-slider {
    z-index: 0
}

.ant-carousel .slick-slider:hover .slick-arrow {
    opacity: 1
}

.ant-carousel .slick-slider .slick-arrow {
    font-size: 40px;
    margin-top: 0;
    height: 40px;
    opacity: 0;
    transition: all .2s;
    transform: translateY(-50%);
    width: 40px;
    z-index: 1
}

.ant-carousel .slick-slider .slick-arrow,.ant-carousel .slick-slider .slick-arrow:focus {
    color: #fff
}

.ant-carousel .slick-slider .slick-arrow:hover {
    color: #6e6e6e
}

.ant-carousel .slick-slider .slick-prev {
    left: 10px
}

.ant-carousel .slick-slider .slick-next {
    right: 10px
}

.ant-carousel .slick-slider .slick-dots {
    z-index: 1
}

.ant-carousel .slick-slider .slick-dots li button:before {
    font-size: 0;
}


.slick-slider .slick-dots li.slick-active button:before {
    color: #0055CC;
}

.slick-slider .slick-dots li button:before {
    width: 0.25rem !important;
    height: 0.25rem !important;
}

.slick-slider .slick-dots {
    bottom: 2px;
}

.ant-carousel .slick-slider .slick-dots-bottom {
    left: auto;
    margin-right: 0;
    right: 20px;
    width: auto
}

.ant-carousel .carousel-item {
    position: relative
}

.ant-carousel .carousel-item img {
    bottom: 0;
    left: 0;
    max-width: 100%;
    min-height: 100%;
    margin: auto;
    right: 0;
    position: absolute;
    top: 0
}

.ant-rate-star-first,.ant-rate-star-second {
    color: #e5e5e5
}

.ant-select-dropdown .ant-select-value-prefix {
    display: none
}

.ant-alert-with-description .ant-alert-message {
    font-weight: 700
}

.ant-table-expanded-row .ant-table-cell:empty {
    display: none
}

.ant-checkbox-group.ant-checkbox-group-vertical {
    display: flex;
    flex-direction: column
}

.ant-checkbox-group.ant-checkbox-group-vertical .ant-checkbox-group-item {
    align-items: center;
    display: flex;
    margin-right: 0
}

.ant-checkbox-group.ant-checkbox-group-vertical .ant-checkbox-group-item:not(:last-child) {
    margin-bottom: 8px
}

.ant-tag-checkable.disabled {
    cursor: not-allowed;
    pointer-events: none
}

.u-align-form-item {
    line-height: 22px;
    padding-bottom: 5px;
    padding-top: 5px
}

.m-p-0 #content {
    padding: 0
}

.m-p-0 #inner_content {
    max-width: auto
}

#page_head {
    z-index: 1
}

#content {
    padding: 16px 16px 0
}

#inner_content {
    min-height: 660px;
    max-width: 1440px;
    margin: auto
}

.basic-layout #content {
    padding: 0
}

/*# sourceMappingURL=admin.e2caec86.css.map*/


