.admin-products-page .ant-btn {
  min-width: 0
}

.admin-products-page .ant-tabs-ink-bar:after,
.admin-products-page .ant-tabs-ink-bar:before {
  background-color: #fff;
  bottom: 0;
  content: "";
  position: absolute;
  top: 0;
  width: 33%
}

.admin-products-page .ant-tabs-ink-bar:before {
  left: 0
}

.admin-products-page .ant-tabs-ink-bar:after {
  right: 0
}

.admin-products-page .products-main.is-searching .products-main-top {
  margin-bottom: 8px
}

.admin-products-page .products-main-content,
.admin-products-page .products-main-hero-banner {
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px
}

.admin-products-page .products-main-top {
  margin-bottom: 32px
}

.admin-products-page .products-main-top:empty {
  display: none
}

.admin-products-page .product .ant-btn {
  min-width: 0
}

.admin-products-page .product .ant-space-item:only-child .ant-btn-primary.add-to-import-list {
  width: 200px
}

.admin-products-page .products-main-box-categories {
  min-width: 216px;
  max-width: 216px;
  margin-right: 20px
}

.admin-products-page .products-main-categories,
.admin-products-page .products-main-categories .products-main-categories-popup .ant-cascader-menu .ant-cascader-menu-item {
  min-width: 216px;
  max-width: 100%
}

@media (min-width: 1500px) {

  .admin-products-page .products-main-categories,
  .admin-products-page .products-main-categories .products-main-categories-popup .ant-cascader-menu .ant-cascader-menu-item {
    width: 240px
  }
}

.admin-products-page .products-main-hero-banner-right {
  min-width: 232px;
  margin-left: 20px;
  max-width: 240px;
  width: auto;
}

#admin-products-page-header-bottom .products-main-search {
  max-width: 1000px;
  margin-bottom: 16px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
}

#admin-products-page-header-bottom .products-main-search .ant-input-affix-wrapper>input {
  padding-bottom: 6.5px !important;
  padding-top: 6.5px !important
}

#admin-products-page-header-bottom .products-main-search-input .ant-input-group-wrapper {
  box-shadow: 0 0 0 2px #1890ff;
  border-radius: 2px
}

#admin-products-page-header-bottom .products-main-search-input .ant-input-group-addon:last-child {
  left: 0
}

.products-main-categories {
  height: 482px;
  max-height: 482px;
  overflow: hidden;
  position: relative;
  width: 240px;
  z-index: 2
}

.products-main-categories>div:first-child {
  position: relative !important
}

.products-main-categories .ant-cascader-picker {
  // display: none
}

.products-main-categories .products-main-categories-popup {
  box-shadow: none;
  opacity: 1 !important;
  pointer-events: all !important;
  width: 100%;
  z-index: 1
}

.products-main-categories .products-main-categories-popup .ant-cascader-menu {
  background-color: #fff;
  border-right: 0;
  overflow-x: hidden;
  padding-bottom: 0;
  padding-top: 0;
  width: 100% !important
}

.products-main-categories .products-main-categories-popup .ant-cascader-menu::-webkit-scrollbar {
  background-color: transparent;
  width: 4px
}

.products-main-categories .products-main-categories-popup .ant-cascader-menu::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 10px
}

.products-main-categories .products-main-categories-popup .ant-cascader-menu .ant-empty {
  display: none
}

.products-main-categories .products-main-categories-popup .ant-cascader-menu .ant-cascader-menu-item {
  padding-bottom: 9px;
  padding-top: 9px;
  width: 240px
}

.products-main-categories .ant-cascader-menu {
  height: 482px
}

.products-main-categories:hover {
  overflow: visible
}

.products-main-search-top-categories {
  width: 232px
}

.products-main-search-top-categories .products-main-categories-popup {
  border-radius: 4px;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .12)
}

.products-main-search-top-categories .products-main-categories-popup .ant-cascader-menu .ant-cascader-menu-item {
  width: 232px
}

.products-main-search-image-scanner {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  position: relative;
  width: 100%
}

.products-main-search-image-scanner .upload-preview {
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%
}

.products-main-search-image-scanner .upload-preview img {
  border-radius: 3px;
  bottom: 0;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0
}

.products-main-search-image-scanner.is-uploading .upload-preview .anticon {
  opacity: 0
}

.products-main-search-image-scanner.is-uploading .upload-preview img {
  opacity: .5
}

.products-main-search-image-scanner-spinner {
  bottom: 0;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0
}

.products-main-search-platforms {
  margin-right: 16px;
  padding: 4px 0
}

.products-main-search-platform {
  cursor: pointer;
  display: block;
  position: relative
}

.products-main-search-platform .platform-icon {
  border-radius: 50%
}

.products-main-search-platform.active:after {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #1890ff;
  bottom: -12px;
  content: "";
  height: 0;
  left: 50%;
  margin-left: -5px;
  position: absolute;
  width: 0
}

.products-main-search-platform.active .platform-icon {
  box-shadow: 0 0 0 3px #1890ff
}

.products-main-search-platforms-more {
  background-color: #ccc;
  color: #fff;
  cursor: pointer;
  font-size: 20px;
  height: 32px;
  vertical-align: middle;
  width: 32px
}

.products-main-search-platforms-modal .products-main-search-platform.active:after {
  left: 20px
}

.products-main-search-top {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  position: relative
}

.products-main-search-top .products-main-search-top-categories-trigger {
  margin-left: -60px;
  min-width: 0;
  position: absolute
}

.products-main-search-top .cart-button {
  margin-left: 16px;
  min-width: 0
}

.products-main-search-top .cart-button-inner {
  position: relative
}

.products-main-search-top .cart-button-dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #40a9ff;
  position: absolute;
  right: -4px;
  top: 8px
}

.products-main-search-top .products-main-categories .products-main-categories-popup .ant-cascader-menu:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px
}

.products-main-search-top .products-main-categories .products-main-categories-popup .ant-cascader-menu:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px
}

.products-main-search-top .products-main-search-image-scanner {
  margin-right: -8px;
  height: 32px;
  width: 32px
}

.products-main-search-top.is-search-by-image .products-main-search-platforms,
.products-main-search-top.is-search-by-image .products-main-search-top-categories-container {
  display: none
}

.products-main-search-input {
  flex-grow: 1;
  position: relative;
}

.products-main-search-input .ant-input-group .ant-input-group-addon:first-child {
  padding: 0
}

.products-main-search-input .ant-input-group .ant-input-group-addon .ant-input-search-button {
  align-items: center;
  display: inline-flex;
  justify-content: center;
  width: 80px
}

.products-main-search-input .ant-input-affix-wrapper {
  height: 40px
}

.products-main-search-input .ant-input-affix-wrapper,
.products-main-search-input .ant-input-affix-wrapper:focus,
.products-main-search-input .ant-input-affix-wrapper:hover {
  border-color: transparent;
  box-shadow: none
}

.products-main-scanned-images {
  background-color: #fff;
  border-radius: 4px;
  display: flex;
  height: 168px;
  margin-bottom: 24px;
  padding: 24px
}

.products-main-scanned-images-item {
  align-items: center;
  border-radius: 6px;
  display: flex;
  height: 120px;
  justify-content: center;
  margin-right: 20px;
  overflow: hidden;
  position: relative;
  width: 120px
}

.products-main-scanned-images-item .anticon {
  background-color: #fff;
  font-size: 16px;
  position: absolute;
  right: 5px;
  top: 5px
}

.products-main-scanned-images-item.selected {
  box-shadow: 0 0 0 2px #1890ff
}

.products-main-scanned-images-item.selected .anticon {
  color: #1890ff
}

.products-main-scanned-images-upload-button {
  align-items: center;
  border: 2px dashed #ccc;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  height: 120px;
  justify-content: center;
  width: 120px
}

.products-main-scanned-images-upload-button .anticon {
  color: #ccc
}

.products-main-scanned-images-upload-button .upload-preview img {
  display: none
}

.products-main-scanned-images-upload-button .products-main-search-image-scanner.is-uploading {
  pointer-events: none
}

.products-main-scanned-images-upload-button .products-main-search-image-scanner.is-uploading .upload-preview .anticon {
  opacity: 1
}

.products-main-scanned-images-upload-button .products-main-search-image-scanner .products-main-search-image-scanner-spinner {
  display: none
}

.products-search-image-search-platforms .ant-radio-button-wrapper {
  height: 40px;
  line-height: 38px;
  text-align: center;
  width: 180px
}

.products-search-image-search-platforms .ant-radio-button-wrapper-checked {
  background-color: #1890ff;
  color: #fff
}

.products-search-image-search-platforms .ant-radio-button-wrapper-checked:hover {
  color: #fff
}

.products-main-filters {
  align-items: flex-start;
  background-color: #fff;
  border-radius: 4px;
  color: #333;
  display: flex;
  margin-bottom: 24px;
  padding: 16px 16px 0
}

.products-main-filters .ant-form-item {
  margin-bottom: 16px
}

.products-main-filters .sorter {
  padding-left: 0
}

.products-main-filters .ant-input-number {
  min-width: 100px
}

.products-main-filters.has-only-sorter-filters .products-main-filters-form-actions,
.products-main-filters:not(.is-expanded) .is-collapsible {
  display: none
}

.products-main-filters-group {
  display: flex;
  margin-bottom: 12px;
}

.products-main-filters-label {
  flex-shrink: 0;
  font-weight: 500;
  margin-right: 16px;
  width: 160px
}

.products-main-filters-controls {
  flex-grow: 1;
  display: flex;
  align-items: center;
  height: 100%;
}

.products-main-filters-item {
  margin-right: 16px
}

.products-main-filters-toggle-more {
  cursor: pointer;
  line-height: 32px
}

.products-main-filter {
  align-items: center;
  display: inline-flex;
  padding-bottom: 8px;
  padding-top: 8px
}

.products-main-filter>label {
  margin-right: 12px
}

.products-main-filter>label:after {
  content: ":"
}

.products-main-filter:not(:last-child) {
  margin-right: 40px
}

.products-main-sorter {
  line-height: 1.5715;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  cursor: pointer;
  transition: all .3s cubic-bezier(.645, .045, .355, 1);
  user-select: none;
  touch-action: manipulation;
  height: 32px;
  padding: 4px 7px;
  font-size: 14px;
  color: rgba(0, 0, 0, .85)
}

.products-main-sorter:active,
.products-main-sorter:focus,
.products-main-sorter:hover {
  background-color: transparent;
  color: #40a9ff
}

.products-main-sorter.is-active {
  color: #40a9ff
}

.products-main-sorter.is-active .products-main-sorter-ink-bar {
  bottom: -9px;
  border-bottom: 2px solid #1890ff;
  content: "";
  height: 0;
  left: 25%;
  position: absolute;
  transform: translateY(-50%);
  width: 50%
}

.products-main-sorter.products-main-sorter-with-order {
  position: relative;
  padding-right: 15px
}

.products-main-sorter.products-main-sorter-with-order:after,
.products-main-sorter.products-main-sorter-with-order:before {
  color: #ccc;
  font-size: 10px;
  position: absolute;
  right: 3px
}

.products-main-sorter.products-main-sorter-with-order:before {
  content: "" !important;
  top: 11px;
  width: 0;
  height: 0;
  border-color: transparent transparent #999;
  border-style: solid;
  border-width: 0 4px 4px
}

.products-main-sorter.products-main-sorter-with-order:after {
  content: "";
  bottom: 10px;
  width: 0;
  height: 0;
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 4px 4px 0
}

.products-main-sorter.products-main-sorter-with-order.is-asc:before {
  border-bottom-color: #40a9ff
}

.products-main-sorter.products-main-sorter-with-order.is-desc:after {
  border-top-color: #40a9ff
}

.products-main-flows {
  background-color: #fff;
  border-radius: 4px;
  color: #333;
  margin-top: 20px;
  overflow: hidden;
  width: 680px
}

.products-main-flow {
  align-items: center;
  display: flex;
  position: relative
}

.products-main-flow:nth-child(2n) {
  background-color: #f9f9fb
}

.products-main-flow.active .products-main-flow-step:not(.active, .expand),
.products-main-flow.expand .products-main-flow-step:not(.active, .expand) {
  flex-shrink: 1;
  width: 16.66667%
}

.products-main-flow.active .products-main-flow-step-indicator .anticon-arrow-right,
.products-main-flow.expand .products-main-flow-step-indicator .anticon-arrow-right {
  display: none
}

.products-main-flow.active .products-main-flow-step-title,
.products-main-flow.expand .products-main-flow-step-title {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden
}

.products-main-flow.active:before {
  background-color: #40a9ff
}

.products-main-flow.active .products-main-flow-step-indicator,
.products-main-flow.active .products-main-flow-step-indicator-icon,
.products-main-flow.active .products-main-flow-step-title,
.products-main-flow.active .products-main-flow-title {
  color: #fff
}

.products-main-flow:before {
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0
}

.products-main-flow-title {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  font-weight: 500;
  height: 115px;
  padding: 16px;
  position: relative;
  width: 20%
}

.products-main-flow-steps {
  align-items: center;
  display: flex;
  width: 80%
}

.products-main-flow-step {
  align-items: flex-start;
  cursor: pointer;
  flex-direction: column;
  height: 115px;
  padding: 22px 10px;
  position: relative;
  transition: all .2s;
  width: 25%
}

.products-main-flow-step.active,
.products-main-flow-step.expand {
  flex-grow: 1;
  width: 50%
}

.products-main-flow-step.active .products-main-flow-step-indicator-icon {
  visibility: hidden
}

.products-main-flow-step.active .products-main-flow-step-details {
  opacity: 1
}

.products-main-flow-step.active .products-main-flow-step-details-background {
  opacity: 1;
  transform: scaleX(1) scaleY(2)
}

.products-main-flow-step.active .products-main-flow-step-details-content {
  height: 200%;
  opacity: 1;
  overflow: hidden;
  width: 100%
}

.products-main-flow-step-indicator {
  align-items: center;
  display: flex
}

.products-main-flow-step-indicator .anticon:not(.anticon-arrow-right) {
  margin-right: 5px
}

.products-main-flow-step-indicator .anticon:not(.anticon-arrow-right)>svg {
  height: 20px;
}

.products-main-flow-step-indicator-icon {
  color: #40a9ff;
  font-size: 20px;
  height: 20px;
  width: 20px
}

.products-main-flow-step-title {
  font-weight: 500;
  height: 36px;
  line-height: 18px
}

.products-main-flow-step-details {
  color: #fff;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  transform-origin: top left;
  top: 0;
  width: 100%;
  z-index: 1
}

.products-main-flow-step-details-background {
  background-color: #40a9ff;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  transition: all .2s ease;
  top: 0;
  transform: scale(0);
  width: 100%
}

.products-main-flow-step-details-step-indicator {
  color: #62b8ff;
  font-size: 32px;
  font-weight: 700;
  line-height: 42px;
  position: absolute;
  right: 20px;
  top: 12px
}

.products-main-flow-step-details-content {
  height: 100%;
  opacity: 0;
  overflow: auto;
  padding: 20px 12px;
  position: absolute;
  transition: opacity .2s ease .2s;
  width: 100%;
  z-index: 1
}

.products-main-flow-step-indicator {
  color: #999
}

.products-main-flow-step-indicator .anticon-arrow-right {
  color: #bbb
}

.products-main-flow-step-details-title {
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 10px;
  position: relative
}

.products-main-flow-step-details-description {
  color: #fff;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 10px
}

.products-main-flow-step-details-highlights {
  font-size: 14px;
  font-weight: 700;
  margin-left: 16px
}

.products-main-flow-step-details-highlights>li {
  list-style-type: disc
}

.products-main-box-logistics {
  height: 550px
}

.products-main-box-logistics .ant-select {
  width: 100%
}

.products-main-box-logistics .ant-table {
  font-size: 12px
}

.products-main-box-logistics .ant-table-cell:before {
  background-color: transparent !important
}

.products-main-box-logistics-table-wrapper {
  height: 155px;
  margin-top: 12px
}

.products-main-box-logistics-table-wrapper .ant-table-body {
  height: 120px
}

.transaction-record-thumbnail-image,
.transaction-record-thumbnail-images {
  align-items: center;
  display: flex;
  position: relative
}

.transaction-record-thumbnail-image {
  height: 40px;
  justify-content: center;
  width: 40px
}

.transaction-record-thumbnail-image:not(:first-child) {
  margin-left: 6px
}

.transaction-record-thumbnail-image a {
  height: 100%
}

.transaction-record-thumbnail-image img {
  max-height: 100%;
  max-width: 100%
}

.products-main-transaction-record-thumbnails {
  height: 230px
}

.products-main-hero-banner {
  align-items: center;
  display: flex;
  justify-content: space-between
}

.products-main-carousel {
  background-color: #fff;
  border-radius: 4px;
  height: 300px;
  overflow: hidden;
  width: 680px
}

.products-main-carousel .ant-carousel .slick-slider {
  z-index: 0
}

.products-main-carousel .ant-carousel:hover .slick-arrow {
  opacity: 1
}

.products-main-carousel .slick-arrow {
  font-size: 40px;
  margin-top: -20px;
  height: 40px;
  opacity: 0;
  transition: all .2s;
  width: 40px;
  z-index: 1
}

.products-main-carousel .slick-arrow,
.products-main-carousel .slick-arrow:focus {
  color: #fff
}

.products-main-carousel .slick-arrow:hover {
  color: #6e6e6e
}

.products-main-carousel .slick-prev {
  left: 10px
}

.products-main-carousel .slick-next {
  right: 10px
}

.products-main-carousel .slick-dots-bottom {
  left: auto;
  margin-right: 0;
  right: 20px
}

.products-main-carousel-item {
  display: block !important;
  height: 300px;
  position: relative;
  overflow: hidden;
  width: 680px
}

.products-main-carousel-item img {
  bottom: 0;
  left: 0;
  max-width: 100%;
  margin: auto;
  right: 0;
  position: absolute;
  top: 0
}

.products-main-hero-banner-right {
  width: 232px
}

.product {
  background-color: #fff;
  border-radius: 4px;
  overflow: hidden;
  padding-bottom: 118px;
  position: relative;
  transition: all .3s
}

.product.hover,
.product:hover {
  box-shadow: 0 0 0 3px #1890ff
}

.product.hover .product-actions,
.product:hover .product-actions {
  height: 48px;
  opacity: 1;
  padding-top: 16px
}

.product .ant-rate {
  font-size: 12px
}

.product .added-to-cart-badge {
  background-color: rgba(0, 0, 0, .5);
  color: #fff;
  padding: 6px 12px;
  position: absolute;
  right: 0;
  top: 16px
}

.product a.text-red:active,
.product a.text-red:focus,
.product a.text-red:hover {
  color: #f21f26
}

.product-image {
  cursor: pointer;
  display: block;
  height: 0;
  overflow: hidden;
  padding-bottom: 100%;
  position: relative
}

.product-image img {
  margin: auto;
  max-height: 100%;
  max-width: 100%;
  top: 0
}

.product-content,
.product-image img {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0
}

.product-content {
  background-color: #fff;
  padding: 16px 10px
}

.product-name {
  color: #333;
  font-size: 12px
}

.product-placeholder {
  background-color: #fff;
  border-radius: 4px;
  overflow: hidden
}

.product-placeholder-image {
  background-color: #f0f0f0;
  height: 0;
  padding-bottom: 100%
}

.product-placeholder-image img {
  width: 100%
}

.product-placeholder-content {
  height: 145px;
  padding: 0 10px
}

.empty-tip {
  padding: 20px
}

.empty-tip-description {
  color: #333;
  margin-top: 20px
}

.empty-tip-block {
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  width: 880px
}

.products-main-featured-hot-products {
  max-height: 1660px
}

.products-main-featured-hot-products .ant-tabs {
  overflow: visible
}

.products-main-featured-hot-products .ant-tabs-tab {
  flex-basis: 0%;
  flex-grow: 1;
  flex-shrink: 1;
  justify-content: center
}

.products-main-featured-hot-products .ant-tabs-nav {
  background-color: #fff;
  border-radius: 4px;
  height: 48px
}

.products-main-featured-hot-products .ant-tabs-nav .ant-btn-link {
  color: #666;
  padding-left: 16px;
  padding-right: 16px
}

.products-main-featured-hot-products .ant-tabs-nav:before {
  display: none
}

.products-main-featured-hot-products .ant-tabs-extra-content .ant-btn-link {
  border-width: 0;
  color: #666
}

.products-main-featured-hot-products .ant-tabs-nav-operations {
  display: none !important
}

.products-main-featured-hot-products:not(.empty) .ant-tabs-nav-list {
  border-radius: 4px;
  overflow: hidden;
  width: 100%
}

.products-main-featured-hot-products-footer {
  background-color: #fff;
  border-radius: 4px;
  padding: 13px 24px;
  text-align: center
}

.transaction-record {
  background-color: #fff;
  border-radius: 4px;
  margin-right: 15px
}

.transaction-record .ant-descriptions-item-content {
  height: 22px
}

.transaction-record-images {
  align-items: center;
  display: flex;
  padding: 20px;
  position: relative
}

.transaction-record-image {
  align-items: center;
  display: flex;
  height: 130px;
  justify-content: center;
  position: relative;
  width: 130px
}

.transaction-record-image:not(:first-child) {
  margin-left: 11px
}

.transaction-record-image img {
  max-height: 100%;
  max-width: 100%
}

.products-main-transaction-records:hover .slick-arrow {
  opacity: 1
}

.products-main-transaction-records .slick-track {
  margin-left: 240px
}

.products-main-transaction-records .slick-arrow {
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 0 40px 0 rgba(42, 42, 42, .5);
  display: flex !important;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 48px;
  opacity: 0;
  z-index: 1;
  transition: all .2s
}

.products-main-transaction-records .slick-arrow:focus,
.products-main-transaction-records .slick-arrow:hover {
  background-color: #fff
}

.products-main-transaction-records .slick-arrow:focus svg,
.products-main-transaction-records .slick-arrow:hover svg {
  color: #6e6e6e
}

.products-main-transaction-records .slick-arrow svg {
  font-size: 24px;
  color: #bbb
}

.products-main-transaction-records .slick-prev {
  left: 16px
}

.products-main-transaction-records .slick-prev[currentslide="-0.5"]+.slick-list:before {
  display: none
}

.products-main-transaction-records .slick-next {
  right: 16px
}

.products-main-transaction-records .slick-next svg {
  margin-left: 5px
}

.find-products-form {
  text-align: center
}

.find-products-form .ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
  background-color: #f5f5f5;
  border-color: #f5f5f5 !important
}

.find-products-form .ant-form-item-explain {
  font-size: 12px
}

.find-products-form .ant-input:focus {
  box-shadow: none
}

.find-products-form .ant-upload.ant-upload-select {
  transition: all .1s
}

.find-products-form .ant-upload.ant-upload-select:hover {
  border-color: #d9d9d9
}

.find-products-form .ant-upload.ant-upload-select:only-child {
  margin-right: 0;
  width: 100%
}

.find-products-form div.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #f5f5f5
}

.find-products-form div.ant-select.ant-select-focused:not(.ant-select-disabled) .ant-select-selector {
  border-color: #f5f5f5;
  box-shadow: none
}

.find-products-form-footer {
  text-align: center;
  margin-top: 20px
}

.find-products-form-footer .ant-btn-primary {
  min-width: 200px
}

.find-products-modal legend {
  display: none
}

.find-products-modal .find-products-form-footer {
  text-align: right
}

.find-products-modal .find-products-form-footer .ant-btn-primary {
  min-width: 0
}

.products-main-find-products .find-products-form {
  text-align: left
}

.find-products-contact {
  background-image: url(https://cdn.buckydrop.com/starit-buckydrop/2024/dist/bg.50126ab0.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: -200px;
  border-radius: 5px;
  display: flex;
  padding: 16px 24px
}

.find-products-contact .ant-form {
  background-color: #fff;
  border-radius: 4px;
  margin-left: auto;
  padding: 24px;
  width: 720px
}

.find-products-contact .ant-form legend {
  border-bottom: 0;
  color: #000;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  text-align: center
}

.find-products-contact .ant-form .ant-input,
.find-products-contact .ant-form .ant-select-selector {
  background-color: #f5f5f5;
  border-color: #f5f5f5;
  border-radius: 4px
}

.find-products-steps {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  padding: 0 22px
}

.find-products-steps .image {
  background-image: none
}

.OxDyYCh3 {
  display: flex;
  justify-content: space-between
}

.zMYsaPgP {
  border-radius: 4px;
  display: flex;
  flex-basis: calc(25% - 9px);
  flex-direction: column;
  overflow: hidden
}

.KeL-nh9x {
  position: relative
}

.KeL-nh9x img {
  width: 100%
}

.BsrvTra\+ {
  bottom: 0;
  left: 0;
  padding: 16px 20px;
  position: absolute
}

.BsrvTra\+ .anticon {
  color: #fff;
  font-size: 24px;
  margin-bottom: 14px
}

._1gvJQMw4 {
  background-color: #fff;
  flex-grow: 2;
  line-height: 22px;
  padding: 18px 24px
}

.products-main-cart-drawer {
  height: 100%
}

.products-main-cart-drawer-header {
  align-items: center;
  background-color: #fff;
  display: flex;
  font-weight: 500;
  padding-bottom: 10px;
  padding-top: 20px
}

.products-main-cart {
  margin-left: -24px;
  margin-right: -24px;
  height: calc(100% - 59px);
  padding-bottom: 56px
}

.products-main-cart-header {
  background-color: #f9f9f9;
  padding: 16px 24px
}

.products-main-cart-body {
  margin-bottom: 80px;
  height: calc(100% - 54px);
  overflow: auto
}

.products-main-cart-item {
  align-items: center;
  display: flex;
  margin-left: 24px;
  padding: 24px 24px 24px 0
}

.products-main-cart-item:not(:last-child) {
  border-bottom: 1px solid #e5e5e5
}

.products-main-cart-item-image {
  height: 100px;
  flex-shrink: 0;
  position: relative;
  width: 100px
}

.products-main-cart-item-image img {
  bottom: 0;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0
}

.products-main-cart-item-actions .anticon svg {
  color: #333
}

.products-main-cart-footer {
  background-color: #f9f9f9;
  bottom: 0;
  left: 0;
  padding: 24px;
  position: absolute;
  width: 100%
}

.products-main-estimated-freight-modal .ant-modal-header {
  background-color: #f9f9f9;
  padding: 14px 16px
}

.products-main-estimated-freight-modal .ant-modal-body {
  padding: 16px
}

.products-main-estimated-freight-modal .ant-table-thead>tr>th {
  background-color: #eee;
  padding: 10px 20px
}

.products-main-estimated-freight-modal .ant-table-tbody>tr>td {
  border-color: transparent;
  padding-bottom: 9px;
  padding-top: 9px
}

.products-main-estimated-freight-modal .ant-select-value-prefix {
  color: #666;
  margin-right: 5px
}

.products-main-estimated-freight-modal .estimated-freight-table-route-name {
  display: flex;
  flex-wrap: nowrap
}

.products-main-estimated-freight-modal .estimated-freight-table-route-name .ant-tag {
  flex-shrink: 0
}

.products-main-estimated-freight-modal .estimated-freight-table-route-name-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%
}

.products-main-estimated-freight-modal .ant-tag {
  border-color: transparent;
  margin-left: 10px;
  margin-right: 0
}

.products-main-estimated-freight-modal textarea {
  max-height: 600px
}

.ant-table-tbody>tr>td {
  white-space: pre-wrap
}

.ant-tabs.rounded-tabs .ant-tabs-nav {
  padding: 12px 16px
}

.ant-tabs.rounded-tabs .ant-tabs-top-bar {
  margin-bottom: 0
}

.ant-tabs.rounded-tabs .ant-tabs-tab {
  border: 1px solid #e5e5e5;
  border-radius: 16px;
  padding: 3px 16px
}

.ant-tabs.rounded-tabs .ant-tabs-tab.ant-tabs-tab-active {
  background-color: #e4f5ff;
  border-color: #e4f5ff
}

.ant-tabs.rounded-tabs .ant-tabs-ink-bar {
  display: none !important
}

.ant-carousel .slick-slider {
  z-index: 0
}

.ant-carousel .slick-slider:hover .slick-arrow {
  opacity: 1
}

.ant-carousel .slick-slider .slick-arrow {
  font-size: 40px;
  margin-top: 0;
  height: 40px;
  opacity: 0;
  transition: all .2s;
  transform: translateY(-50%);
  width: 40px;
  z-index: 1
}

.ant-carousel .slick-slider .slick-arrow,
.ant-carousel .slick-slider .slick-arrow:focus {
  color: #fff
}

.ant-carousel .slick-slider .slick-arrow:hover {
  color: #6e6e6e
}

.ant-carousel .slick-slider .slick-prev {
  left: 10px
}

.ant-carousel .slick-slider .slick-next {
  right: 10px
}

.ant-carousel .slick-slider .slick-dots {
  z-index: 1
}

.ant-carousel .slick-slider .slick-dots li button:before {
  font-size: 0
}

.ant-carousel .slick-slider .slick-dots-bottom {
  left: auto;
  margin-right: 0;
  right: 20px;
  width: auto
}

.ant-carousel .carousel-item {
  position: relative
}

.ant-carousel .carousel-item img {
  bottom: 0;
  left: 0;
  max-width: 100%;
  min-height: 100%;
  margin: auto;
  right: 0;
  position: absolute;
  top: 0
}

.ant-rate-star-first,
.ant-rate-star-second {
  color: #e5e5e5
}

.ant-select-dropdown .ant-select-value-prefix {
  display: none
}

.ant-alert-with-description .ant-alert-message {
  font-weight: 700
}

.ant-table-expanded-row .ant-table-cell:empty {
  display: none
}

.ant-checkbox-group.ant-checkbox-group-vertical {
  display: flex;
  flex-direction: column
}

.ant-checkbox-group.ant-checkbox-group-vertical .ant-checkbox-group-item {
  align-items: center;
  display: flex;
  margin-right: 0
}

.ant-checkbox-group.ant-checkbox-group-vertical .ant-checkbox-group-item:not(:last-child) {
  margin-bottom: 8px
}

.ant-tag-checkable.disabled {
  cursor: not-allowed;
  pointer-events: none
}

.u-align-form-item {
  line-height: 22px;
  padding-bottom: 5px;
  padding-top: 5px
}

.products-main .btn-bold {
  border-width: 2px;
  font-weight: 600
}

.products-main-section:not(:last-child) {
  margin-bottom: 32px
}

.products-main-section-header {
  margin-bottom: 24px
}

.products-main-section-title {
  color: #333;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 0
}

.products-main-box {
  background-color: #fff;
  border-radius: 4px;
  padding-bottom: 16px;
  padding-top: 16px
}

.products-main-box.full-width .products-main-box-body {
  padding-left: 0;
  padding-right: 0
}

.products-main-box .ant-table-cell-scrollbar {
  background-color: #f0f0f0;
  box-shadow: none
}

.products-main-box .ant-table-body::-webkit-scrollbar {
  height: 8px;
  width: 5px;
  background-color: #f0f0f0
}

.products-main-box .ant-table-body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #c1c1c1
}

.products-main-box .ant-table-body .ant-empty-normal {
  margin: 10px
}

.products-main-box .ant-select-dropdown {
  z-index: 1
}

.products-main-box-body,
.products-main-box-header {
  padding-left: 16px;
  padding-right: 16px
}

.products-main-box-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px
}

.products-main-box-title {
  color: #333;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 0
}

.products-main-box-header-extra {
  color: #333;
  font-size: 12px;
  margin-left: auto
}

.products-main-box-header-extra a {
  color: inherit
}

.products-main-pagination .ant-btn {
  width: 130px
}



.inner-content {
  min-height: 660px;
  max-width: 1440px;
  margin: auto;
  padding-top: 142px;
  height: calc(100vh - 64px);
  overflow: scroll;
}

div {
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* IE 10+ */
}

div::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

/*# sourceMappingURL=653.b41d1ecb.css.map*/



.product-list {
  display: flex;
  flex-direction: column;
  /* 垂直排列 */
  gap: 15px;
  /* 项目之间的间隔 */
}

.product-item {
  display: flex;
  align-items: center;
  gap: 10px;
  /* 图片和文本之间的间隔 */
  cursor: pointer;
}

.product-item .product-thumbnail {
  width: 34px;
  /* 缩略图宽度 */
  height: 34px;
  /* 缩略图高度 */
  object-fit: cover;
  /* 图片填充方式 */
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.product-item .product-name {
  margin: 0;
  font-size: 14px;
  color: #333;
}

.product-item .product-name:hover {
  color: #1890ff;
}


.products-main-search-input .ant-input-suffix {
  position: relative;
  left: -24px;
  z-index: 11;
}